import {
  doctorListFiltersFormDefaultValues,
  DoctorsListFiltersFormSchema,
  IDoctorsListFiltersFormValues,
} from "../DoctorsListFiltersFormSchema";
import { GenericFilterForm } from "@/components/filter/GenericFilterForm";
import { useTranslation } from "@toolkit/i18n";
import { DoctorFilterFormProps } from "../types";
import { Box } from "@toolkit/ui";
import { isEmpty, xor } from "lodash";
import { FormSlider, ValueLabelComponent } from "@/components";
import { useSiteSettingsGetQuery } from "@/gql";

export const CallPriceFilterForm: React.FC<DoctorFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  const { data } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });
  const defaultCurrency = data?.siteSettings?.defaultCurrency || "";
  return (
    <GenericFilterForm<IDoctorsListFiltersFormValues>
      schema={DoctorsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='callPrice'
      dialogTitle={t("Price")}
      formLabel={t("Price")}
      render={name => (
        <Box width='100%'>
          <FormSlider
            valueLabelFormat={value => ValueLabelComponent({ value, unit: t(defaultCurrency) })}
            valueLabelDisplay='on'
            min={Math.min(...(doctorListFiltersFormDefaultValues.callPrice ?? []))}
            max={Math.max(...(doctorListFiltersFormDefaultValues.callPrice ?? []))}
            name={name}
          />
        </Box>
      )}
      height={"fit-content"}
      paddingX={4}
      paddingY={2}
      defaultValues={doctorListFiltersFormDefaultValues}
      isEmpty={value => {
        const _value = value as IDoctorsListFiltersFormValues["callPrice"];
        return isEmpty(xor(_value, doctorListFiltersFormDefaultValues.callPrice));
      }}
    />
  );
};
