import { makeStyles } from "@toolkit/ui";

const useStyles = makeStyles<{ hasBlob?: boolean }>()((theme, { hasBlob }) => ({
  root: {
    zIndex: theme.zIndex.drawer,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: !hasBlob ? theme.palette.background.paper : "inherit",
    [theme.breakpoints.up("sm")]: {
      height: "100px",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      width: theme.breakpoints.values.sm,
      paddingInline: "10px",
    },
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      paddingInline: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingInline: "70px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingInline: "140px",
    },
  },
  MenuIconButton: {
    width: "35px",
    height: "35px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: theme.palette.primary.main,
    "& svg": {
      width: "21px",
      height: "13px",
      fill: theme.palette.text.primary,
    },
  },
  startItems: {
    display: "flex",
    alignItems: "center",
    flex: 2,
    "&&& .MuiIconButton-root": {
      backgroundColor: hasBlob ? "inherit" : theme.palette.primary[50],
    },
  },
  endItems: {
    paddingBlock: "5px",
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-end",
    gap: "5px",
  },
  iconButton: {
    width: "35px",
    height: "35px",
    borderRadius: "7px",
    backgroundColor: theme.palette.primary[50],
    marginInline: "3.5px",
    padding: "0px",
    "& svg": {
      height: "18px",
      width: "18px",
      fill: theme.palette.text.primary,
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "inherit",
      "& svg": {
        height: "22px",
        width: "22px",
      },
    },
  },
  avatar: {
    height: "38px",
    width: "38px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  backButton: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary[50],
    "& svg": {
      width: "18px",
      fill: theme.palette.text.primary,
    },
  },
  logo: {
    width: "150px",
    height: "48px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    position: "relative",
    left: "-15px",
  },
  logoLink: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    width: "min-content",
    padding: "0px",
  },
  loginButton: {
    paddingInline: "15px",
    height: "37.5px",
    borderRadius: "8px",
    "& path": {
      stroke: theme.palette.common.white,
    },
    "& circle": {
      stroke: theme.palette.common.white,
    },
    margin: "0px",
  },
}));

export default useStyles;
