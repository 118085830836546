import { useCallback, useEffect, useState } from "react";

export default function useFullscreen() {
  const [isFullscreen, setFullscreen] = useState(false);
  const onToggleFullscreen = useCallback(async () => {
    try {
      if (isFullscreen) {
        await document.exitFullscreen();
      } else {
        await document.body?.requestFullscreen();
      }
    } catch (e) {
      console.error(e);
    }
  }, [isFullscreen]);

  useEffect(() => {
    const onFullscreenchange = () => {
      setFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", onFullscreenchange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenchange);
    };
  }, [isFullscreen, setFullscreen]);

  return {
    isFullscreen,
    isFullscreenSupported: document.fullscreenEnabled,
    onToggleFullscreen,
  };
}
