import { useTranslation } from "@toolkit/i18n";
import { CallInstance, CallState } from "./Meetora";
import { useCallStyles } from "./Call.styles";
import { Box, Button, CustomIcon, IconButton, Rating, RatingIcon, Typography, useAddToast } from "@toolkit/ui";
import { SyntheticEvent, useCallback, useState } from "react";
import useSetStatsAndRating from "@meetora/react/queries/calls/stats/useSetStatsAndRating";
import useCountDown from "./useCountDown";
import { useRatingScreenStyles } from "./RatingScreen.styles";

type RatingScreenProps = {
  call: CallInstance;
  onClose?: () => void;
};

export const RatingScreen = ({ call, onClose }: RatingScreenProps) => {
  const { t } = useTranslation();
  const { succeeded } = useAddToast();
  const { classes: callClasses } = useCallStyles();
  const { classes: ratingClasses, theme } = useRatingScreenStyles();
  const { setStatsAndRating } = useSetStatsAndRating();

  const { state, resetCallState: handleReset } = call;
  const { callState } = state;
  const [rate, setRate] = useState<number | null>(0);

  const handleClose = useCallback(() => {
    handleReset();
    onClose?.();
  }, [handleReset, onClose]);

  const { countDown } = useCountDown(30, handleClose);

  const onChange = useCallback(
    async (event: SyntheticEvent, value: number | null) => {
      const { callId, peer } = call;
      setRate(value);
      if (!callId || !peer || value === null) {
        return;
      }

      const response = await setStatsAndRating({ callId, peer, rating: value, stats: "" });
      if (response?._name === "void") {
        succeeded(t("Thank you for your feedback!"));
      }

      handleClose();
    },
    [call, handleClose, setStatsAndRating, succeeded, t]
  );

  return (
    <Box className={callClasses.root}>
      <Box className={callClasses.container}>
        <Box className={callClasses.header}>
          <Box className={ratingClasses.header}>
            <Box className={ratingClasses.counter}>{countDown}</Box>
          </Box>
          <IconButton size='small' onClick={handleClose}>
            <CustomIcon color={theme.palette.common.white} icon='xAlert' />
          </IconButton>
        </Box>

        <Box className={callClasses.body} width={"320px !important"}>
          <Box className={ratingClasses.root}>
            <Box mb={3}>
              <Typography fontSize={theme.mixins.fonts.fontSize.xl}>
                {callState === CallState.Ended ? t("The call is ended") : t("You left the call")}
              </Typography>
            </Box>
            <Box mb={3} gap={1}>
              <Button disabled={callState !== CallState.Dropped} size='small' onClick={handleReset}>
                {t("Rejoin")}
              </Button>
              <Button variant='outlined' size='small' onClick={handleClose}>
                {t("Return to home screen")}
              </Button>
            </Box>
            <Box className={ratingClasses.content}>
              <Typography sx={{ pb: 2, textAlign: "initial" }}>{t("How was the call quality ?")}</Typography>

              <Rating
                value={rate}
                icon={<RatingIcon fill={theme.palette.common.white} />}
                emptyIcon={<RatingIcon fill={theme.palette.gray[800]} />}
                onChange={onChange}
                className={ratingClasses.input}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
