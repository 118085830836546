import { makeStyles } from "@toolkit/ui";

export const useCareTeamRegistrationSuccessContainerStyle = makeStyles()(theme => ({
  iconContainer: {
    minWidth: "58px",
    minHeight: "58px",
    borderRadius: "50%",
    boxShadow: theme.mixins.shadows.md,
    marginInlineEnd: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "29px",
      height: "29px",
    },
  },
  infoCard: {
    marginTop: "10px",
  },
  chatButton: {
    margin: 0,
    padding: 0,
    textDecoration: "underline",
    color: theme.palette.secondary.main,
  },
}));
