import { useCallback, useEffect, useState } from "react";
import { parseUserId } from "./util";
import { MEETORA_STORAGE_USER_KEY as KEY, MeetoraClient } from "./MeetoraClient";

export const useLoggedUser = (token: string | undefined) => {
  const [loggedUser, setLoggedUser] = useState<string | null>(() => localStorage.getItem(KEY) ?? null);

  const onSignin = useCallback((value: string | null) => {
    const userId = parseUserId(value);
    setLoggedUser(userId || null);

    if (!userId) {
      localStorage.removeItem(KEY);
    } else {
      localStorage.setItem(KEY, `${userId}`);
    }
  }, []);

  useEffect(() => {
    const userId = parseUserId(token);
    if (!userId || !loggedUser || userId === loggedUser) {
      return;
    }

    MeetoraClient.logout();
  }, [token, loggedUser]);

  return { onSignin };
};
