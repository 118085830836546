import { Theme } from "@toolkit/ui";
import { i18n } from "@toolkit/i18n";
import { CalculatedPaymentStatusForSubscription } from "@health/queries/types";
import { CacheNotificationIcon } from "@/components";

type PaymentNotificationStatus = {
  display: string;
  color?: string;
  icon: typeof CacheNotificationIcon;
};

export const paymentNotificationStatusMapper = (
  theme: Theme
): Record<CalculatedPaymentStatusForSubscription, PaymentNotificationStatus> => ({
  [CalculatedPaymentStatusForSubscription.PaymentRejected]: {
    display: i18n.t("Payment Rejected", { ns: "consumer" }),
    color: theme.palette.error.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundRejected]: {
    display: i18n.t("Refund Rejected", { ns: "consumer" }),
    color: theme.palette.error.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.PaymentPending]: {
    display: i18n.t("Payment Pending", { ns: "consumer" }),
    color: theme.palette.warning.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundPending]: {
    display: i18n.t("Refund Pending", { ns: "consumer" }),
    color: theme.palette.warning.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.PaymentSucceeded]: {
    display: i18n.t("Payment Succeeded", { ns: "consumer" }),
    color: theme.palette.success.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundSucceeded]: {
    display: i18n.t("Refund Succeeded", { ns: "consumer" }),
    color: theme.palette.success.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundedToWallet]: {
    display: i18n.t("Refunded to Wallet", { ns: "consumer" }),
    color: theme.palette.success.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.PaymentChargedBack]: {
    display: i18n.t("Payment Charged Back", { ns: "consumer" }),
    color: theme.palette.error.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.RefundChargedBack]: {
    display: i18n.t("Refund Charged Back", { ns: "consumer" }),
    color: theme.palette.error.main,
    icon: CacheNotificationIcon,
  },
  [CalculatedPaymentStatusForSubscription.PaymentUnderProcessing]: {
    display: i18n.t("Payment Under Processing", { ns: "consumer" }),
    color: theme.palette.warning.main,
    icon: CacheNotificationIcon,
  },
});
