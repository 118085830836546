import { VirtualVisitAvailableDoctorsContainer } from "./containers/VirtualVisitAvailableDoctorsContainer/VirtualVisitAvailableDoctorsContainer";
import { VirtualVisitPaymentSummaryContainer } from "./containers/VirtualVisitPaymentSummaryContainer/VirtualVisitPaymentSummaryContainer";

export const VirtualVisitRoute = "/virtual-visit";

export const routes = {
  root: {
    route: "",
    component: VirtualVisitAvailableDoctorsContainer,
    get absRoute() {
      return `${VirtualVisitRoute}${this.route}`;
    },
  },
  callPaymentSummary: {
    route: "/call-payment-summary",
    component: VirtualVisitPaymentSummaryContainer,
    get absRoute() {
      return `${VirtualVisitRoute}${this.route}`;
    },
    getRouteWithParams({ doctorId }: { doctorId: string }) {
      return `${this.absRoute}?doctorId=${doctorId}`;
    },
  },
};
