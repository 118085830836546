import { makeStyles } from "@toolkit/ui";

export const useCareTeamCompletionCardStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    padding: "20px",
  },
  title: {
    marginTop: "20px",
    fontSize: theme.mixins.fonts.fontSize.md,
    lineHeight: "30px",
  },
  iconContainer: {
    width: "145px",
    height: "131px",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  teamName: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
}));
