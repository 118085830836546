import { FC } from "react";
import { GetVisitQuery } from "../../gql";
import { Box, RescheduleIcon, Typography } from "@toolkit/ui";
import { useCallPageActionsStyle } from "./CallPageActionsStyle";
import { CallIcon, CircularIconButton, PhoneReceiverIcon } from "@/components";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";
import { routes } from "@/domains/VirtualVisit/routes";

type CanceledActionsProps = {
  visit: GetVisitQuery["visit"];
  hideRetry?: boolean;
  hideReschedule?: boolean;
};

export const CanceledActions: FC<CanceledActionsProps> = props => {
  const { visit, hideRetry, hideReschedule } = props;
  const { classes } = useCallPageActionsStyle();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(routesPaths.home);
  };
  const handleRetry = () => {
    navigate(routes.callPaymentSummary.getRouteWithParams({ doctorId: visit?.doctor?.id || "" }));
  };
  const handleReschedule = () => {
    navigate(routesPaths.appointments);
  };
  return (
    <Box className={classes.root}>
      {!hideRetry && (
        <Box className={classes.buttonContainer}>
          <CircularIconButton onClick={handleRetry} className={classes.iconButton} height='60px' width='60px'>
            <CallIcon rotation={-90} />
          </CircularIconButton>
          <Typography className={classes.label}>{t("Retry")}</Typography>
        </Box>
      )}
      {!hideReschedule && (
        <Box className={classes.buttonContainer}>
          <CircularIconButton onClick={handleReschedule} className={classes.iconButton} height='60px' width='60px'>
            <RescheduleIcon />
          </CircularIconButton>
          <Typography className={classes.label}>{t("Reschedule")}</Typography>
        </Box>
      )}
      <Box className={classes.buttonContainer}>
        <CircularIconButton onClick={handleClose} className={classes.declineButton} height='60px' width='60px'>
          <PhoneReceiverIcon width='100%' height='100%' />
        </CircularIconButton>
        <Typography className={classes.label}>{t("Close")}</Typography>
      </Box>
    </Box>
  );
};
