import React from "react";
import { Box, Typography, IconButton } from "@toolkit/ui";
import useStyles from "./DoctorServiceButton.styles";

interface ServiceOptionProps {
  icon: React.ReactNode;
  label: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
}

export const DoctorServiceButton: React.FC<ServiceOptionProps> = props => {
  const { icon, label, onClick, disabled, classes: _classes } = props;
  const { classes, cx } = useStyles({ disabled });
  return (
    <Box
      className={cx(classes.container, _classes?.container)}
      onClick={!disabled ? onClick : undefined}
      aria-label={label}
      aria-disabled={disabled}
    >
      <IconButton className={cx(classes.button, _classes?.button)} aria-label={label} disabled={disabled}>
        {icon}
      </IconButton>
      <Typography className={cx(classes.label, _classes?.label)}>{label}</Typography>
    </Box>
  );
};
