import { makeStyles } from "@toolkit/ui";

export const useCareTeamMembersListStyle = makeStyles()(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    gap: "0px",
  },
  card: {
    marginBlock: "5px",
  },
}));
