import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, CustomIcon, IconButton, Typography, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useCareTeamDisEnrollButtonStyle } from "./CareTeamDisEnrollButtonStyle";
import { useOpenState } from "@toolkit/core";
import { useGuidedCareTeamDisEnrollMutation } from "../../gql/mutations";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";

type CareTeamDisEnrollButtonProps = {
  teamId?: string;
  teamName?: string | null;
};

export const CareTeamDisEnrollButton: FC<CareTeamDisEnrollButtonProps> = props => {
  const { teamName, teamId } = props;
  const { t } = useTranslation("consumer");
  const { classes } = useCareTeamDisEnrollButtonStyle();
  const { failed, succeeded } = useAddToast();
  useAuth();
  const navigate = useNavigate();

  const { open, handleOpen, handleClose } = useOpenState();
  const { open: confirmOpen, handleOpen: handleOpenConfirm, handleClose: handleCloseConfirm } = useOpenState();

  const handleCloseModals = () => {
    handleClose();
    handleCloseConfirm();
  };
  const [disEnrollGuidedCareTeam, { loading }] = useGuidedCareTeamDisEnrollMutation({
    onCompleted: mutationData => {
      if (!mutationData?.disEnrollPatientToTeam?.providerGuidedCareHealthProgramTeamErrors?.length) {
        succeeded(t("You have successfully left the care team"));
        handleCloseModals();
        navigate(routesPaths.home);
      } else {
        failed(t("Failed to leave the care team. Please try again"));
        handleCloseModals();
      }
    },
    onError: () => {
      failed(t("Failed to leave the care team. Please try again"));
      handleCloseModals();
    },
  });

  const handleDisEnrollTeam = () => {
    disEnrollGuidedCareTeam({
      variables: {
        input: {
          teamId: teamId || "",
        },
      },
    });
  };
  return (
    <>
      {confirmOpen && (
        <CustomDialog
          type='delete'
          variant='consumer'
          isOpen={true}
          onClose={handleCloseModals}
          onDelete={handleDisEnrollTeam}
          isDeleteButtonDisabled={loading}
          text={{
            title: t(""),
            body: t("Are you sure you want to dis-enroll from {{teamName}}", { teamName: teamName || t("this team") }),
          }}
          classes={{
            body: classes.ConfirmBodyTitle,
          }}
        />
      )}
      {
        <CustomDialog handleClose={handleClose} hasDragBar={false} open={open} type='bottomSheet'>
          <Box className={classes.leaveButtonContainer}>
            <Button onClick={handleOpenConfirm} className={classes.leaveButton}>
              {t("Leave Care Team")}
            </Button>
          </Box>
        </CustomDialog>
      }
      <IconButton onClick={handleOpen}>
        <CustomIcon icon='threeDots' />
        <Typography className={classes.moreTitle}>{t("More")}</Typography>
      </IconButton>
    </>
  );
};
