import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useCommonStyles } from "@/domains/appointment/containers/CommonContainer.styles";
import { DoctorProfileCard } from "@/domains/doctor/components/DoctorProfileCard/DoctorProfileCard";
import { useGetDoctorProfileData } from "@/domains/doctor/containers/DoctorProfileContainer/useGetDoctorProfileData";
import { useStyle } from "@/domains/doctor/containers/DoctorProfileContainer/DoctorProfileContainerStyle";
import { CareTeamsDoctorTabs } from "../../components/CareTeamsDoctorTabs/CareTeamsDoctorTabs";
import { CareTeamRegistrationContainerDataType } from "../../type";
import { Location, useLocation } from "react-router-dom";

export const CareTeamDoctorProfileContainer: FC = () => {
  const { t } = useTranslation("consumer");
  const { doctorData, loading } = useGetDoctorProfileData(t);
  const { classes: commonClasses } = useCommonStyles();
  const { classes, cx } = useStyle();

  const location: Location<CareTeamRegistrationContainerDataType> = useLocation();
  const { team, teamLeader } = location.state || {};
  const leaderTeamsMembers = team?.teamMembers?.filter((member): member is NonNullable<typeof member> => member?.id !== teamLeader?.id);

  return (
    <Box className={commonClasses.pageContainer}>
      <Box className={cx(commonClasses.pageContent, classes.pageContent)}>
        {loading && (
          <Box mt={"40px"}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Box className={classes.generalInfoContainer}>
              <Typography className={classes.doctorName}>{doctorData?.user?.fullName}</Typography>
              <DoctorProfileCard hideActions doctorInfo={doctorData!} />
            </Box>
            <Box className={classes.doctorTabContainer}>
              <CareTeamsDoctorTabs
                teamMembers={leaderTeamsMembers}
                doctorInfo={doctorData!}
                visibleTabsKeys={["overview", "teamMembers", "qualifications", "availability"]}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
