import { Breakpoint, makeStyles, Theme } from "@toolkit/ui";

export const useStyles = makeStyles<{
  containerBreakpoint?: Breakpoint;
  containerBackground?: keyof Theme["palette"]["background"];
}>()((theme, { containerBreakpoint, containerBackground }) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    "--height-fallback": "100vh",
    "--height-modern": "100dvh",
    height: "min(100dvh, 100%)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  contentWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    position: "relative",
    overflowX: "hidden",
    backgroundColor: containerBackground ? theme.palette.background[containerBackground] : "inherit",
  },
  container: {
    flex: 1,
    position: "relative",
    overflowY: "auto",
    scrollbarWidth: "none",
    overflowX: "hidden",
    maxWidth: containerBreakpoint ? theme.breakpoints.values[containerBreakpoint] : undefined,
    width: "100%",
  },
  header: {
    width: "100%",
    maxHeight: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footer: {
    width: "100%",
    maxHeight: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  blobContainer: {
    width: "max-content",
    height: "max-content",
    position: "absolute",
    display: "",
    top: "-88.66015625px",
    pointerEvents: "none",
    [theme.direction === "ltr" ? "left" : "right"]: "-67.9296875px",
    zIndex: 1,
    "& svg": {
      width: "300px",
      height: "300px",
      fill: theme.palette.primary.main,
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));
