import { FC } from "react";
import { Box, Button, CircularProgress, Typography, MuiCallEndIcon } from "@toolkit/ui";
import { useConnectionState } from "./useConnectionState";
import { CallInstance } from "./Meetora";
import { useConnectionStateViewStyles } from "./ConnectionStateView.styles";

type ConnectionStateViewProps = {
  call: CallInstance;
};

export const ConnectionStateView: FC<ConnectionStateViewProps> = ({ call }) => {
  const { classes, theme } = useConnectionStateViewStyles();
  const { isEnabled, title, isLoading, handleDropCall } = useConnectionState(call);
  if (!isEnabled) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Typography py={2} textAlign='center' fontSize={theme.mixins.fonts.fontSize.lg}>
        {title}
      </Typography>
      <Box>
        {isLoading ? (
          <CircularProgress color='inherit' />
        ) : (
          <Button onClick={handleDropCall} color='error'>
            <MuiCallEndIcon />
          </Button>
        )}
      </Box>
    </Box>
  );
};
