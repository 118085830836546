import { PageLayout } from "./PageLayout";
import { PageHeaderWithBack } from "../PageHeader";
import { PageLayoutProps } from "./type";

export const PageWithBackHeaderLayout = (props: PageLayoutProps) => {
  const { containerBackground, containerBreakpoint, hideBack = false, withBlob = false, children } = props;
  return (
    <PageLayout
      containerBackground={containerBackground}
      containerBreakpoint={containerBreakpoint}
      displayBlob={withBlob}
      header={<PageHeaderWithBack hideBack={hideBack} />}
    >
      {children}
    </PageLayout>
  );
};
