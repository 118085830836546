import { createContext, useContext, useEffect } from "react";
import { CallInstance } from "./Meetora";

export type CallInfoParams = { chatId: string; callUrl?: string };

export const CallContext = createContext<{
  call: CallInstance | undefined;
  callInfo: CallInfoParams | undefined;
  select: (info: CallInfoParams) => void;
}>({
  callInfo: undefined,
  call: undefined,
  select: () => Error("Not implemented!"),
});

export function useActiveCall() {
  const { call, callInfo } = useContext(CallContext);
  const isInCall = call?.state.callState === "in_call";
  if (isInCall) {
    return { call, callInfo };
  }

  return null;
}

export function useSelectCall(info?: CallInfoParams) {
  const { callInfo, select, call } = useContext(CallContext);
  useEffect(() => {
    if (info) {
      select(info);
    }
  }, [info?.chatId, info?.callUrl]);

  if (info?.chatId !== callInfo?.chatId) {
    return undefined;
  }

  return call;
}
