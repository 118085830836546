import {
  doctorListFiltersFormDefaultValues,
  DoctorsListFiltersFormSchema,
  IDoctorsListFiltersFormValues,
} from "../DoctorsListFiltersFormSchema";
import { GenericFilterForm } from "@/components/filter/GenericFilterForm";
import { useTranslation } from "@toolkit/i18n";
import { DoctorFilterFormProps } from "../types";
import { VendorsAutocomplete } from "@health/autocompletes";

export const FacilityFilterForm: React.FC<DoctorFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IDoctorsListFiltersFormValues>
      schema={DoctorsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='vendors'
      dialogTitle={t("Facility")}
      formLabel={t("Facility")}
      render={name => <VendorsAutocomplete name={name} multiple />}
      defaultValues={doctorListFiltersFormDefaultValues}
    />
  );
};
