import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "hidden",
  },
  filterContainer: {
    marginBlock: "5px 10px",
  },
  title: {
    marginBlock: "10px 5px",
    marginInline: "10px",
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
  },
  content: {
    flexGrow: 1,
    maxHeight: "100%",
  },
  contentForEmptyPage: {
    display: "flex",
    justifyContent: "center",
  },
  listCard: {
    marginInline: "10px",
  },
  emptyPageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flex: 1,
    justifySelf: "center",
    alignSelf: "center",
    padding: "10px",
    paddingBottom: "100px",
  },
  card: {
    cursor: "pointer",
  },
}));
