import { Slider, styled } from "@toolkit/ui";

export const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 5,
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.primary[50],
    "::after": {
      content: "''",
      position: "absolute",
      width: "14px",
      height: "14px",
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
    },
  },
  "& .MuiSlider-rail": {
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary[50],
    opacity: 1,
    height: 5,
  },
  "& .MuiSlider-valueLabel": {
    color: theme.palette.primary.main,
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    fontSize: theme.mixins.fonts.fontSize.lg,
    backgroundColor: "unset",
    height: "5px",
  },
}));
