/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetWalletBalanceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetWalletBalanceQuery = { __typename?: 'Query', me?: { __typename?: 'User', patient?: { __typename?: 'Patient', wallet?: { __typename?: 'Wallet', balance?: number | null, currency?: string | null } | null } | null } | null };


export const GetWalletBalanceDocument = gql`
    query getWalletBalance {
  me {
    patient {
      wallet {
        balance
        currency
      }
    }
  }
}
    `;

/**
 * __useGetWalletBalanceQuery__
 *
 * To run a query within a React component, call `useGetWalletBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWalletBalanceQuery(baseOptions?: Apollo.QueryHookOptions<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>(GetWalletBalanceDocument, options);
      }
export function useGetWalletBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>(GetWalletBalanceDocument, options);
        }
export type GetWalletBalanceQueryHookResult = ReturnType<typeof useGetWalletBalanceQuery>;
export type GetWalletBalanceLazyQueryHookResult = ReturnType<typeof useGetWalletBalanceLazyQuery>;
export type GetWalletBalanceQueryResult = Apollo.QueryResult<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>;