import { makeStyles } from "@toolkit/ui";

export const useCallErrorStyles = makeStyles()(theme => ({
  root: {
    minHeight: "100%",
    padding: "0 0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
  },
  icon: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    width: 70,
    height: 70,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
