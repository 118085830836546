import { useEffect, useRef, useState } from "react";

const useContainerHeight = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    const updateHeight = (entries: ResizeObserverEntry[]) => {
      setContainerHeight(entries[0].contentRect.height);
    };

    const observer = new ResizeObserver(updateHeight);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return { containerRef, containerHeight };
};

export default useContainerHeight;
