import { AmountSourceType } from "@health/queries/types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logIoEvent, logPaymentEvent } from "../constants";
import { CustomWebViewProps } from "../custom-web-view/custom-web-view.component";
import { usePreparePayment } from "../hooks/use-prepare-payment.hook";
import { useOnPaymentStatusUpdate } from "@health/sse";

type PaymentWebView = {
  amountSourceId: string;
  amountSource: AmountSourceType;
  onSuccess?: () => void;
  onPending?: () => void;
  onRefundedToWallet?: () => void;
  onError?: () => void;
};

const usePaymentWebView = ({ amountSourceId, amountSource, onSuccess, onError, onPending, onRefundedToWallet }: PaymentWebView) => {
  const { paymentInfo, failedPayment, isPaymentProcessLoading, getCheckoutId, fetchCreateCheckoutId, getPreparedShopperResultUrl } =
    usePreparePayment();

  const { checkoutPaymentFormUrl, shopperResultUrl } = paymentInfo.current;
  const navigate = useNavigate();
  // const { failed } = useAddToast();

  useOnPaymentStatusUpdate(data => {
    console.log("Payment Status Update", { data });
    if (
      !data?.data.changes.find(d => d.field == "amountSourceId" && d.value == amountSourceId) &&
      !data?.data.changes.find(d => d.field == "amountSourceType" && d.value == amountSource)
    )
      return;
    const paymentStatus = data?.data.changes.find(d => d.field == "paymentStatus")?.value;

    if (paymentStatus === "PAYMENT_SUCCEEDED") {
      onSuccess?.();
    } else if (paymentStatus === "PAYMENT_ERROR" || paymentStatus === "PAYMENT_REJECTED") {
      onError?.();
    } else if (paymentStatus === "PAYMENT_PENDING") {
      onPending?.();
    } else if (paymentStatus === "REFUNDED_TO_WALLET") {
      onRefundedToWallet?.();
    }
  });

  const handleOnLoadStart: CustomWebViewProps["handleOnLoadStart"] = e => {
    console.log("handleOnLoadStart", e, onSuccess, onError);
    // const { nativeEvent } = e;
    return;
  };

  const handleOnRefresh = () => {
    fetchCreateCheckoutId({
      variables: {
        input: {
          amountSourceId: amountSourceId!,
          amountSourceType: amountSource!,
        },
      },
    });
  };

  const handleGoToHome = () => {
    navigate("/");
  };

  logIoEvent("payment from webview", {
    resultUrl: getPreparedShopperResultUrl(),
    checkoutId: getCheckoutId(),
    shopperResultUrl,
    checkoutPaymentFormUrl,
  });

  useEffect(() => {
    if (!amountSourceId || !amountSource) {
      return;
    }

    if (!checkoutPaymentFormUrl) {
      fetchCreateCheckoutId({
        variables: {
          input: {
            amountSourceId: amountSourceId!,
            amountSourceType: amountSource!,
          },
        },
      });
    }
  }, [checkoutPaymentFormUrl, amountSourceId, amountSource, fetchCreateCheckoutId]);

  useEffect(() => {
    if (!amountSource || !amountSourceId || !checkoutPaymentFormUrl) {
      return;
    }

    logPaymentEvent({
      resultUrl: getPreparedShopperResultUrl(),
      checkoutUrl: checkoutPaymentFormUrl,
      amountSourceId: amountSourceId!,
      amountSourceType: amountSource!,
    });
  }, [checkoutPaymentFormUrl, amountSourceId, amountSource, getPreparedShopperResultUrl]);

  return {
    failedPayment,
    isPaymentProcessLoading,
    checkoutPaymentFormUrl,
    handleGoToHome,
    handleOnRefresh,
    handleOnLoadStart,
    getPreparedShopperResultUrl,
  };
};

export default usePaymentWebView;
