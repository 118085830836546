import { isLanguageEnglish } from "@toolkit/i18n";

export const formatCurrency = (value: number, currency: string): string => {
  if ((!value && value !== 0) || !currency) {
    return "";
  }
  let trustedCurrency = currency;
  if (trustedCurrency !== "SAR") {
    trustedCurrency = "AED";
  }
  let amount = "0";
  /*
   * when language is english, the currency symbol should be on the left side of the number
   * when language is arabic, the currency symbol should be on the right side of the number:
   * because of that we added this work around to make sure the currency symbol is displayed correctly
   * when arabic it was return the currency symbol on the left side of the number
   */

  try {
    const formattedCurrency = new Intl.NumberFormat("en", {
      style: "currency",
      currency: "USD",
      currencyDisplay: "code",
    }).format(value);

    amount = formattedCurrency.replace("USD", "");
  } catch (error) {
    amount = value.toFixed(2);
  }
  return trsCurrency(trustedCurrency) + " " + amount;
};
export const trsCurrency = (currency: string) => {
  if (isLanguageEnglish()) {
    return currency;
  }

  if (currency === "AED") {
    return "د.إ";
  }

  if (currency === "SAR") {
    return "ر.س";
  }

  return currency;
};
