import { Box, Button, CardsContainer, Typography, useMediaQuery } from "@toolkit/ui";
import React from "react";
import { useStyle } from "./ProductsSectionStyle";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { useTranslation } from "@toolkit/i18n";
import { ArrowIcon, CircularIconButton } from "@/components";
import { ProductCard, ProductCardSkeleton, useMarketplaceProductsForYouQuery } from "@/domains/marketplace-products";
import { useAuth } from "react-oidc-context";

export const ProductsSection: React.FC = () => {
  const { classes, cx, theme } = useStyle();
  const { classes: commonClasses } = useCommonSectionsStyle();
  const { t } = useTranslation("consumer");
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useAuth();
  const { data, loading } = useMarketplaceProductsForYouQuery({
    variables: {
      first: 9,
    },
    skip: !user?.access_token,
  });
  const products = data?.marketplaceProductsForYou?.edges?.map(edge => edge?.node) || [];
  const handleSeeAll = () => {
    // implement see all
  };
  return (
    <Box className={cx(commonClasses.root, classes.root, "section__root", "products")}>
      <Box className={cx(commonClasses.container, classes.container)}>
        <Box className={commonClasses.titleContainer}>
          <Typography className={cx(commonClasses.title, "title")}>{t("Featured items")}</Typography>
          <Button onClick={handleSeeAll} className={cx(commonClasses.titleSeeAll, "title__btn")} variant='text'>
            {t("See All")}
          </Button>
        </Box>
        <CardsContainer
          classes={{
            cardsContainerClassName: classes.cardsContainerClassName,
            navBtnClassName: classes.navBtnClassName,
            navBtnContainerClassName: classes.navBtnContainerClassName,
            cardsContainer: classes.cardsContainer,
            cardsUpperContainer: classes.cardsUpperContainer,
            card: classes.card,
            skeletonContainer: classes.skeletonContainer,
          }}
          isLoadingData={loading}
          countOfSkeletons={7}
          CardSkeletonComponent={
            <Box className={cx(classes.productCardWrapper, "productCard__wrapper")}>
              <ProductCardSkeleton />
            </Box>
          }
          cardsScrollAmount={2}
          hideNavBtns={isSmall}
          cards={products?.map(product => (
            <Box key={product?.id} className={cx(classes.productCardWrapper, "productCard__wrapper")}>
              <ProductCard product={product} />
            </Box>
          ))}
        />
        <Box className={classes.actionsContainer}>
          <Box className={classes.seeAllButton__container}>
            <Typography className={"see-all__title"}>{t("See All")}</Typography>
            <CircularIconButton onClick={handleSeeAll} height={"46px"} className={commonClasses.seeAllButton}>
              <ArrowIcon fill={theme.palette.common.white} />
            </CircularIconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
