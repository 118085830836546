import { PageLayout } from "@/components";
import { PageLayoutProps } from "@/components/PageLayout/type";
import { useMeetoraCallPageLayoutStyle } from "./MeetoraCallPageLayoutStyle";

export const MeetoraCallPageLayout = (props: PageLayoutProps) => {
  const { containerBreakpoint, withBlob = false, children } = props;

  const { classes } = useMeetoraCallPageLayoutStyle();
  return (
    <PageLayout
      displayBlob={withBlob}
      containerBreakpoint={containerBreakpoint}
      containerBackground='paper'
      classes={{ container: classes.content }}
    >
      {children}
    </PageLayout>
  );
};
