import { FC } from "react";
import { GetVisitQuery } from "../../gql";
import { useCallDoctorAvatarStyle } from "../CallDoctorAvatar/CallDoctorAvatarStyle";
import { Box, PulseConnection } from "@toolkit/ui";
import { VisitStatus } from "@health/queries/types";
import { PhoneReceiverIcon } from "@/components";
import { CallDoctorAvatar } from "../CallDoctorAvatar/CallDoctorAvatar";

type CallPageIconProps = {
  visit: GetVisitQuery["visit"];
  isLoading?: boolean;
};

export const CallPageIcon: FC<CallPageIconProps> = ({ visit, isLoading }) => {
  const { classes, cx } = useCallDoctorAvatarStyle();
  const doctor = visit?.doctor;
  const renderIcon = () => {
    switch (visit?.status) {
      case VisitStatus.CallTimedOut:
      case VisitStatus.Rejected:
      case VisitStatus.Canceled:
      case VisitStatus.CanceledByConsumer:
      case VisitStatus.EndedByOneOfTheParticipants:
      case VisitStatus.DoctorAnswerTimedOut:
      case VisitStatus.CallRejoinable:
        return (
          <Box className={cx(classes.root, classes.declineCallIcon)}>
            <PhoneReceiverIcon width='100%' height='100%' />
          </Box>
        );
      default:
        return <CallDoctorAvatar doctorName={doctor?.user?.fullName || ""} src={doctor?.user?.photo || ""} gender={doctor?.user?.gender} />;
    }
  };

  return (
    <>
      {isLoading && !visit?.id ? (
        <Box className={classes.root}>
          <PulseConnection width='100%' height='100%' />
        </Box>
      ) : (
        renderIcon()
      )}
    </>
  );
};
