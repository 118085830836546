import { DoctorProfileForTabs } from "@/domains/doctor/type";
import { Box, format } from "@toolkit/ui";
import { FC } from "react";
import { useDoctorProfileTabsStyle } from "../DoctorProfileTabsStyles";

type QualificationBodyProps = {
  doctorInfo: DoctorProfileForTabs;
};
export const QualificationBody: FC<QualificationBodyProps> = props => {
  const { doctorInfo } = props;
  const { classes } = useDoctorProfileTabsStyle();
  return (
    <Box>
      {doctorInfo?.qualifications?.map(qualification => (
        <Box key={qualification?.id} className={classes.qualificationContainer}>
          <Box className={classes.qualificationText}>{format(qualification?.toDate, "yyyy")}</Box>
          <Box>{qualification?.issuer}</Box>
        </Box>
      ))}
    </Box>
  );
};
