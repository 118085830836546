import { makeStyles } from "@toolkit/ui";

export const useSectionTabsStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },
  tabsUpperContainer: {
    width: "100%",
    height: "100%",
    borderBlockEnd: "1px solid",
    borderColor: theme.palette.divider,
  },
  tab: {
    border: "none",
    borderRadius: 0,
    padding: 0,
    margin: 0,
    width: "fit-content",
    paddingInline: "10px",
    fontSize: theme.mixins.fonts.fontSize.sm,
    color: theme.palette.stale.main,
  },
  tabsContainerClassName: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    position: "relative",
  },
  navBtnContainer: {
    width: "fit-content",
    padding: 0,
    "& svg": {
      width: "6px",
      height: "20px",
    },
    "& path": {
      fill: "inherit",
    },
  },
  activeTab: {
    position: "relative",
    color: theme.palette.primary.main,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: -0,
      left: 0,
      width: "100%",
      height: "2px",
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
