import { FC } from "react";
import { MeetoraGuestCall } from "@health/meetora";

type VisitCallGuestProps = {
  token: string;
};

export const VisitCallGuest: FC<VisitCallGuestProps> = ({ token }) => {
  return <MeetoraGuestCall token={token} />;
};
