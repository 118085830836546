import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    borderRadius: "0px",
    boxShadow: "none",
    padding: "5px",
    paddingBottom: "5px",
  },
  avatarContainer: {
    width: "145px",
    height: "145px",
  },
  avatar: {
    "& img": {
      width: "135px",
      height: "135px",
    },
  },
  specialty: {
    color: theme.palette.text.primary,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  vendorName: {
    color: theme.palette.stale.main,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  experience: {
    color: theme.palette.stale.main,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  price: {
    color: theme.palette.primary.main,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  downAvatarContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  button: {
    width: "38px",
    height: "38px",
    border: "none",

    "& svg": {
      fill: theme.palette.primary.main,
      width: "17px",
      height: "17px",
    },
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  languagesContainer: {
    marginTop: "10px",
    display: "flex",
    gap: "5px",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "100%",
  },
  languageContainer: {
    height: "30px",
    paddingInline: "20px",
    borderRadius: "15px",
    backgroundColor: theme.palette.primary[50],
    fontSize: theme.mixins.fonts.fontSize.xs,
    lineHeight: "28px",
  },
  doctorAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "min-content",
  },
  onlineStatus: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    color: theme.palette.success.main,
  },
  dot: {
    display: "inline-block",
  },
}));
