import { Box, Container, IconButton, makeStyles, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useHeaderContext } from "../../providers/HeaderProvider";
import { ArrowIcon } from "../Icons";
import useStyles from "./PageHeader.styles";

const useCustomHeaderStyles = makeStyles()(() => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
    minHeight: "50px",
    height: "max-content",
  },

  root: {
    padding: "10px!important",
  },

  titleContainer: {
    position: "absolute",
    left: "calc(50% - 17.5px)",
    transform: "translateX(-50%)",
    width: "100%",
    textIndent: "35px",
  },
}));

type PageHeaderWithBackProps = {
  hideBack?: boolean;
};

export const PageHeaderWithBack: FC<PageHeaderWithBackProps> = props => {
  const { hideBack } = props;
  const { classes, theme, cx } = useStyles({});
  const navigate = useNavigate();
  const { headerTitle } = useHeaderContext();
  const { classes: customClasses } = useCustomHeaderStyles();

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth='sm' className={cx(classes.container, customClasses.root)}>
      <Box className={customClasses.headerContainer}>
        <Box className={customClasses.titleContainer}>
          <Typography fontSize={theme.mixins.fonts.fontSize.lg}>{headerTitle}</Typography>
        </Box>
        {!hideBack && (
          <IconButton onClick={onBackClick} className={classes.iconButton}>
            <ArrowIcon direction='left' />
          </IconButton>
        )}
      </Box>
    </Container>
  );
};
