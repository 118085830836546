/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { DoctorInfoFragmentFragmentDoc } from './doctorInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AppointmentQuery = { __typename?: 'Query', appointment?: { __typename?: 'Appointment', id: string, type?: Types.AppointmentType | null, confirmedStartTime?: any | null, confirmedEndTime?: any | null, created?: any | null, status?: Types.AppointmentStatus | null, paymentStatus?: Types.PaymentStatus | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', type?: Types.AppointmentPaymentType | null, totalAmount?: number | null, priceAmountAfterVat?: number | null } | null, doctor?: { __typename?: 'Doctor', id: string, yearsOfExperience?: number | null, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, gender?: Types.UserGender | null, photo?: string | null } | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, arabicDisplay?: string | null, display?: string | null } | null> | null, vendor: { __typename?: 'Vendor', id: string, logo?: string | null, name: string, nameAr?: string | null }, languages?: Array<{ __typename?: 'Language', display: string, displayAr?: string | null, id: string } | null> | null } | null } | null };


export const AppointmentDocument = gql`
    query appointment($id: ID!) {
  appointment(id: $id) {
    id
    type
    confirmedStartTime
    confirmedEndTime
    created
    status
    paymentStatus
    paymentInfo {
      type
      totalAmount
      priceAmountAfterVat
    }
    doctor {
      ...DoctorInfoFragment
    }
  }
}
    ${DoctorInfoFragmentFragmentDoc}`;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentQuery(baseOptions: Apollo.QueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
      }
export function useAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentQuery, AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(AppointmentDocument, options);
        }
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>;
export type AppointmentQueryResult = Apollo.QueryResult<AppointmentQuery, AppointmentQueryVariables>;