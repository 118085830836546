import { FC } from "react";
import { DetailCard, SingleInfoDetailCardProps } from "./DetailCard";

export const SingleInfoWithIconDetailCard: FC<SingleInfoDetailCardProps> = props => {
  return (
    <DetailCard
      variant='singleInfoWithIcon'
      {...props}
      sx={{
        flexDirection: "row",
      }}
    />
  );
};
