import React, { FC, PropsWithChildren } from "react";
import { AuthContext } from "./context";
import { oidcUserManager } from "@/configs/oidc";
import { useAuth as odicUseAuth } from "react-oidc-context";

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, user } = odicUseAuth();

  const setAppToken = (token?: string) => {
    token;
    // do nothing for now
    // odicUserManger is already handling the token
    // keeping this function for future use
  };
  const getAppToken = () => {
    return user?.access_token ?? null;
  };

  const askToLogin = () => {
    oidcUserManager.signinRedirect();
  };

  const handleLogout = async () => {
    await oidcUserManager.clearStaleState();
    await oidcUserManager.signoutRedirect({
      id_token_hint: (await oidcUserManager.getUser())?.id_token,
      state: (await oidcUserManager.getUser())?.state,
      post_logout_redirect_uri: window.location.origin,
    });
    await oidcUserManager.removeUser();
    await oidcUserManager.revokeTokens();
    // localStorage.removeItem(AppAuth.TOKEN);
    // setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        askToLogin,
        setAppToken,
        getAppToken,
        doLogout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
