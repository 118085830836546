/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorProfileDataQueryVariables = Types.Exact<{
  doctorId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type DoctorProfileDataQuery = { __typename?: 'Query', doctor?: { __typename?: 'Doctor', id: string, isLanguagesPublic: boolean, isDateOfBirthPublic: boolean, isYearsOfExperiencePublic: boolean, yearsOfExperience?: number | null, isMobileNumberPublic: boolean, isNationalIdPublic: boolean, onlineVisitPrice?: number | null, ratingsSum: number, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, bio?: string | null, canAcceptCall: boolean, availabilityStatus: Types.DoctorAvailabilityStatusEnum, languages?: Array<{ __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null> | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', closeTime?: any | null, openTime?: any | null } | null> | null } | null> | null, qualifications?: Array<{ __typename?: 'Qualification', id: string, code: string, fromDate?: any | null, toDate?: any | null, issuer: string } | null> | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, code: string, display?: string | null, arabicDisplay?: string | null } | null> | null, user?: { __typename?: 'User', fullName?: string | null, photo?: string | null, gender?: Types.UserGender | null, nationalId?: string | null, dateOfBirth?: any | null, mobile?: string | null } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null }, experiences?: Array<{ __typename?: 'DoctorExperience', id: string, startDate: any, endDate?: any | null, jobTitle: string } | null> | null } | null };


export const DoctorProfileDataDocument = gql`
    query DoctorProfileData($doctorId: ID) {
  doctor(id: $doctorId) {
    id
    languages {
      code
      display
      displayAr
      id
    }
    isLanguagesPublic
    isDateOfBirthPublic
    isYearsOfExperiencePublic
    yearsOfExperience
    isMobileNumberPublic
    isNationalIdPublic
    onlineVisitPrice
    ratingsSum
    appointmentTypes
    bio
    canAcceptCall
    availabilityStatus
    workingHours {
      day
      openTimeRanges {
        closeTime
        openTime
      }
    }
    qualifications {
      id
      code
      fromDate
      toDate
      issuer
    }
    specializations {
      id
      code
      display
      arabicDisplay
    }
    user {
      fullName
      photo
      gender
      nationalId
      dateOfBirth
      mobile
    }
    vendor {
      id
      name
      nameAr
      logo
    }
    experiences {
      id
      startDate
      endDate
      jobTitle
    }
  }
}
    `;

/**
 * __useDoctorProfileDataQuery__
 *
 * To run a query within a React component, call `useDoctorProfileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorProfileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorProfileDataQuery({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *   },
 * });
 */
export function useDoctorProfileDataQuery(baseOptions?: Apollo.QueryHookOptions<DoctorProfileDataQuery, DoctorProfileDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorProfileDataQuery, DoctorProfileDataQueryVariables>(DoctorProfileDataDocument, options);
      }
export function useDoctorProfileDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorProfileDataQuery, DoctorProfileDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorProfileDataQuery, DoctorProfileDataQueryVariables>(DoctorProfileDataDocument, options);
        }
export type DoctorProfileDataQueryHookResult = ReturnType<typeof useDoctorProfileDataQuery>;
export type DoctorProfileDataLazyQueryHookResult = ReturnType<typeof useDoctorProfileDataLazyQuery>;
export type DoctorProfileDataQueryResult = Apollo.QueryResult<DoctorProfileDataQuery, DoctorProfileDataQueryVariables>;