/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorQueryVariables = Types.Exact<{
  doctorId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type DoctorQuery = { __typename?: 'Query', doctor?: { __typename?: 'Doctor', id: string, yearsOfExperience?: number | null, isYearsOfExperiencePublic: boolean, isLanguagesPublic: boolean, appointmentTypes?: Array<Types.AppointmentTypeEnum | null> | null, onlineVisitPrice?: number | null, atHomeVisitPrice?: number | null, onsiteVisitPrice?: number | null, totalRatings: number, ratingsSum: number, languages?: Array<{ __typename?: 'Language', code: string, display: string, displayAr?: string | null, id: string } | null> | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, code: string, display?: string | null } | null> | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, fullName?: string | null, photo?: string | null, gender?: Types.UserGender | null } | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null } } | null };


export const DoctorDocument = gql`
    query Doctor($doctorId: ID) {
  doctor(id: $doctorId) {
    id
    yearsOfExperience
    isYearsOfExperiencePublic
    languages {
      code
      display
      displayAr
      id
    }
    isLanguagesPublic
    appointmentTypes
    onlineVisitPrice
    atHomeVisitPrice
    onsiteVisitPrice
    totalRatings
    ratingsSum
    specializations {
      id
      code
      display
    }
    user {
      firstName
      lastName
      fullName
      photo
      gender
    }
    vendor {
      id
      name
      nameAr
      logo
    }
  }
}
    `;

/**
 * __useDoctorQuery__
 *
 * To run a query within a React component, call `useDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorQuery({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *   },
 * });
 */
export function useDoctorQuery(baseOptions?: Apollo.QueryHookOptions<DoctorQuery, DoctorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorQuery, DoctorQueryVariables>(DoctorDocument, options);
      }
export function useDoctorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorQuery, DoctorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorQuery, DoctorQueryVariables>(DoctorDocument, options);
        }
export type DoctorQueryHookResult = ReturnType<typeof useDoctorQuery>;
export type DoctorLazyQueryHookResult = ReturnType<typeof useDoctorLazyQuery>;
export type DoctorQueryResult = Apollo.QueryResult<DoctorQuery, DoctorQueryVariables>;