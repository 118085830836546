import { DoctorProfileContainer } from "./containers/DoctorProfileContainer/DoctorProfileContainer";

export const DoctorBaseRoute = "/doctor";

export const routes = {
  profile: {
    route: "/profile",
    component: DoctorProfileContainer,
    get absRoute() {
      return `${DoctorBaseRoute}${this.route}`;
    },
    getRouteWithParams({ doctorId }: { doctorId: string }) {
      return `${this.absRoute}?doctorId=${doctorId}`;
    },
  },
};
