import { PatientEligibleTeamFilterInput } from "@health/queries/types";
import { ICareTeamsListFiltersFormValues } from "./CareTeamsListFiltersFormSchema";

const mapFilterValues = <T, K>(items: T[] | undefined, mapFn: (item: T) => K | undefined): K[] | undefined => {
  return items?.map(mapFn).filter((value): value is K => value !== undefined);
};

export const convertCareTeamsFilterFormValuesToBackEndValues = (
  values: ICareTeamsListFiltersFormValues
): PatientEligibleTeamFilterInput => {
  return {
    providerId: values?.vendor?.value?.id,
    payerId: values?.payer?.value?.id,
    doctorIds: mapFilterValues(values?.doctors, doctor => doctor?.value?.id),
    name: values?.name,
  };
};
