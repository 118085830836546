import { useGuidedCareTeamListQuery } from "../../gql";

export const useCareTeamsList = ({ filters, patientId }) => {
  const { data, loading, fetchMore, refetch } = useGuidedCareTeamListQuery({
    variables: {
      filter: filters,
    },
    skip: !patientId,
    fetchPolicy: "cache-and-network",
  });

  const careTeams = data?.patientEligibleTeams?.edges?.map(edge => edge.node) || [];
  const pageInfo = data?.patientEligibleTeams?.pageInfo;

  const loadMoreTeams = () => {
    if (pageInfo?.hasNextPage && fetchMore) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo.endCursor || "",
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.patientEligibleTeams) {
            return previousResult;
          }

          const newEdges = [...(previousResult?.patientEligibleTeams?.edges || []), ...(fetchMoreResult.patientEligibleTeams.edges || [])];

          return {
            ...previousResult,
            patientEligibleTeams: {
              ...previousResult.patientEligibleTeams,
              edges: newEdges,
              pageInfo: fetchMoreResult.patientEligibleTeams.pageInfo,
            },
          };
        },
      });
    }
  };

  const handleRefresh = () => {
    refetch({
      filter: filters,
      first: 10,
    });
  };
  return { careTeams, pageInfo, isLoading: loading, fetchMoreData: loadMoreTeams, handleRefresh };
};
