import { FC, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { CallProvider, Call } from "@health/meetora";
import { useNavigate } from "react-router-dom";

type VisitCallProps = {
  meetingLink: string;
};

export const VisitCall: FC<VisitCallProps> = ({ meetingLink }) => {
  // todo: apply refresh token logic
  const { user } = useAuth();
  const token = user?.access_token;
  const navigate = useNavigate();
  const onRequestClose = useCallback(() => {
    window.close();
    navigate("/");
  }, [navigate]);

  if (!token) {
    return <div>Unauthorized</div>;
  }

  return (
    <CallProvider>
      <Call invitationCode={meetingLink} onClose={onRequestClose} />
    </CallProvider>
  );
};
