import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "hidden",
  },
  root: {
    color: theme.palette.common.white,
    borderRadius: 15,
    background: `linear-gradient(90deg, #712661,${theme.palette.primary.main} )`,
    padding: 16,
    margin: 10,
    boxShadow: theme.mixins.shadows.md,
    [theme.breakpoints.up("sm")]: {
      padding: 15,
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 240,
      ".card__container": {
        gap: 20,
      },
      ".card__subtitle": {
        fontSize: theme.mixins.fonts.fontSize.xxxl,
        fontWeight: theme.mixins.fonts.fontWeight.bold,
      },
      ".card__title": {
        fontSize: theme.mixins.fonts.fontSize.md,
        fontWeight: theme.mixins.fonts.fontWeight.light,
      },
    },
  },
  title: {
    marginBlock: "10px 5px",
    marginInline: "10px",
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
  },
  text: {
    textAlign: "start",
  },
  iconWrapper: {
    "& svg": {
      width: 50,
      height: 50,
    },
    paddingInlineStart: 2,
  },

  textWrapper: {
    textAlign: "start",
  },
  typographyTitle: {
    lineHeight: 1,
    fontSize: theme?.mixins.fonts.fontSize.md,
  },
  typographySubtitle: {
    fontSize: theme?.mixins.fonts.fontSize.xxxl,
    fontWeight: theme?.mixins.fonts.fontWeight.bold,
  },
  overview: {
    marginBlock: "10px",
    marginInline: "10px",
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  emptyPageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flex: 1,
    justifySelf: "center",
    alignSelf: "center",
    padding: "10px",
    paddingBottom: "100px",
  },
  contentForEmptyPage: {
    display: "flex",
    justifyContent: "center",
  },
  listCard: {
    marginInline: "10px",
  },
  box: {
    flexGrow: 1,
    height: "100%",
    overflowY: "hidden",
  },
}));
