import { CustomCard } from "@/components/CustomCard";
import { CardProps, styled, Typography, useTheme, Box } from "@toolkit/ui";
import { ReactNode } from "react";

const Card = styled(CustomCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  row-gap: 4px;
  position: relative;
`;

interface DetailCardProps extends Omit<CardProps, "title" | "variant" | "content"> {
  title: string;
  variant?: "singleInfo" | "multipleInfo" | "singleInfoWithIcon";
  actions?: ReactNode;
}
export interface MultipleInfoDetailCardProps extends DetailCardProps {
  variant: "multipleInfo";
  items: Array<{ label: string; value: string }>;
  actions?: ReactNode;
  startIcon?: ReactNode;
}
export interface SingleInfoDetailCardProps extends DetailCardProps {
  variant?: "singleInfo" | "singleInfoWithIcon";
  content: string | ReactNode;
  actions?: ReactNode;
  startIcon?: ReactNode;
}
export const DetailCard: React.FC<MultipleInfoDetailCardProps | SingleInfoDetailCardProps> = props => {
  const { title, variant = "singleInfo", sx = {}, startIcon, actions, ...cardProps } = props;
  const theme = useTheme();

  return (
    <Card {...(cardProps as CardProps)} sx={{ my: 1, display: "flex", ...sx }}>
      {actions && <Box sx={{ position: "absolute", top: 8, right: 8 }}>{actions}</Box>}
      {startIcon}
      <Box
        sx={
          variant !== "singleInfoWithIcon"
            ? {
                display: "contents",
              }
            : {
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                rowGap: "4px",
              }
        }
      >
        <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={16}>
          {title}
        </Typography>
        {["singleInfo", "singleInfoWithIcon"].includes(variant) ? (
          <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.regular}>
            {(props as SingleInfoDetailCardProps).content}
          </Typography>
        ) : (
          (props as MultipleInfoDetailCardProps).items.map(item => (
            <Typography
              key={item.label + item.value}
              fontSize={theme.mixins.fonts.fontSize.sm}
              fontWeight={theme.mixins.fonts.fontWeight.regular}
            >
              <Typography component={"span"} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                {item.label}:{" "}
              </Typography>
              {item.value}
            </Typography>
          ))
        )}
      </Box>
    </Card>
  );
};
