import { FC } from "react";
import VisitRefundNotification from "./VisitRefundNotification";
import AppointmentRefundNotification from "./AppointmentRefundNotification";
import { formatCurrency } from "@/utils";

type PaymentNotificationProps = {
  data?: string | null;
  creationDate: string;
  isSeen: boolean;
};

export const PaymentNotification: FC<PaymentNotificationProps> = props => {
  const { data, creationDate, isSeen } = props;

  const changes = data && JSON.parse(data!)?.changes;

  const amount = changes?.find((change: { field: string; value: string }) => change.field === "amount");
  const currency = changes?.find((change: { field: string; value: string }) => change.field === "currency");
  const appointmentId = changes?.find((change: { field: string; value: string }) => change.field === "appointmentId");
  const visitId = changes?.find((change: { field: string; value: string }) => change.field === "visitId");

  const paymentStatus = changes?.find((change: { field: string; value: string }) => change.field === "paymentStatus");
  const refundAmount = formatCurrency(amount?.value, currency?.value);

  return appointmentId?.value ? (
    <AppointmentRefundNotification
      id={appointmentId?.value}
      status={paymentStatus?.value}
      creationDate={creationDate}
      isSeen={isSeen}
      refundAmount={refundAmount}
    />
  ) : (
    <VisitRefundNotification
      id={visitId?.value}
      status={paymentStatus?.value}
      refundAmount={refundAmount}
      creationDate={creationDate}
      isSeen={isSeen}
    />
  );
};
