import { MainPageLayout } from "@/components";
import { routes } from "@/domains/care-teams";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { PageWithBackHeaderLayout } from "../../components/PageLayout/PageWithBackHeaderLayout";

export const CareTeamRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={routes.root.route} Component={routes.root.component} />
        <Route path={routes.newTeams.route} Component={routes.newTeams.component} />
        <Route path={routes.register.route} Component={routes.register.component} />
        <Route path={routes.confirmRegistration.route} Component={routes.confirmRegistration.component} />
        <Route path={routes.doctorProfile.route} Component={routes.doctorProfile.component} />
      </Route>
      <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' hideBack />}>
        <Route path={routes.registerSuccess.route} Component={routes.registerSuccess.component} />
      </Route>
      <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
        <Route path={routes.myCareTeam.route} Component={routes.myCareTeam.component} />
      </Route>
    </Routes>
  );
};
