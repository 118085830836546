import { FormAutocomplete } from "@toolkit/ui"; // Adjust path if necessary
import {
  doctorListFiltersFormDefaultValues,
  DoctorsListFiltersFormSchema,
  IDoctorsListFiltersFormValues,
} from "../DoctorsListFiltersFormSchema";
import { GenericFilterForm } from "@/components/filter/GenericFilterForm";
import { appointmentTypeOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { DoctorFilterFormProps } from "../types";
import { AppointmentType } from "@health/queries/types";

export const AppointmentTypesFilterForm: React.FC<DoctorFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  const visitOptions = appointmentTypeOptions.filter(option => option.value !== AppointmentType.Diagnostic);
  return (
    <GenericFilterForm<IDoctorsListFiltersFormValues>
      schema={DoctorsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='appointmentTypes'
      dialogTitle={t("Visit Types")}
      formLabel={t("Visit Types")}
      render={name => <FormAutocomplete multiple options={visitOptions} name={name as string} label={t("Visit Types")} />}
      defaultValues={doctorListFiltersFormDefaultValues}
    />
  );
};
