import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { NotificationDefaultCard } from "../NotificationDefaultCard/NotificationDefaultCard";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { getNotificationTextStyle } from "../../utils";
import { useSurveyQuery } from "../../gql";
import { SurveyNotificationIcon } from "@/components";

type SurveyNotificationProps = {
  surveyId: string;
  isSeen: boolean;
  creationDate: string;
};

export const SurveyNotification: FC<SurveyNotificationProps> = props => {
  const { surveyId, creationDate, isSeen } = props;
  const theme = useTheme();

  const { t } = useTranslation("consumer");
  const { data, error } = useSurveyQuery({
    variables: {
      surveyId: surveyId,
    },
    skip: !surveyId,
  });

  const { color } = getNotificationTextStyle(isSeen, theme);

  if (!data?.survey?.id || error) {
    return <NotificationDefaultCard type={"survey-notification"} isSeen={isSeen} />;
  }

  return (
    <Box display='flex' flex={1} justifyContent='flex-start' alignItems='center' flexDirection='row'>
      <NotificationCard title={t("Patient Care Satisfaction")} date={creationDate} icon={<SurveyNotificationIcon />} isSeen={isSeen}>
        <Typography style={{ color }}>
          {t("Thank you for completing the survey. Your feedback will assist us in improving our services.")}
        </Typography>
      </NotificationCard>
    </Box>
  );
};
