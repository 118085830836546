import {
  Box,
  Button,
  CircularProgress,
  CustomIcon,
  IconButton,
  Typography,
  MuiMicIcon,
  MuiMicOffIcon,
  MuiVideocamIcon,
  MuiVideocamOffIcon,
} from "@toolkit/ui";
import { CallInstance, PeerTitle, PeerAvatar, MeetingPublisherView } from "./Meetora";
import { useCallStyles } from "./Call.styles";
import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";
import { inputUserSelf } from "@meetora/schema/InputUser";
import { useJoinScreenStyles } from "./JoinScreen.styles";
import { useCallHook } from "./useCallHook";

type JoinScreenProps = {
  call: CallInstance;
  onClose?: () => void;
};

export const JoinScreen = ({ call, onClose }: JoinScreenProps) => {
  const { t } = useTranslation();
  const { classes: callClasses, theme } = useCallStyles();
  const { classes } = useJoinScreenStyles();
  const { audioMuted, videoMuted } = call.state;
  const currentUser = useMemo(() => inputUserSelf(), []);
  const { onToggleAudio, onToggleVideo, handleJoin, isJoining } = useCallHook({ call });

  return (
    <Box className={callClasses.root}>
      <Box className={callClasses.container}>
        <Box className={callClasses.header}>
          <Box />

          <Typography className={callClasses.headerTitle}>
            <PeerTitle peer={call.peer} />
          </Typography>
          {onClose && (
            <IconButton size='small' onClick={onClose}>
              <CustomIcon color={theme.palette.common.white} icon='xAlert' />
            </IconButton>
          )}
        </Box>
        <Box className={classes.root}>
          <Box mt={1} className={classes.mediaView}>
            {videoMuted ? <PeerAvatar peer={currentUser} size={140} /> : <MeetingPublisherView call={call} />}
          </Box>

          <Box className={classes.mediaControls}>
            <Button variant='text' onClick={onToggleAudio} className={classes.mediaButton}>
              {!audioMuted ? <MuiMicIcon /> : <MuiMicOffIcon />}
              {!audioMuted ? t("Mute") : t("Unmute")}
            </Button>

            <Button variant='text' onClick={onToggleVideo} className={classes.mediaButton}>
              {!videoMuted ? <MuiVideocamIcon /> : <MuiVideocamOffIcon />}
              {!videoMuted ? t("Stop video") : t("Start video")}
            </Button>
          </Box>

          <Box mt={3}>
            <Button fullWidth className={classes.joinButton} disabled={!call.callId || isJoining} onClick={handleJoin}>
              {isJoining ? <CircularProgress size={20} sx={{ color: theme.palette.common.white }} /> : t("Join")}
            </Button>

            <Typography mt={2}>{t("By clicking Join now I agree to the informed consent and terms of use")}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
