import { Box, Button } from "@toolkit/ui";
import { ArrowIcon } from "@/components/Icons";
import useStyles from "./SignIn.styles";
import { useTranslation } from "@toolkit/i18n";
import { useAuth } from "@/providers/auth";
import { CustomUserAvatar } from "@/components";

export const SignIn: React.FC = () => {
  const { classes, cx } = useStyles();
  const { askToLogin, isAuthenticated } = useAuth();
  const { t } = useTranslation("consumer");

  const onClick = () => {
    console.log("debug:  isAuthenticated", isAuthenticated);
    askToLogin();
  };

  return (
    <Box className={classes.container}>
      <CustomUserAvatar width='80px' height='80px' />
      <Button
        onClick={onClick}
        className={cx(classes.button, classes.signInButton)}
        endIcon={
          <Box className={classes.buttonIcon}>
            <ArrowIcon />
          </Box>
        }
      >
        {t("Sign in")}
      </Button>
    </Box>
  );
};
