import { AvailabilityBody } from "./tabsBodies/AvailabilityBody";
import { ExperienceBody } from "./tabsBodies/ExperienceBody";
import { OverviewBody } from "./tabsBodies/OverviewBody";
import { QualificationBody } from "./tabsBodies/QualificationsBody";

export const tabs = [
  {
    label: "Overview",
    key: "overview",
    component: OverviewBody,
  },
  {
    label: "Qualifications",
    key: "qualifications",
    component: QualificationBody,
  },
  {
    label: "Experience",
    key: "experience",
    component: ExperienceBody,
  },
  {
    label: "Availability",
    key: "availability",
    component: AvailabilityBody,
  },
];
