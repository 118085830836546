import { Box, MuiDrawer, useMediaQuery, useTheme } from "@toolkit/ui";
import { useReactiveVar } from "@apollo/client";
import { sideMenuDrawerOpenStateVar } from "./sideMenuDrawerOpenStateVar";
import { useMenuMenuStyles } from "../MenuList/Menu.styles";
import { PreList } from "./PreList/PreList";
import { PostList } from "./PostList/PostList";
import { MenuList } from "../MenuList/MenuList";

const SideMenuDrawer = () => {
  const isOpen = useReactiveVar(sideMenuDrawerOpenStateVar);
  const theme = useTheme();
  const handleClose = () => sideMenuDrawerOpenStateVar(false);
  const { classes } = useMenuMenuStyles();
  const media = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiDrawer
      classes={{ paper: classes.paper, root: classes.root }}
      anchor='left'
      open={media && isOpen}
      onClose={handleClose}
      variant={"temporary"}
      disableEnforceFocus
    >
      <Box className={classes.menuContainer}>
        <PreList />
        <Box className={classes.listContainer}>
          <MenuList />
        </Box>
        <PostList />
      </Box>
    </MuiDrawer>
  );
};

export default SideMenuDrawer;
