import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";

export const DoctorsListFiltersFormSchema = z.object({
  specializations: z.array(zodSchema.systemCode).optional(),
  appointmentTypes: z.array(zodEnumSchema.appointmentType).optional(),
  gender: zodEnumSchema.userGender.nullable().optional(),
  vendors: z.array(zodSchema.vendor).optional(),
  languages: z.array(zodSchema.language).optional(),
  callPrice: z.array(z.number()).length(2).optional(),
});

export type IDoctorsListFiltersFormValues = z.infer<typeof DoctorsListFiltersFormSchema>;

export const doctorListFiltersFormDefaultValues: Partial<IDoctorsListFiltersFormValues> = {
  specializations: undefined,
  appointmentTypes: undefined,
  gender: undefined,
  vendors: undefined,
  languages: undefined,
  callPrice: [0, 500],
};
