import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";
import { isRTL } from "@toolkit/i18n";

interface LongArrowIconProps extends SvgIconProps {
  direction?: "up" | "down" | "left" | "right";
  disableAutoRotate?: boolean;
}

const getRotation = (direction: LongArrowIconProps["direction"]) => {
  const isRtl = isRTL();

  switch (direction) {
    case "up":
      return "rotate(-90deg)";
    case "down":
      return "rotate(90deg)";
    case "right":
      return isRtl ? "rotate(0deg)" : "rotate(180deg)";
    case "left":
    default:
      return isRtl ? "rotate(180deg)" : "rotate(0deg)";
  }
};

export const WalletArrowIcon: React.FC<LongArrowIconProps> = ({ direction = "left", fill, ...props }) => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width='29.102'
      height='26.035'
      viewBox='0 0 29.102 26.035'
      style={{ transform: getRotation(direction) }}
      {...props}
    >
      <path
        id='icons8-arrow'
        d='M16.887,7a1.149,1.149,0,0,1,1.132,1.149v5.932A20.368,20.368,0,0,1,26.771,17.1c3.823,2.474,7.222,7.019,7.3,14.54,0,0,0,.006,0,.009h0c0,.087.027.15.027.238a1.149,1.149,0,0,1-2.272.238A9.369,9.369,0,0,0,26.7,25.259,1.149,1.149,0,1,1,27.722,23.2a11.906,11.906,0,0,1,2.821,2.061,13.036,13.036,0,0,0-5.021-6.234,20.122,20.122,0,0,0-8.758-2.845,1.149,1.149,0,0,1-1.043-1.144V10.922l-2.864,2.864a1.149,1.149,0,1,1-1.625-1.625l4.826-4.825A1.149,1.149,0,0,1,16.887,7ZM9.148,14.723a1.149,1.149,0,0,1,.8,1.973L7.773,18.87l7.949,7.949v-4.2a1.149,1.149,0,0,1,1.072-1.146,26.89,26.89,0,0,1,6.48.244,1.149,1.149,0,1,1-.4,2.263,21.656,21.656,0,0,0-4.855-.251v5.859a1.149,1.149,0,0,1-1.961.812L5.336,19.683a1.149,1.149,0,0,1,0-1.624l2.987-2.987a1.149,1.149,0,0,1,.824-.349Z'
        transform='translate(-5 -7)'
        fill={fill}
      />
    </SvgIcon>
  );
};
