import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles<{ disableAction?: boolean }>()((theme, { disableAction }) => ({
  root: {
    padding: "5px",
    gap: 0,
    position: "relative",
  },
  memberCard: {
    backgroundColor: "inherit",
    boxShadow: "none",
    margin: 0,
    borderRadius: 0,
    borderBlockEnd: "1px solid",
    borderBlockEndColor: theme.palette.divider,
  },
  serviceContainer: {
    height: "90px",
    borderBlockEnd: "1px solid",
    borderBlockEndColor: theme.palette.divider,
    paddingInline: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: disableAction ? "not-allowed" : "pointer",
  },
  lastServiceContainer: {
    borderBlockEnd: "none",
  },
  iconContainer: {
    width: "58px",
    height: "58px",
    borderRadius: "50%",
    boxShadow: theme.mixins.shadows.md,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "30px",
      height: "30px",
      "& path": {
        fill: disableAction ? theme.palette.gray[500] : theme.palette.primary.main,
      },
    },
  },
  serviceStart: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  expandIcon: {
    "& svg": {
      width: "6px",
      height: "12px",
      "& path": {
        fill: disableAction ? theme.palette.gray[500] : theme.palette.primary.main,
      },
    },
  },
  serviceTitle: {
    color: disableAction ? theme.palette.gray[500] : theme.palette.primary.main,
  },
  disEnrollContainer: {
    position: "absolute",
    top: 10,
    right: 15,
  },
}));
