import { Box, FormControlLabel, Radio, RadioGroup, TextField, useTheme } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { useReasonsForm } from "./useReasonsForm";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { AffectedType } from "@health/queries/types";

type ReasonsFormProps = {
  onSubmit: (reason: string) => void;
  type: "cancellation" | "rejection";
  skip?: boolean;
  affectedType: AffectedType;
};

export type ReasonsFormRef = {
  submit: () => void;
};

const ReasonsFormForwardRef: ForwardRefRenderFunction<ReasonsFormRef, ReasonsFormProps> = (props, ref) => {
  const { type, affectedType, onSubmit } = props;

  const isCancellation = type === "cancellation";
  const { state, reasons, handleChangeOtherReason, handleChangeReason, handleSubmit, error } = useReasonsForm({
    isCancellation,
    onSubmit,
    affectedType,
  });
  const theme = useTheme();
  const { t } = useTranslation("consumer");

  useImperativeHandle(
    ref,
    () => ({
      submit: handleSubmit,
    }),
    [state]
  );

  return (
    <>
      <RadioGroup onChange={handleChangeReason}>
        {reasons?.map(item => (
          <FormControlLabel
            key={item?.id}
            value={item?.text}
            control={<Radio />}
            label={pickLocalizedValue(item?.text, item?.text_ar) || ""}
            sx={{ fontSize: theme.mixins.fonts.fontSize.sm }}
          />
        ))}
      </RadioGroup>

      {state.isOther && (
        <TextField
          label={t("Other Reason")}
          placeholder={t("Other Reason")}
          multiline
          minRows={3}
          fullWidth
          value={state.other}
          onChange={handleChangeOtherReason}
        />
      )}
      <Box marginTop='20px' color={theme.palette.error.main}>
        {error}
      </Box>
    </>
  );
};

export const ReasonsForm = forwardRef(ReasonsFormForwardRef);
