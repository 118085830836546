import { Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { useStyle } from "./CareTeamsListTabsStyle";
import { useTranslation } from "@toolkit/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes";

export const CareTeamsListTabs: FC = () => {
  const { pathname } = useLocation();

  const { classes, cx } = useStyle();
  const { t } = useTranslation("consumer");
  const navigate = useNavigate();
  const handleRouteTo = (path: keyof typeof routes) => () => navigate(routes[path].absRoute);

  return (
    <Box className={classes.root}>
      <Button
        onClick={handleRouteTo("root")}
        variant='text'
        className={cx(classes.tab, pathname === routes.root.absRoute && classes.activeTab)}
      >
        {t("My Care Team")}
      </Button>
      <Button
        onClick={handleRouteTo("newTeams")}
        variant='text'
        className={cx(classes.tab, pathname === routes.newTeams.absRoute && classes.activeTab)}
      >
        {t("New Care Team")}
      </Button>
    </Box>
  );
};
