import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  container: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: 10,

      minWidth: 240,
      ".card__container": {
        marginBlockStart: 20,
      },
    },
  },
  text: {
    textAlign: "center",
    marginTop: 10,
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  transactionCardWrapper: {
    "& svg": {
      width: 38,
      height: 34,
    },
    alignSelf: "center",
  },
  iconButton: {
    marginTop: 20,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.md,
    borderRadius: 40,
    padding: 15,
    borderWidth: 0,
    height: 76,
    width: 76,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  date: {
    textAlign: "center",
    fontSize: theme.mixins.fonts.fontSize.lg,
    marginTop: 10,
  },
  balance: {
    marginTop: 35,
    textAlign: "center",
    fontSize: 50,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  content: {
    color: theme.palette.primary.main,
    borderRadius: 15,
    background: theme.palette.common.white,
    height: 88,
    padding: 16,
    marginTop: 18,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.md,
    borderRadius: 40,
    padding: 15,
    borderWidth: 0,
  },
  iconWrapper: {
    "& svg": {
      width: 29,
      height: 26,
    },
    paddingInlineStart: 2,
    display: "flex",
  },
  source: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
    textAlign: "start",
  },
  footer: {
    position: "sticky",
    bottom: "0px",
    left: "0px",
    height: "74px",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  button: {
    borderRadius: "24px",
  },
}));
