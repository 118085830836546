import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  vendorAvatar: {
    height: "100%",
    borderRadius: "0",
    "& img": {
      objectFit: "cover",
    },
  },
  doctorName: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.secondary.main,
  },
  specialty: {
    color: theme.palette.text.primary,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  vendorName: {
    color: theme.palette.text.primary,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  experience: {
    color: theme.palette.stale.main,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  languages: {
    color: theme.palette.stale.main,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  downAvatarContainer: {
    padding: 0,
    width: "100%",
  },
}));
