import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const LocationIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-location' width='25.169' height='29.629' viewBox='0 0 25.169 29.629' {...props}>
      <path
        id='Ellipse_2848'
        data-name='Ellipse 2848'
        d='M2.942-1.5A4.442,4.442,0,1,1-1.5,2.942,4.447,4.447,0,0,1,2.942-1.5Zm0,6.662A2.221,2.221,0,1,0,.721,2.942,2.223,2.223,0,0,0,2.942,5.162Z'
        transform='translate(9.643 9.643)'
        fill={fill}
      />
      <path
        id='Path_12799'
        data-name='Path 12799'
        d='M30.215,25.171a1.11,1.11,0,0,1-.845-1.83,10.317,10.317,0,0,0,1.814-3.075,10.447,10.447,0,0,0,.66-3.682A10.326,10.326,0,0,0,21.48,6.221,10.992,10.992,0,0,0,15.97,7.772a1.11,1.11,0,1,1-1.119-1.918A13.213,13.213,0,0,1,21.48,4,12.547,12.547,0,0,1,34.065,16.584a12.66,12.66,0,0,1-.8,4.461,12.531,12.531,0,0,1-2.2,3.735A1.108,1.108,0,0,1,30.215,25.171Z'
        transform='translate(-8.896 -4)'
        fill={fill}
      />
      <path
        id='Path_12800'
        data-name='Path 12800'
        d='M19.575,35.733a3.4,3.4,0,0,1-2.3-.9l-.017-.016c-1.717-1.642-7.021-7.66-7.246-7.915l-.025-.029A13.3,13.3,0,0,1,7.8,23.094a12.492,12.492,0,0,1,.74-10.415,1.11,1.11,0,0,1,1.95,1.063,10.223,10.223,0,0,0-1.271,4.946A10.583,10.583,0,0,0,11.7,25.445c.3.336,5.471,6.2,7.092,7.754a1.083,1.083,0,0,0,1.492.026l.028-.027c1.1-1.026,2.372-2.454,3.921-4.215A1.11,1.11,0,1,1,25.9,30.451c-1.585,1.8-2.891,3.266-4.061,4.36A3.2,3.2,0,0,1,19.575,35.733Z'
        transform='translate(-7 -6.104)'
        fill={fill}
      />
    </SvgIcon>
  );
};
