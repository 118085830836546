import { useEffect } from "react";
import { useDoctorProfileDataQuery } from "../../gql";
import queryString from "query-string";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { TFunction } from "@toolkit/core";

type QueryParams = {
  doctorId?: string;
};

export const useGetDoctorProfileData = (t: TFunction) => {
  const queryParams: QueryParams = queryString.parse(location.search);
  const doctorId = queryParams.doctorId;
  const { failed } = useAddToast();
  const { data, loading } = useDoctorProfileDataQuery({
    variables: {
      doctorId,
    },
    skip: !doctorId,
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors) || t("Doctor not found"));
    },
  });

  useEffect(() => {
    if (!doctorId) {
      failed(t("Doctor not found"));
    }
  }, [doctorId]);
  const doctorData = data?.doctor;
  return { doctorData, loading };
};
