import { VisitCallWaitingContainer } from "./containers/VisitCallWaitingContainer/VisitCallWaitingContainer";
import VisitCallPage from "./VisitCallPage";
import VisitCallGuestPage from "./VisitCallGuestPage";

export const VisitCallBaseRoute = "/visit-call";
export const routes = {
  root: {
    route: "/",
    component: VisitCallPage,
    get absRoute() {
      return this.route;
    },
  },
  waiting: {
    route: "/waiting",
    component: VisitCallWaitingContainer,
    get absRoute() {
      return `${VisitCallBaseRoute}${this.route}`;
    },
    getRouteWithParams({ visitId }: { visitId: string }) {
      return `${this.absRoute}?visitId=${visitId}`;
    },
  },
};

export const VisitCallGuestBaseRoute = "/visit-call-guest";
export const guestRoutes = {
  root: {
    route: "/:token",
    component: VisitCallGuestPage,
    get absRoute() {
      return this.route;
    },
  },
};
