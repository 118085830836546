import { DoctorProfileForTabs } from "@/domains/doctor/type";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useDoctorProfileTabsStyle } from "../DoctorProfileTabsStyles";

type OverviewBodyProps = {
  doctorInfo: DoctorProfileForTabs;
};
export const OverviewBody: FC<OverviewBodyProps> = props => {
  const { doctorInfo } = props;
  const { t } = useTranslation();
  const { classes } = useDoctorProfileTabsStyle();
  return (
    <>
      <Typography className={classes.multilineText}>{doctorInfo?.bio}</Typography>
      <Typography className={classes?.titleText}>{t("Specialization")}</Typography>
      <Typography>
        <Typography className={classes.multilineText} lineHeight={3}>
          {doctorInfo?.specializations?.length
            ? doctorInfo?.specializations?.map(specialty => pickLocalizedValue(specialty?.display, specialty?.arabicDisplay)).join("\n")
            : t("No Specialization provided")}
        </Typography>
      </Typography>
      {!doctorInfo?.bio && doctorInfo?.specializations?.length === 0 && <Box>{t("No Overview provided")}</Box>}
    </>
  );
};
