import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export interface EmptyUserAvatarWithBorderIconProps extends SvgIconProps {
  secondary?: string;
  src?: string | null;
  elementId?: string;
  fillInnerBlob?: string;
  fillOuterBlob?: string;
  id?: string;
}

export const UserAvatarWithDefaultFillIcon: FC<EmptyUserAvatarWithBorderIconProps> = props => {
  const { src, width, height, fillInnerBlob, fillOuterBlob, id } = props;
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  const secondaryFill = props.secondary || theme.palette.secondary.main;
  const imgId = "image-pattern-" + id || src || "";
  const urlIdentifier = src ? `url(#${imgId})` : `url(#user-avatar-linear-gradient-${id})`;

  return (
    <SvgIcon key={src} xmlns='http://www.w3.org/2000/svg' direction={theme.direction} viewBox='0 0 123.598 123.146' {...props}>
      <defs>
        <linearGradient
          id={`user-avatar-linear-gradient-${id}`}
          x1='0.775'
          y1='0.531'
          x2='-0.038'
          y2='1.079'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stop-color={fill} />
          <stop offset='1' stop-color={secondaryFill} />
        </linearGradient>
        <pattern id={imgId} patternUnits='userSpaceOnUse' width={"85%"} height={"85%"}>
          <image width={"85%"} height={"85%"} href={src!} preserveAspectRatio='xMidYMid slice' />
        </pattern>

        <filter
          id={"happy-muslim-business-women-in-hijab-at-work-smili-2023-05-13-03-01-51-utc" + id}
          x='0'
          y='0'
          width={width}
          height={height}
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood flood-opacity='0.161' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g id={"Group_11240" + id} data-name='Group 11240' transform='translate(9.035 2.561)'>
        <g
          transform='matrix(1, 0, 0, 1, -9.03, -2.56)'
          filter='url(#happy-muslim-business-women-in-hijab-at-work-smili-2023-05-13-03-01-51-utc)'
        >
          <path
            id={"happy-muslim-business-women-in-hijab-at-work-smili-2023-05-13-03-01-51-utc-2" + id}
            data-name='happy-muslim-business-women-in-hijab-at-work-smili-2023-05-13-03-01-51-utc'
            d='M52.6,106.521c12.2-2.755,23.2-7.106,33.36-14.862,10.051-7.732,19.274-18.765,19.63-30.064.249-11.275-8.3-22.775-17.612-31.81A93.292,93.292,0,0,0,56.8,9.162C44.786,4.192,30.452.959,20.64,6.171,10.894,11.426,5.672,25.127,2.757,37.821-.224,50.474-.9,62.122,1.276,74.219S8.511,98.8,17.766,104.478c9.214,5.746,22.619,4.691,34.837,2.043'
            transform='translate(9 2.49)'
            fill={fillOuterBlob || urlIdentifier}
          />
        </g>
        {!src && (
          <path
            id={"happy-muslim-business-women-in-hijab-at-work-smili-2023-05-13-03-01-51-utc-3" + id}
            data-name='happy-muslim-business-women-in-hijab-at-work-smili-2023-05-13-03-01-51-utc'
            d='M47.268,96.074c10.959-2.476,20.85-6.385,29.977-13.355C86.277,75.77,94.564,65.857,94.884,55.7c.224-10.131-7.461-20.465-15.826-28.584A83.83,83.83,0,0,0,51.043,8.589c-10.8-4.466-23.68-7.371-32.5-2.687C9.789,10.623,5.1,22.935,2.477,34.341-.2,45.711-.806,56.178,1.147,67.048s6.5,22.085,14.818,27.19c8.279,5.163,20.325,4.216,31.3,1.836'
            transform='translate(4.556 6.176)'
            fill={fillInnerBlob || theme?.palette.primary[100]}
          />
        )}
      </g>
    </SvgIcon>
  );
};
