import { memo } from "react";
import { useMeetingDuration } from "./useMeetingDuration";
import { CallInstance } from "./Meetora";

type MeetingDuration = {
  call: CallInstance;
};

export const MeetingDuration = memo(({ call }: MeetingDuration) => {
  const startedAt = call.callInfo && "createdAt" in call.callInfo ? call.callInfo.createdAt : undefined;
  const { duration } = useMeetingDuration({ startedAt });
  if (!startedAt) {
    return null;
  }

  return <>{duration}</>;
});
