import { useMemo } from "react";
import useSelectPeerFromInvitationLink from "@meetora/react/sehacity/Chat/useSelectPeerFromInvitationLink";
import { useSelectCall } from "./CallContext";

export const useMeetingInfo = ({ invitationCode }: { invitationCode?: string }) => {
  const code = useMemo(() => invitationCode?.replace(/(^")|("$)/g, ""), []);
  const { data, loading, error, refetch: handleRetry } = useSelectPeerFromInvitationLink(code);
  const callInfo = useMemo(() => {
    if (data?._name === "conversation.invitationInfoAnonymousChat") {
      return { chatId: data.anonChat.id, callUrl: window.location.href };
    }
  }, [data]);
  const call = useSelectCall(callInfo);

  return { call, loading, error, handleRetry };
};
