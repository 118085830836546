const DoctorConsoleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width={32} height={32} {...props}>
      <path
        d='M15.198 18.939v2.4a.8.8 0 1 0 1.6 0v-2.4h2.4a.8.8 0 1 0 0-1.6h-2.4v-2.4a.8.8 0 1 0-1.6 0v2.4h-2.4a.8.8 0 1 0 0 1.6Zm-8.285 7.748a1.538 1.538 0 0 1-1.122-.481 1.538 1.538 0 0 1-.478-1.122V10.843a1.538 1.538 0 0 1 .481-1.122 1.538 1.538 0 0 1 1.119-.481h5.076V6.913a1.553 1.553 0 0 1 .481-1.109 1.518 1.518 0 0 1 1.125-.491h4.809a1.518 1.518 0 0 1 1.122.494 1.553 1.553 0 0 1 .481 1.106V9.24h5.076a1.644 1.644 0 0 1 1.6 1.6v14.24a1.644 1.644 0 0 1-1.6 1.6ZM13.595 9.24h4.809V6.913h-4.809Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default DoctorConsoleIcon;
