import { Box, FormHelperText, SliderProps } from "@toolkit/ui";
import { get } from "lodash";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomSlider } from "./CustomSlider";

interface FormSliderProps extends SliderProps {
  name: string;
}

export const FormSlider: FC<FormSliderProps> = ({ name, ...rest }) => {
  const {
    control,
    formState: { errors, disabled: isFormDisabled },
  } = useFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CustomSlider {...rest} value={field.value || 0} onChange={(_, value) => field.onChange(value)} disabled={isFormDisabled} />
        )}
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};
