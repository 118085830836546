import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const CareTeamsListFiltersFormSchema = z.object({
  name: z.string().optional(),
  doctors: z.array(zodSchema.doctor.nullish()).optional(),
  payer: zodSchema.payer.nullish(),
  vendor: zodSchema.vendor.nullish(),
});

export type ICareTeamsListFiltersFormValues = z.infer<typeof CareTeamsListFiltersFormSchema>;

export const careTeamListFiltersFormDefaultValues: Partial<ICareTeamsListFiltersFormValues> = {
  name: undefined,
  doctors: undefined,
  payer: undefined,
  vendor: undefined,
};
