import { FormAutocomplete } from "@toolkit/ui"; // Adjust path if necessary
import {
  doctorListFiltersFormDefaultValues,
  DoctorsListFiltersFormSchema,
  IDoctorsListFiltersFormValues,
} from "../DoctorsListFiltersFormSchema";
import { GenericFilterForm } from "@/components/filter/GenericFilterForm";
import { userGenderOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { DoctorFilterFormProps } from "../types";

export const GenderFilterForm: React.FC<DoctorFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IDoctorsListFiltersFormValues>
      schema={DoctorsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='gender'
      dialogTitle={t("Gender")}
      formLabel={t("Gender")}
      render={name => <FormAutocomplete options={userGenderOptions} name={name as string} label={t("Gender")} />}
      defaultValues={doctorListFiltersFormDefaultValues}
    />
  );
};
