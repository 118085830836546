import { formatGraphQLError, formatMessageErrors } from "@/utils/formatErrors";
import { ApolloError } from "@apollo/client";
import { useRef } from "react";
import { CallDoctorNowCreateMutation, useCallDoctorNowCreateMutation } from "../../gql";
import { useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

type VisitType = NonNullable<CallDoctorNowCreateMutation["callDoctorNowCreate"]>["visit"];

export const useCreateImmediateCallVisit = ({ doctorId }: { doctorId: string }) => {
  const { failed } = useAddToast();
  const { t } = useTranslation("consumer");
  const visitRef = useRef<VisitType | null>(null);

  const [callDoctorNowCreate, { loading }] = useCallDoctorNowCreateMutation();
  const handleCreateVisitNow = async () => {
    try {
      const request = await callDoctorNowCreate({
        variables: {
          input: {
            doctorId: doctorId,
            patientNote: "",
          },
        },
      });

      const visitErrors = request?.data?.callDoctorNowCreate?.visitErrors;
      const visit = request?.data?.callDoctorNowCreate?.visit;
      if (visitErrors?.length !== 0) {
        failed(formatMessageErrors(visitErrors));
      } else if (!visit?.id) {
        failed(t("We are unable to initiate the visit at this time. Please try again later."));
      }
      visitRef.current = visit || null;

      return visit;
    } catch (error: unknown) {
      if (error instanceof ApolloError) {
        failed(formatGraphQLError(error.graphQLErrors));
      } else {
        failed(t("We are unable to initiate the visit at this time. Please try again later."));
      }

      return null;
    }
  };
  return {
    loading,
    visitRef,
    handleCreateVisitNow,
  };
};
