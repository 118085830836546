import {
  doctorListFiltersFormDefaultValues,
  DoctorsListFiltersFormSchema,
  IDoctorsListFiltersFormValues,
} from "../DoctorsListFiltersFormSchema";
import { GenericFilterForm } from "@/components/filter/GenericFilterForm";
import { useTranslation } from "@toolkit/i18n";
import { DoctorFilterFormProps } from "../types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode } from "@health/queries/types";

export const SpecializationsFilterForm: React.FC<DoctorFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<IDoctorsListFiltersFormValues>
      schema={DoctorsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='specializations'
      dialogTitle={t("Specialty")}
      formLabel={t("Specialty")}
      render={name => <SystemCodeAutocomplete name={name} multiple isCodeHidden filter={{ codeSystemCode: CodeSystemCode.Speciality }} />}
      defaultValues={doctorListFiltersFormDefaultValues}
    />
  );
};
