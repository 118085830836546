import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const WhatsappIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-whatsapp' width='29.61' height='29.61' viewBox='0 0 29.61 29.61' {...props}>
      <path
        d='M18.805,4A14.809,14.809,0,0,0,4.093,20.46A1.11,1.11,0,1,0,6.3,20.213a12.587,12.587,0,0,1,23.379-7.751A1.11,1.11,0,1,0,31.6,11.341,14.809,14.809,0,0,0,18.805,4Zm-5,8.144a1.3,1.3,0,0,0-.943.442,3.954,3.954,0,0,0-1.238,2.947,6.879,6.879,0,0,0,1.443,3.656c.176.235,2.445,3.92,6.039,5.336,2.986,1.177,3.594.943,4.242.883a3.565,3.565,0,0,0,2.387-1.68,2.973,2.973,0,0,0,.208-1.679c-.088-.147-.324-.237-.678-.413s-2.09-1.032-2.415-1.149-.561-.177-.8.176-.913,1.15-1.119,1.385-.412.267-.766.09a9.7,9.7,0,0,1-2.844-1.755,10.616,10.616,0,0,1-1.965-2.448c-.206-.352-.022-.546.155-.721.159-.158.354-.412.531-.619a2.444,2.444,0,0,0,.351-.588.651.651,0,0,0-.029-.619c-.088-.177-.776-1.926-1.09-2.626-.264-.588-.543-.6-.8-.61C14.273,12.143,14.037,12.142,13.8,12.142Zm18.683,4.6a1.11,1.11,0,0,0-1.123,1.2c.02.283.029.57.029.862a12.58,12.58,0,0,1-18.7,11,1.111,1.111,0,0,0-.839-.1L6.386,31.225,7.912,25.76a1.111,1.111,0,0,0-2.14-.6l-1.7,6.1a1.889,1.889,0,0,0,2.281,2.281l5.75-1.6a14.666,14.666,0,0,0,6.7,1.671A14.822,14.822,0,0,0,33.61,18.8c0-.34-.011-.678-.035-1.015a1.11,1.11,0,0,0-1.092-1.05Z'
        transform='translate(-4 -3.998)'
        fill={fill}
      />
    </SvgIcon>
  );
};
