import React from "react";
import { productsQueryNode } from "../../types";
import { Box, Card, CustomIcon, IconButton, TruncateTypography, Typography } from "@toolkit/ui";
import { useStyle } from "./ProductCardStyle";
import { pickLocalizedValue } from "@toolkit/i18n";
import { HeartIcon } from "@/components";
import { useSiteSettingsGetQuery } from "@/gql";
import { truncate } from "lodash";
import defaultProductImage from "@/assets/emptyProduct.png";

type ProductCardProps = {
  product: productsQueryNode;
};
export const ProductCard: React.FC<ProductCardProps> = props => {
  const { product } = props;
  const { classes, cx } = useStyle();
  const { data } = useSiteSettingsGetQuery({});
  const siteCurrency = data?.siteSettings?.defaultCurrency;
  const currentPrice = product.activeDiscount?.amount ? product.price - product.activeDiscount?.amount : product.price;
  return (
    <Card className={cx(classes.root)}>
      <Box className={cx(classes.cardTitleContainer)}>
        <TruncateTypography text={pickLocalizedValue(product?.vendor?.name, product?.vendor?.nameAr) || ""} />
      </Box>
      <Box className={cx(classes.cardImageContainer)}>
        <img src={product?.mainImageUrl || defaultProductImage} alt='product' />
      </Box>
      <Box className={cx(classes.cardDetailsContainer)}>
        <Typography className={classes.productCategory}>
          {pickLocalizedValue(product?.category?.name, product?.category?.nameAr) || ""}
        </Typography>
        <TruncateTypography text={pickLocalizedValue(product?.name, product?.nameAr) || ""} className={classes.productName} />
        <Typography className={classes.productShortDescription}>
          {truncate(pickLocalizedValue(product?.shortDescription, product?.shortDescriptionAr) || "", { length: 65 })}
        </Typography>
      </Box>
      <Box className={cx(classes.cardActionContainer)}>
        <Box>
          <Box className={classes.priceContainer}>
            <Typography className={classes.currency}>{siteCurrency}</Typography>
            <Typography className={classes.price}>{currentPrice}</Typography>
          </Box>
          {(product.activeDiscount?.amount || product.activeDiscount?.percentage) && (
            <Box>
              <Typography className={classes.discountCurrency}>{siteCurrency}</Typography>
              <Typography className={classes.discountPrice}> {product?.price}</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.cardActionButtonsContainer}>
          <IconButton className={cx(classes.actionsButton)}>
            <HeartIcon />
          </IconButton>
          <IconButton className={cx(classes.actionsButton, classes.filledIcon)}>
            <CustomIcon icon='cart' />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};
