import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    marginTop: "20px",
  },
  button: {
    borderRadius: "24px",
    height: "48px",
    paddingInline: "30px",
  },
}));
