import queryString from "query-string";
import { useAppointmentQuery } from "../../gql";
export const useAppointmentPaymentSummary = () => {
  const { appointmentId } = queryString.parse(location.search) as { appointmentId: string };
  const { data, loading: isLoading } = useAppointmentQuery({
    variables: {
      id: appointmentId,
    },
    skip: !appointmentId,
  });
  const appointment = data?.appointment;

  return { appointmentId, isLoading, appointment };
};
