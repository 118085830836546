import { guestRoutes } from "@/domains/VisitCall/routes";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const VistCallGuestRoutes: FC = () => {
  return (
    <Routes>
      <Route path={guestRoutes.root.route} element={<guestRoutes.root.component />} />
    </Routes>
  );
};
