import { FC } from "react";
import { CallInstance, MeetingGridView, MeetingSpeakerView } from "./Meetora";
import { MeetingSelfView } from "./MeetingSelfView";

interface MeetingViewProps {
  call: CallInstance;
  view: "speaker" | "grid";
}

export const MeetingView: FC<MeetingViewProps> = ({ call, view }) => {
  return (
    <>
      <MeetingSelfView call={call} />
      {view === "grid" && <MeetingGridView call={call} />}
      {view === "speaker" && <MeetingSpeakerView call={call} />}
    </>
  );
};
