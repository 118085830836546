import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const BlobIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon opacity={0.1} xmlns='http://www.w3.org/2000/svg' width='340.01' height='340.659' viewBox='0 0 340.01 340.659' {...props}>
      <path
        xmlns='http://www.w3.org/2000/svg'
        id='blob_2_'
        data-name='blob (2)'
        d='M300.51,111.28c0,85.408-105.867,187.379-190.951,187.379S-39.5,196.689-39.5,111.28C-39.5,26.2,24.475-42,109.559-42S300.51,26.2,300.51,111.28Z'
        transform='translate(39.5 42)'
        fill={fill}
      />
    </SvgIcon>
  );
};
