import { useMutation } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CONFIRM_VERIFICATION,
  DELETE_CUSTOMER_BY_EMAIL,
  DELETE_CUSTOMER_BY_MOBILE,
  IdentityTypes,
  REQUEST_OTP_FOR_DELETE_ACCOUNT,
  RESEND_OTP_FOR_FORGET_PASSWORD_IN_SECOND,
} from "./constants";
import { DeletePageLayout } from "./DeletePageLayout";
import OtpForm from "./OtpForm";

export interface VerifyDeleteAccountState {
  identity: string;
  reason: string;
  identityType: string;
  sessionToken: string;
}

export default function VerifyDeleteAccount() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { failed, succeeded } = useAddToast();
  const [error, setError] = useState("");
  const { state } = useLocation();
  const [sessionToken, setSessionToken] = useState(state.sessionToken);
  const [fetchResendOTP] = useMutation(REQUEST_OTP_FOR_DELETE_ACCOUNT);
  const [fetchConfirmVerification] = useMutation(CONFIRM_VERIFICATION);
  const [fetchDeleteCustomer] = useMutation(
    state.identityType === IdentityTypes.email ? DELETE_CUSTOMER_BY_EMAIL : DELETE_CUSTOMER_BY_MOBILE
  );
  const userMethod = state.identityType === "mobile" ? "phoneNumber" : "email";

  const handelResend = async function () {
    try {
      const resp = await fetchResendOTP({
        variables: {
          [userMethod]: state.identity.trim(),
        },
      });
      const data = resp.data.requestOtpForDeleteAccount;
      setSessionToken(data.sessionToken);
    } catch (e) {
      failed(t("Something went wrong. Please try again."));
    }
  };

  const handelVerify = async function (otp: string) {
    try {
      const response = await fetchConfirmVerification({
        variables: {
          [userMethod]: state.identity.trim(),
          sessionToken: sessionToken,
          verificationCode: otp,
        },
      });
      const status = response.data.confirmVerification.success;
      if (!status) {
        setError(response.data.confirmVerification.accountErrors.message || t("verification_code is not valid."));
        return;
      }
      const resp = await fetchDeleteCustomer({
        variables: {
          sessionToken,
          reason: state.reason,
          identity: state.identity,
        },
      });
      if (resp?.data?.customerCreate?.patientErrors?.length) {
        failed(t("Something went wrong. Please try again."));
        return;
      } else {
        succeeded(t("Your account has been deleted successfully."));
        navigate("/");
      }
    } catch (e) {
      failed(t("Something went wrong. Please try again."));
    }
  };

  return (
    <DeletePageLayout>
      <OtpForm
        seconds={RESEND_OTP_FOR_FORGET_PASSWORD_IN_SECOND}
        onSubmit={handelVerify}
        onResend={handelResend}
        message={t("Please enter the 4-digit verification code that was sent to")}
        username={state.identity.trim() ?? t("your account")}
        outterError={error}
      />
    </DeletePageLayout>
  );
}
