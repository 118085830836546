import { Button, CirclePlusIcon, CustomTable, FormPhoneInput, FormTextField, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCallback } from "react";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";

export type GuestInviteContactInput = { phoneNumber: string; email: string };

export const GuestInviteForm = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const { append, remove } = useFieldArray({ name: "contacts" });

  const methods = useForm<GuestInviteContactInput>({
    context: null,
    resolver: zodResolver(
      z
        .object({
          phoneNumber: z.string().refine(isValidPhoneNumber),
          email: z.string().email(),
        })
        .partial()
    ),
    defaultValues: {
      phoneNumber: "",
      email: "",
    },
  });
  const { reset, handleSubmit, formState } = methods;
  const handleAddContact = useCallback(() => {
    const { phoneNumber, email } = methods.getValues();
    if (phoneNumber && !formState.errors.phoneNumber && isValidPhoneNumber(phoneNumber)) {
      append({ phoneNumber });
      reset();
    }
    if (email && !formState.errors["email"]) {
      append({ email });
      reset();
    }
  }, [append, formState.errors, methods, reset]);
  const handleRemoveContact = useCallback(
    (_: GuestInviteContactInput, index: number) => {
      remove(index);
    },
    [remove]
  );

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2} mb={2}>
        <Grid item sm={5} xs={12}>
          <FormPhoneInput fullWidth label={t("Phone number")} name='phoneNumber' />
        </Grid>
        <Grid item sm={5} xs={12}>
          <FormTextField fullWidth label={t("Email")} name='email' />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Button fullWidth onClick={handleSubmit(handleAddContact, handleAddContact)}>
            <CirclePlusIcon fontSize='small' sx={{ marginInlineEnd: 1 }} /> {t("Add")}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          data={getValues("contacts")}
          minHeight={300}
          isDeleteVisible
          hasFooter={false}
          onDeleteRow={handleRemoveContact}
          columns={[
            {
              key: "contact",
              header: t("Guest List"),
              accessor: (s: GuestInviteContactInput) => s.phoneNumber || s.email,
            },
          ]}
        />
      </Grid>
    </FormProvider>
  );
};
