import { MainPageWithAppBarLayout } from "@/components";
import { VisitStartingNowCard } from "@/domains/VirtualVisit/components/VisitStartingNowCard/VisitStartingNowCard";
import AccountSettingsPage from "@/pages/AccountSettings/page";
import { AppointmentsRoutes } from "@/pages/Appointments/AppointmentRoutes";
import { HomeRoutes } from "@/pages/Home/HomeRoutes";
import { LoginPage } from "@/pages/login";
import { NotificationsRoutes } from "@/pages/Notifications/NotificationsRoutes";
import PageNotFound from "@/pages/PageNotFound";
import { useAuth } from "@/providers/auth";
import PageUnderConstruction from "@/pages/PageUnderConstruction";
import { paymentCallBackPagesRoutes } from "@/pages/PaymentCallback/route";
import Playground from "@/pages/Playground";
import { VisitCallRoutes } from "@/pages/VisitCall/VistCallRoutes";
import { VistCallGuestRoutes } from "@/pages/VisitCall/VistCallGuestRoutes";
import { Box, FallbackComponent, Typography } from "@toolkit/ui";
import { FC, Suspense, useEffect } from "react";
import { useAuth as useOidcAuth } from "react-oidc-context";
import { Route, Routes, useLocation } from "react-router-dom";
import { routesPaths } from "./routesPaths";
import { CareTeamRoutes } from "@/pages/CareTeam/CareTeamRoutes";
import { DoctorRoutes } from "@/pages/DoctorProfile/DoctorRoutes";
import { MyWalletRoute } from "@/pages/MyWallet/MyWalletRoute";
import { VirtualVisitRoutes } from "@/pages/VirtualVisit/VirtualVisitRoutes";
import DeleteAccount from "@/pages/DeleteAccount/DeleteAccountPage";
import VerifyDeleteAccount from "@/pages/DeleteAccount/VerifyDeleteAccountPage";
import DeleteAccountCompleted from "@/pages/DeleteAccount/DeleteAccountCompleted";
import { getEnvVariable } from "@/env";

export const RootRoutes: FC = () => {
  const isAppConfiguredForDeleteAccountOnly =
    getEnvVariable("CONSUMER_ALLOW_ONLY_FOR_DELETE_ACCOUNT_ROUTES", "false")?.toLocaleLowerCase() === "true";
  if (isAppConfiguredForDeleteAccountOnly) {
    return (
      <Routes>
        <Route path={routesPaths.deleteAccount} element={<DeleteAccount />} />
        <Route path={routesPaths.verifyDeleteAccount} element={<VerifyDeleteAccount />} />
        <Route path={routesPaths.deleteAccountCompleted} element={<DeleteAccountCompleted />} />
        <Route
          path='/*'
          element={
            <Suspense fallback={<FallbackComponent />}>
              <Box sx={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography sx={{ fontSize: 60 }}>Coming soon</Typography>
              </Box>
            </Suspense>
          }
        />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route element={<MainPageWithAppBarLayout />}>
        <Route path='404' element={<PageNotFound />} />
        <Route path='soon' element={<PageUnderConstruction />} />
      </Route>
      <Route path='login' element={<LoginPage />} />
      <Route path={routesPaths.deleteAccount} element={<DeleteAccount />} />
      <Route path={routesPaths.verifyDeleteAccount} element={<VerifyDeleteAccount />} />
      <Route path={routesPaths.deleteAccountCompleted} element={<DeleteAccountCompleted />} />
      <Route path={paymentCallBackPagesRoutes.success.absRoute} Component={paymentCallBackPagesRoutes.success.component} />
      <Route path={paymentCallBackPagesRoutes.error.absRoute} Component={paymentCallBackPagesRoutes.error.component} />
      <Route path={paymentCallBackPagesRoutes.refunded.absRoute} Component={paymentCallBackPagesRoutes.refunded.component} />
      <Route path={paymentCallBackPagesRoutes.root.absRoute} Component={paymentCallBackPagesRoutes.root.component} />

      <Route path={routesPaths.playGround + "/*"} element={<Playground />} />
      <Route path={routesPaths.visitCallGuest + "/*"} element={<VistCallGuestRoutes />} />
      <Route element={<VisitStartingNowCard />}>
        <Route path={routesPaths.doctor + "/*"} element={<DoctorRoutes />} />
        <Route path={routesPaths.appointments + "/*"} element={<AppointmentsRoutes />} />
        <Route path={routesPaths.home + "/*"} element={<HomeRoutes />} />
        <Route path={routesPaths.virtualVisit + "/*"} element={<VirtualVisitRoutes />} />

        <Route path={routesPaths.landing} element={<HomeRoutes />} />
        <Route
          path='/*'
          element={
            <Suspense fallback={<FallbackComponent />}>
              <LoggedInRoutes />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export const LoggedInRoutes: FC = () => {
  const locaiton = useLocation();
  const auth = useOidcAuth();
  const { doLogout } = useAuth();

  console.log("loading....auth", auth?.isAuthenticated);
  useEffect(() => {
    if (!auth?.isAuthenticated && !auth.isLoading) {
      auth?.signinRedirect({
        redirect_uri: window.location.href,
      });
    }
    if (auth && auth.isAuthenticated && !auth.user?.expired) {
      const intervalId = setInterval(() => {
        const expiresIn = auth?.user?.expires_in ?? 0;
        if (expiresIn === 0 || !!auth?.user?.expired) {
          doLogout();
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [auth?.isAuthenticated, auth.isLoading, locaiton.pathname]);

  if (auth?.isLoading) {
    return <FallbackComponent />;
  }

  if (auth?.error) {
    console.log("auth.error", auth.error);
    return <FallbackComponent />;
  }

  return (
    <Routes>
      <Route path={routesPaths.accountSettings} element={<AccountSettingsPage />} />
      <Route path={routesPaths.notifications + "/*"} element={<NotificationsRoutes />} />
      <Route path={routesPaths.careTeam + "/*"} element={<CareTeamRoutes />} />
      <Route path={routesPaths.visitCall + "/*"} element={<VisitCallRoutes />} />
      <Route path={routesPaths.wallet + "/*"} element={<MyWalletRoute />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
};
