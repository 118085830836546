import React from "react";
import { healthPackagesQueryNode } from "../../types";
import { Box, Card, CustomIcon, IconButton, TruncateTypography, Typography } from "@toolkit/ui";
import { useStyle } from "./HealthPackageCardStyle";
import { pickLocalizedValue } from "@toolkit/i18n";
import { HeartIcon } from "@/components";
import { useSiteSettingsGetQuery } from "@/gql";
import { truncate } from "lodash";
import defaultProductImage from "@/assets/emptyProduct.png";

type ProductCardProps = {
  healthPackage: healthPackagesQueryNode;
};
export const HealthPackageCard: React.FC<ProductCardProps> = props => {
  const { healthPackage } = props;
  const { classes, cx } = useStyle();
  const { data } = useSiteSettingsGetQuery({});
  const siteCurrency = data?.siteSettings?.defaultCurrency;
  const currentPrice = healthPackage.activeDiscount?.amount
    ? healthPackage.price - healthPackage.activeDiscount?.amount
    : healthPackage.price;
  return (
    <Card className={cx(classes.root)}>
      <Box className={cx(classes.cardImageContainer)}>
        <img src={healthPackage?.mainImageUrl || defaultProductImage} alt='healthPackage' />
      </Box>
      <Box className={cx(classes.cardDetailsContainer)}>
        <TruncateTypography
          text={pickLocalizedValue(healthPackage?.name, healthPackage?.nameAr) || ""}
          className={classes.healthPackageName}
        />
        <TruncateTypography
          text={pickLocalizedValue(healthPackage?.vendor?.name, healthPackage?.vendor?.nameAr) || ""}
          className={classes.healthPackageVendor}
        />
        <Typography className={classes.healthPackageShortDescription}>
          {truncate(pickLocalizedValue(healthPackage?.shortDescription, healthPackage?.shortDescriptionAr) || "", { length: 65 })}
        </Typography>
      </Box>
      <Box className={cx(classes.cardActionContainer)}>
        <Box>
          <Box className={classes.priceContainer}>
            <Typography className={classes.currency}>{siteCurrency}</Typography>
            <Typography className={classes.price}>{currentPrice}</Typography>
          </Box>
          {(healthPackage.activeDiscount?.amount || healthPackage.activeDiscount?.percentage) && (
            <Box>
              <Typography className={classes.discountCurrency}>{siteCurrency}</Typography>
              <Typography className={classes.discountPrice}> {healthPackage?.price}</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.cardActionButtonsContainer}>
          <IconButton className={cx(classes.actionsButton)}>
            <HeartIcon />
          </IconButton>
          <IconButton className={cx(classes.actionsButton, classes.filledIcon)}>
            <CustomIcon icon='cart' />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};
