import { replacePathParams } from "@toolkit/core";
import { CareTeamRegistrationContainer } from "./containers/CareTeamRegistrationContainer/CareTeamRegistrationContainer";
import { MyCareTeamsListContainer } from "./containers/CareTeamsListContainer/MyCareTeamsListContainer";
import { NewCareTeamsListContainer } from "./containers/CareTeamsListContainer/NewCareTeamsListContainer";
import { CareTeamRegistrationConfirmContainer } from "./containers/CareTeamRegistrationConfirmContainer/CareTeamRegistrationConfirmContainer";
import { CareTeamRegistrationSuccessContainer } from "./containers/CareTeamRegistrationSuccessContainer/CareTeamRegistrationSuccessContainer";
import { CareTeamDoctorProfileContainer } from "./containers/CareTeamDoctorProfileContainer/CareTeamDoctorProfileContainer";
import { MyCareTeamContainer } from "./containers/MyCareTeamContainer.tsx/MyCareTeamContainer.tsx";

export const CareTeamBaseRoute = "/care-teams";

export const routes = {
  root: {
    route: "",
    component: MyCareTeamsListContainer,
    get absRoute() {
      return `${CareTeamBaseRoute}${this.route}`;
    },
  },
  newTeams: {
    route: "/new-care-teams",
    component: NewCareTeamsListContainer,
    get absRoute() {
      return `${CareTeamBaseRoute}${this.route}`;
    },
  },
  register: {
    route: "/:teamId/register",
    component: CareTeamRegistrationContainer,
    get absRoute() {
      return `${CareTeamBaseRoute}${this.route}`;
    },
    getRouteWithParams(params: { teamId: string }) {
      return replacePathParams(this.absRoute, params);
    },
  },
  confirmRegistration: {
    route: "/:teamId/confirm-registration",
    component: CareTeamRegistrationConfirmContainer,
    get absRoute() {
      return `${CareTeamBaseRoute}${this.route}`;
    },
    getRouteWithParams(params: { teamId: string }) {
      return replacePathParams(this.absRoute, params);
    },
  },
  registerSuccess: {
    route: "/:teamId/register-success",
    get absRoute() {
      return `${CareTeamBaseRoute}${this.route}`;
    },
    component: CareTeamRegistrationSuccessContainer,
    getRouteWithParams(params: { teamId: string }) {
      return replacePathParams(this.absRoute, params);
    },
  },
  doctorProfile: {
    route: "/doctor-profile/:doctorId",
    get absRoute() {
      return `${CareTeamBaseRoute}${this.route}`;
    },
    component: CareTeamDoctorProfileContainer,
    getRouteWithParams({ doctorId }: { doctorId: string }) {
      return `${this.absRoute}?doctorId=${doctorId}`;
    },
  },
  myCareTeam: {
    route: "/my-care-team",
    get absRoute() {
      return `${CareTeamBaseRoute}${this.route}`;
    },
    component: MyCareTeamContainer,
    getRouteWithParams({ teamId }: { teamId: string }) {
      return `${this.absRoute}?teamId=${teamId}`;
    },
  },
};
