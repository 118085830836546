/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCustomForm, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, BoxProps } from "@toolkit/ui";
import React, { useEffect } from "react";
import { DefaultValues, FieldValues, FormProvider, Path } from "react-hook-form";
import { z } from "zod";
import { FilterButton } from "./FilterButton";
import { FilterDialog } from "./FilterDialog";

type GenericFilterFormProps<TFormValues> = Omit<BoxProps, "onSubmit"> & {
  schema: z.ZodObject<z.ZodRawShape> | z.ZodEffects<z.ZodObject<z.ZodRawShape>>;
  onSubmit: <K extends keyof TFormValues>(fieldName: K, value: TFormValues[K]) => void;
  getValue: <K extends keyof TFormValues>(fieldName: K) => TFormValues[K];
  formFieldName: Path<TFormValues>;
  dialogTitle: string;
  render: (name: Path<TFormValues>) => React.ReactNode;
  formLabel: string;
  defaultValues: DefaultValues<TFormValues>;
  isEmpty?: (value: TFormValues[keyof TFormValues]) => boolean;
};

export const GenericFilterForm = <TFormValues extends FieldValues>({
  schema,
  onSubmit,
  getValue,
  formFieldName,
  dialogTitle,
  formLabel,
  render,
  defaultValues,
  isEmpty: _isEmpty,
  ...props
}: GenericFilterFormProps<TFormValues>) => {
  const { open, handleClose, handleOpen } = useOpenState();
  const { t } = useTranslation("consumer");

  const form = useCustomForm<TFormValues>({
    schema,
    defaultValues,
    mode: "onChange",
  });

  const isEmpty = (value: TFormValues[keyof TFormValues]) => {
    if (_isEmpty) {
      return _isEmpty(value);
    }
    return value === null || value === undefined || (Array.isArray(value) && value.length === 0);
  };

  const { watch, setValue, reset, handleSubmit } = form;
  const currentValue = watch(formFieldName);
  const formValue = getValue(formFieldName);
  const isClearDisabled = isEmpty(currentValue) && isEmpty(formValue);
  useEffect(() => {
    if (open) {
      setValue(formFieldName, formValue);
    }
  }, [open]);

  const handleApply = handleSubmit(data => {
    onSubmit(formFieldName, data[formFieldName]);
    handleClose();
  });

  const handleReset = () => {
    reset();
    onSubmit(formFieldName, defaultValues[formFieldName] as TFormValues[Path<TFormValues>]);
    handleClose();
  };

  return (
    <FormProvider {...form}>
      <form>
        <FilterButton onFilterClick={handleOpen} hasValue={!isEmpty(formValue)} onReset={handleReset} label={t(formLabel)} />
        <FilterDialog
          isApplyDisabled={isEmpty(currentValue)}
          handleApply={handleApply}
          isClearDisabled={isClearDisabled}
          handleReset={handleReset}
          title={t(dialogTitle)}
          open={open}
          onClose={handleClose}
        >
          {open && (
            <Box height={"200px"} {...props}>
              {render(formFieldName)}
            </Box>
          )}
        </FilterDialog>
      </form>
    </FormProvider>
  );
};
