interface MeetoraConfig {
  meetoraBaseUrl: string;
}

class Config {
  private static instance: Config;
  private config: MeetoraConfig | null = null;

  private constructor() {}

  static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  loadConfig(config: MeetoraConfig): void {
    this.config = config;
  }

  getConfig(): MeetoraConfig {
    if (!this.config) {
      throw new Error("Configuration has not been loaded. Please call loadConfig() first.");
    }
    return this.config;
  }

  get(key: keyof MeetoraConfig): string {
    const config = this.getConfig();
    if (config[key] !== undefined) {
      return config[key];
    } else {
      throw new Error(`Key ${key} is not present in the configuration.`);
    }
  }
}

export const meetoraConfig = Config.getInstance();

export const initializeMeetoraConfig = (config: MeetoraConfig): void => {
  meetoraConfig.loadConfig(config);
};
