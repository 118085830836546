import { Box, Button, Typography } from "@toolkit/ui";
import React from "react";
import { useStyle } from "./CategoriesSectionStyle";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { useMarketplaceProductCategoriesQuery } from "@/domains/marketplace-products";
import { useTranslation } from "@toolkit/i18n";
import { MarketplaceCategoryItemButton } from "@/domains/marketplace";
import { ArrowIcon, CircularIconButton } from "@/components";

export const CategoriesSection: React.FC = () => {
  const { classes, cx } = useStyle();
  const { classes: commonClasses } = useCommonSectionsStyle();
  const { t } = useTranslation("consumer");
  const { data } = useMarketplaceProductCategoriesQuery({
    variables: {
      first: 9,
    },
  });
  const categories = data?.marketplaceProductCategories?.edges?.map(edge => edge?.node);

  const handleSeeAll = () => {
    // implement see all
  };
  return (
    <Box className={cx(commonClasses.root, classes.root, "section__root", "categories")}>
      <Box className={cx(commonClasses.container, classes.container, "section__container")}>
        <Box className={commonClasses.titleContainer}>
          <Typography className={cx(commonClasses.title, "title")}>{t("Marketplace Categories")}</Typography>
          <Button onClick={handleSeeAll} className={cx(commonClasses.titleSeeAll, "title__btn")} variant='text'>
            {t("See All")}
          </Button>
        </Box>
        <Box className={classes?.categoriesContainer}>
          {categories?.map(category => (
            <MarketplaceCategoryItemButton
              key={category?.id}
              title={category?.name}
              imgSrc={category?.image}
              classes={{
                root: classes?.item__root,
                button: classes?.item__button,
                icon: classes?.item__icon,
                title: classes?.item__title,
              }}
            />
          ))}
          <Box className={classes?.item__root}>
            <CircularIconButton
              onClick={handleSeeAll}
              className={cx(commonClasses?.seeAllButton, classes.seeAllButton, classes.item__button)}
            >
              <ArrowIcon />
            </CircularIconButton>
            <Typography className={cx(classes?.item__title)}>{t("All Categories")}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
