import { PageTabs } from "@/components";
import { FC } from "react";
import { appointmentsHistoryTabs } from "./tabs";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { AppointmentHistoryTabsEnum } from "../../types/types";
import { makeStyles } from "@toolkit/ui";

const useStyles = makeStyles()(() => ({
  root: {
    marginBlockStart: "5px",
    marginBlockEnd: "10px",
  },
}));

export const AppointmentsHistoryTabs: FC<{ currentTab: AppointmentHistoryTabsEnum }> = ({ currentTab }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleOnTabClick = (event: React.BaseSyntheticEvent, index: number) => {
    event.preventDefault();
    const selectedTab = appointmentsHistoryTabs[index]?.routeParameterValue;
    navigate(routes.myAppointments.getRouteWithParams({ tab: selectedTab }));
  };

  const tabsLabels = appointmentsHistoryTabs.map(tab => tab.label);

  const getSelectedTabIndex = () => {
    const index = appointmentsHistoryTabs.findIndex(_tab => _tab.routeParameterValue === currentTab);
    return index === -1 ? 0 : index;
  };

  return (
    <PageTabs
      classes={{
        root: classes.root,
      }}
      activeTabIndex={getSelectedTabIndex()}
      onTabClick={handleOnTabClick}
      tabs={tabsLabels}
    />
  );
};
