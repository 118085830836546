import { makeStyles } from "@toolkit/ui";

export const usePageTabsStyle = makeStyles<{ tabsCount?: number }>()((theme, { tabsCount = 1 }) => ({
  root: {
    width: "calc(100% - 20px)",
    height: "40px",
    marginBottom: "10px",
    marginInline: "10px",
    borderRadius: "20px",
    boxShadow: theme.mixins.shadows.sm,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
  },
  tabsUpperContainer: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    overflow: "hidden",
  },
  tabsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    position: "relative",
  },
  tab: {
    height: "40px",
    minWidth: "100px",
    width: `calc(100%/${tabsCount})`,
    margin: 0,
    borderRadius: "20px",
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  activeTab: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary[900],
    },
  },
  navBtnContainer: {
    "& svg": {
      width: "6px",
      height: "20px",
    },
    "& path": {
      fill: "inherit",
    },
  },
}));
