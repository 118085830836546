import { useRef, useState } from "react";
import callNotificationSound from "@/assets/sounds/callNotificationSound.mp3";

const VIBRATION_PERIOD = 600;
const VIBRATION_BREAK = 900;
const RINGING_PERIOD = 25000;
const RINGING_RETRIES = 5;
const RINGING_RETRY_INTERVAL = 2000;

const useVisitStartingNowNotification = () => {
  const [audio] = useState(() => {
    const audioElement = new Audio(callNotificationSound);
    audioElement.preload = "auto";
    audioElement.loop = true;
    return audioElement;
  });
  const vibrationInterval = useRef<number | undefined>(undefined);
  const isPlaying = useRef(false);

  const playSound = () => {
    if (isPlaying.current) return;
    isPlaying.current = true;

    let retryCount = 0;
    const tryPlayAudio = () => {
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => clearInterval(retryTimer))
          .catch(() => {
            retryCount++;
            if (retryCount > RINGING_RETRIES) {
              clearInterval(retryTimer);
              console.error("Failed to play audio after retries");
              isPlaying.current = false;
            }
          });
      }
    };

    const retryTimer = setInterval(tryPlayAudio, RINGING_RETRY_INTERVAL);
    tryPlayAudio();
  };

  const stopSound = () => {
    audio.pause();
    audio.currentTime = 0;
    isPlaying.current = false;
  };

  const startVibration = () => {
    if ("vibrate" in navigator) {
      vibrationInterval.current = window.setInterval(() => {
        navigator.vibrate(VIBRATION_PERIOD);
      }, VIBRATION_PERIOD + VIBRATION_BREAK);
    }
  };

  const stopVibration = () => {
    if (vibrationInterval.current) {
      clearInterval(vibrationInterval.current);
      vibrationInterval.current = undefined;
    }
    if ("vibrate" in navigator) {
      navigator.vibrate(0);
    }
  };

  const startNotificationSound = () => {
    playSound();
    startVibration();

    setTimeout(() => {
      stopNotificationSound();
    }, RINGING_PERIOD);
  };

  const stopNotificationSound = () => {
    stopSound();
    stopVibration();
  };

  return { startNotificationSound, stopNotificationSound };
};

export default useVisitStartingNowNotification;
