import { memo, useCallback } from "react";
import { CallParticipantInfo, PeerAvatar, PeerTitle } from "./Meetora";
import {
  ListItemButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  MuiMicIcon,
  MuiMicOffIcon,
  MuiScreenShareIcon,
  MuiVideocamIcon,
  MuiVideocamOffIcon,
  ListItem,
} from "@toolkit/ui";
import { useParticipantListItemStyles } from "./ParticipantListItem.styles";

type ParticipantListItemProps = {
  participant: CallParticipantInfo;
  onClick?: (participant: CallParticipantInfo) => void;
  color?: string;
  hideActions?: boolean;
};

export const ParticipantListItem = memo(({ participant, color, onClick, hideActions }: ParticipantListItemProps) => {
  const { classes } = useParticipantListItemStyles({ color });
  const handleOnClick = useCallback(() => {
    onClick?.(participant);
  }, [onClick, participant]);

  const Item = onClick ? ListItemButton : (ListItem as typeof ListItemButton);
  return (
    <Item key={participant.id} onClick={handleOnClick} className={classes.root}>
      <ListItemAvatar>
        <PeerAvatar peer={participant.participantId} size={36} />
      </ListItemAvatar>
      <ListItemText>
        <Typography className={classes.title}>
          <PeerTitle peer={participant.participantId} />
        </Typography>
      </ListItemText>
      {!hideActions && (
        <ListItemSecondaryAction className={classes.action}>
          {!participant.state.audioMuted ? <MuiMicIcon className={classes.icon} /> : <MuiMicOffIcon className={classes.icon} />}
          {!participant.state.videoMuted ? <MuiVideocamIcon className={classes.icon} /> : <MuiVideocamOffIcon className={classes.icon} />}
          {!participant.state.screenMuted && <MuiScreenShareIcon className={classes.icon} />}
        </ListItemSecondaryAction>
      )}
    </Item>
  );
});
