import { Client } from "@meetora/client-json";
import { createMeetoraClient } from "./util";

const MEETORA_STORAGE_KEY = "health_im_authentication";
export const MEETORA_STORAGE_USER_KEY = `${MEETORA_STORAGE_KEY}_user`;

export class MeetoraClient {
  private static client: Client | null = null;

  static getInstance() {
    if (!MeetoraClient.client) {
      MeetoraClient.client = createMeetoraClient({ key: MEETORA_STORAGE_KEY });
    }

    return MeetoraClient.client;
  }

  static logout() {
    localStorage.removeItem(MEETORA_STORAGE_KEY);
    localStorage.removeItem(MEETORA_STORAGE_USER_KEY);
    return MeetoraClient.client?.logout();
  }
}
