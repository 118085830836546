/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVisitQueryVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetVisitQuery = { __typename?: 'Query', visit?: { __typename?: 'Visit', id: string, status?: Types.VisitStatus | null, number?: string | null, type?: Types.VisitType | null, cancellationReason?: string | null, consumerJoined?: boolean | null, consumerJoinedDate?: any | null, consumerLeft?: boolean | null, consumerLeftDate?: any | null, meetingPlatformLinkForChatView?: string | null, meetingPlatformLink?: string | null, meetingPlatformLinkForJoinCall?: string | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', fullName?: string | null, gender?: Types.UserGender | null, photo?: string | null } | null } | null, appointment?: { __typename?: 'Appointment', id: string } | null } | null };


export const GetVisitDocument = gql`
    query getVisit($visitId: ID) {
  visit(id: $visitId) {
    id
    status
    number
    type
    doctor {
      id
      user {
        fullName
        gender
        photo
      }
    }
    appointment {
      id
    }
    cancellationReason
    consumerJoined
    consumerJoinedDate
    consumerLeft
    consumerLeftDate
    meetingPlatformLinkForChatView
    meetingPlatformLink
    meetingPlatformLinkForJoinCall
  }
}
    `;

/**
 * __useGetVisitQuery__
 *
 * To run a query within a React component, call `useGetVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useGetVisitQuery(baseOptions?: Apollo.QueryHookOptions<GetVisitQuery, GetVisitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisitQuery, GetVisitQueryVariables>(GetVisitDocument, options);
      }
export function useGetVisitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisitQuery, GetVisitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisitQuery, GetVisitQueryVariables>(GetVisitDocument, options);
        }
export type GetVisitQueryHookResult = ReturnType<typeof useGetVisitQuery>;
export type GetVisitLazyQueryHookResult = ReturnType<typeof useGetVisitLazyQuery>;
export type GetVisitQueryResult = Apollo.QueryResult<GetVisitQuery, GetVisitQueryVariables>;