import { useTranslation } from "@/i18n/i18n.config";
import { getDescription, getTransaction } from "../../utils/transaction-details.utils";
import { useStyles } from "./TransactionDetailsContainerStyle";
import { Box, Button, Grid, Typography } from "@toolkit/ui";
import { PaymentOperationLog } from "@health/queries/types";
import { useTransactionDetails } from "./useTransactionDetails";
import { MyWalletSkeleton } from "../../components/MyWalletSkeleton";
import { useSearchParams } from "react-router-dom";

export const TransactionDetailsContainer: React.FC = () => {
  const { t } = useTranslation();
  const { classes, cx, theme } = useStyles();
  const [searchParams] = useSearchParams();

  const transactionId = searchParams.get("transactionId");

  const {
    date,
    icon,
    isRefundSuccess,
    loading,
    onRefundToCreditCardPress,
    operationType,
    time,
    transactionAmountText,
    paymentOperationLog,
    isTranscriptionLoading,
  } = useTransactionDetails(transactionId, theme);
  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.root}>
          <Grid height='100%' className={"card__container"} container alignItems='center' justifyContent='center'>
            <Grid item className={cx(classes.transactionCardWrapper, classes.iconButton)}>
              {icon}
            </Grid>
          </Grid>
          <Typography className={classes.text}>{t(operationType)}</Typography>
          <Typography className={classes.date}>{t("{{date}} at {{time}}", { date, time })}</Typography>
          <Typography className={classes.balance}>{transactionAmountText}</Typography>
          {isTranscriptionLoading ? (
            <MyWalletSkeleton />
          ) : (
            <Box className={classes.content}>
              <Grid height='100%' className={"card__content"} container gap={2} alignItems='center'>
                <Grid item className={cx(classes.iconWrapper, classes.icon)}>
                  {icon}
                </Grid>
                <Grid>
                  <Typography className={classes.source}>{getTransaction(paymentOperationLog as PaymentOperationLog, t)}</Typography>
                  <Typography textAlign='start'>{getDescription(paymentOperationLog as PaymentOperationLog)}</Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      {!isRefundSuccess && paymentOperationLog?.paymentAmountCanBeRefundedFromWallet && (
        <Box className={classes.footer}>
          <Button className={classes.button} disabled={loading} onClick={onRefundToCreditCardPress}>
            {t("Send to my Credit Care")}
          </Button>
        </Box>
      )}
    </>
  );
};
