import React, { useEffect, useState } from "react";
import { Box, BoxProps } from "../../base/mui";
import DOMPurify from "dompurify";

interface InlineSVGProps extends BoxProps {
  src?: string;
  svgString?: string;
  width?: string | number;
  height?: string | number;
  alt?: string;
}

const isValidSVG = (content: string): boolean => {
  const svgRegex = /<svg[^>]*>[\s\S]*<\/svg>/i;
  return svgRegex.test(content);
};

const sanitizeSVG = (svg: string): string => {
  return DOMPurify.sanitize(svg);
};

const fetchSVGContent = async (src: string): Promise<string | null> => {
  try {
    const response = await fetch(src);
    const text = await response.text();
    return isValidSVG(text) ? sanitizeSVG(text) : null;
  } catch (error) {
    console.error("Failed to fetch the SVG:", error);
    return null;
  }
};

export const InlineSVG: React.FC<InlineSVGProps> = ({ src, svgString, width = "24px", height = "24px", alt = "", ...props }) => {
  const [svgContent, setSvgContent] = useState<string>("");

  useEffect(() => {
    const loadSVG = async () => {
      if (src) {
        const fetchedSVG = await fetchSVGContent(src);
        if (fetchedSVG) {
          setSvgContent(fetchedSVG);
        } else {
          console.error("Fetched content is not a valid SVG.");
        }
      } else if (svgString) {
        const sanitizedSVG = sanitizeSVG(svgString);
        if (isValidSVG(sanitizedSVG)) {
          setSvgContent(sanitizedSVG);
        } else {
          console.error("Provided svgString is not a valid SVG.");
        }
      }
    };

    loadSVG();
  }, [src, svgString]);

  if (!src && !svgString) {
    console.error("You must provide either a `src` or `svgString` prop.");
    return null;
  }

  return (
    <Box component='span' dangerouslySetInnerHTML={{ __html: svgContent }} aria-label={alt} width={width} height={height} {...props} />
  );
};
