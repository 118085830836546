import { FC } from "react";
import { useParams } from "react-router-dom";
import { VisitCallGuest } from "./components";

export const VisitCallGuestPage: FC = () => {
  const params = useParams();

  return <VisitCallGuest token={params["token"]!} />;
};

export default VisitCallGuestPage;
