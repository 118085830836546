import { CallProvider as BaseCallProvider } from "./Meetora";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { CallContext, CallInfoParams } from "./CallContext";
import { useCall } from "./Meetora";
import { inputConferencePeerAnonymousChat } from "@meetora/schema/calls/conference/InputConferencePeer";

const Provider: FC<PropsWithChildren> = ({ children }) => {
  const [callInfo, setCallInfo] = useState<CallInfoParams>();
  const anonChatId = callInfo?.chatId;
  const peer = useMemo(() => (anonChatId ? inputConferencePeerAnonymousChat({ anonChatId }) : undefined), [anonChatId]);
  const call = useCall({ peer });
  const select = useCallback((v: CallInfoParams) => setCallInfo(v), []);

  const value = useMemo(() => {
    return { callInfo, call, select };
  }, [call, callInfo, select]);

  return <CallContext.Provider value={value}>{children}</CallContext.Provider>;
};

export const CallProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BaseCallProvider>
      <Provider>{children}</Provider>
    </BaseCallProvider>
  );
};
