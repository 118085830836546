import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const VideoChatIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const { fill = theme.palette.primary.main } = props;
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      id='icons8-video-chat_1_'
      data-name='icons8-video-chat (1)'
      width='29.5'
      height='23.881'
      viewBox='0 0 29.5 23.881'
      {...props}
    >
      <path
        id='Path_12799'
        data-name='Path 12799'
        d='M4.054,22.382A1.054,1.054,0,0,1,3,21.329V10.863A3.867,3.867,0,0,1,6.863,7H28.637A3.867,3.867,0,0,1,32.5,10.863v2.529a1.054,1.054,0,0,1-2.107,0V10.863a1.758,1.758,0,0,0-1.756-1.756H6.863a1.758,1.758,0,0,0-1.756,1.756V21.329A1.054,1.054,0,0,1,4.054,22.382Z'
        transform='translate(-3 -7)'
        fill={fill}
      />
      <path
        id='Path_12800'
        data-name='Path 12800'
        d='M28.637,35.643H6.863A3.867,3.867,0,0,1,3,31.78V30.586a1.054,1.054,0,1,1,2.107,0V31.78a1.758,1.758,0,0,0,1.756,1.756H28.637a1.758,1.758,0,0,0,1.756-1.756V24.054a1.054,1.054,0,0,1,2.107,0V31.78A3.867,3.867,0,0,1,28.637,35.643Z'
        transform='translate(-3 -11.762)'
        fill={fill}
      />
      <path
        id='Ellipse_2848'
        data-name='Ellipse 2848'
        d='M2.714-1.5A4.214,4.214,0,1,1-1.5,2.714,4.219,4.219,0,0,1,2.714-1.5Zm0,6.321A2.107,2.107,0,1,0,.607,2.714,2.11,2.11,0,0,0,2.714,4.821Z'
        transform='translate(12.036 6.417)'
        fill={fill}
      />
      <path
        id='Path_12801'
        data-name='Path 12801'
        d='M15.054,37.131A1.054,1.054,0,0,1,14,36.077V35.024A7.019,7.019,0,0,1,21.024,28a7.18,7.18,0,0,1,6.18,3.618,1.054,1.054,0,0,1-1.825,1.053,5.067,5.067,0,0,0-4.355-2.563,4.912,4.912,0,0,0-4.917,4.917v1.054A1.054,1.054,0,0,1,15.054,37.131Z'
        transform='translate(-6.274 -13.25)'
        fill={fill}
      />
    </SvgIcon>
  );
};
