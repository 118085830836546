import { useEffect, useRef, useState } from "react";

const useCountDown = (duration: number | null, onEnd?: () => void) => {
  const onEndRef = useRef(onEnd);
  useEffect(() => {
    onEndRef.current = onEnd;
  }, [onEnd]);

  const [value, setValue] = useState<number | null>(null);
  useEffect(() => {
    if (duration === null) {
      return;
    }

    setValue(duration);
    const intervalId = setInterval(() => setValue(d => (d && d > 0 ? d - 1 : d)), 1000);
    return () => clearInterval(intervalId);
  }, [duration]);

  useEffect(() => {
    if (value === 0) {
      onEndRef.current?.();
    }
  }, [value]);

  return { countDown: value === null ? null : value.toFixed(0) };
};

export default useCountDown;
