/* eslint-disable no-restricted-imports */
import { useCustomForm } from "@toolkit/core";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  DoctorsListFiltersFormSchema,
  doctorListFiltersFormDefaultValues,
  IDoctorsListFiltersFormValues,
} from "./DoctorsListFiltersFormSchema";
import { AppointmentTypesFilterForm } from "./Filters/AppointmentTypesFilter";
import { SpecializationsFilterForm } from "./Filters/SpecializationsFilter";
import { GenderFilterForm } from "./Filters/GenderFilter";
import { FacilityFilterForm } from "./Filters/Facility";
import { LanguagesFilterForm } from "./Filters/LanguagesFilter";
import { Box, Button } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useStyles } from "./DoctorsListFiltersStyle";
import { CallPriceFilterForm } from "./Filters/CallPriceFilter";

type DoctorsListFiltersFormProps = {
  onSubmit?: (values: IDoctorsListFiltersFormValues) => void;
  excludeFilters?: Partial<Record<keyof IDoctorsListFiltersFormValues, boolean>>;
};

export type DoctorsListFiltersFormRef = {
  submit: () => void;
};

export const DoctorsListFiltersForm: React.FC<DoctorsListFiltersFormProps> = props => {
  const { excludeFilters } = props;
  const { t } = useTranslation("consumer");
  const form = useCustomForm<IDoctorsListFiltersFormValues>({
    schema: DoctorsListFiltersFormSchema,
    defaultValues: doctorListFiltersFormDefaultValues,
    mode: "onChange",
  });
  const { setValue } = form;
  const onSubmit = <K extends keyof IDoctorsListFiltersFormValues>(fieldName: K, value: IDoctorsListFiltersFormValues[K]) => {
    setValue(fieldName as keyof IDoctorsListFiltersFormValues, value as IDoctorsListFiltersFormValues[keyof IDoctorsListFiltersFormValues]);
    props.onSubmit?.({
      ...form.getValues(),
      [fieldName]: value,
    });
  };
  const getValue = <K extends keyof IDoctorsListFiltersFormValues>(fieldName: K) => {
    return form.getValues()[fieldName];
  };
  const resetForm = () => {
    form.reset(doctorListFiltersFormDefaultValues);
    props.onSubmit?.(doctorListFiltersFormDefaultValues);
  };

  const { classes } = useStyles();
  return (
    <FormProvider {...form}>
      <form>
        <Box className={classes.container}>
          <Button className={classes.button} onClick={resetForm} variant='outlined'>
            {t("All")}
          </Button>
          {!excludeFilters?.appointmentTypes && <AppointmentTypesFilterForm getValue={getValue} onSubmit={onSubmit} />}
          {!excludeFilters?.specializations && <SpecializationsFilterForm getValue={getValue} onSubmit={onSubmit} />}
          {!excludeFilters?.gender && <GenderFilterForm getValue={getValue} onSubmit={onSubmit} />}
          {!excludeFilters?.vendors && <FacilityFilterForm getValue={getValue} onSubmit={onSubmit} />}
          {!excludeFilters?.callPrice && <CallPriceFilterForm getValue={getValue} onSubmit={onSubmit} />}

          {!excludeFilters?.languages && <LanguagesFilterForm getValue={getValue} onSubmit={onSubmit} />}
        </Box>
      </form>
    </FormProvider>
  );
};
