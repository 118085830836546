import { makeStyles } from "@toolkit/ui";

export const useVirtualVisitDoctorCardStyle = makeStyles()(theme => ({
  card: {
    marginInline: "10px",
    position: "relative",
  },
  doctorAvatar: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 20px)",
    justifyContent: "center",
    position: "relative",
    "& .MuiAvatar-root": {
      width: "100px",
      height: "100px",
    },
  },
  doctorName: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.secondary.main,
  },
  price: {
    color: theme.palette.secondary.main,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  vendorName: {
    color: theme.palette.text.primary,
  },
  vendorAvatar: {
    borderRadius: "0",
    height: "30px",
    width: "100%",
    "& img": {
      objectFit: "scale-down",
    },
  },
  avatarsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "fit-content",
  },
  actionsContainer: {
    margInline: "0px",
    gap: 5,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  callButton: {
    borderRadius: "20px",
    height: "40px",
    margin: 0,
  },
  profileButton: {
    borderRadius: "20px",
    height: "40px",
    margin: 0,
  },
  languages: {
    color: theme.palette.stale.main,
    fontSize: theme.mixins.fonts.fontSize.sm,
    width: "fit-content",
    display: "inline-block",
  },
  languagesAndExperienceContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  experience: {
    width: "fit-content",
    display: "inline-block",
  },
  dot: {
    backgroundColor: theme.palette.primary[100],
    marginInline: "5px",
  },
}));
