import { makeStyles } from "@toolkit/ui";

export const useCareTeamCardStyle = makeStyles<{ hasActions?: boolean }>()((theme, { hasActions }) => ({
  card: {},
  doctorAvatar: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 20px)",
    justifyContent: "center",
    position: "relative",
    "& .MuiAvatar-root": {
      width: "100px",
      height: "100px",
    },
  },
  teamName: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.secondary.main,
  },
  vendorName: {
    color: theme.palette.text.primary,
  },
  vendorAvatar: {
    borderRadius: "0",
    height: "30px",
    width: "100%",
    "& img": {
      objectFit: "scale-down",
    },
  },
  teamLeaderBadge: {
    paddingInline: "7px",
    left: "calc(50% - 49.5px)",
    height: "25px",
    borderRadius: "15px",
    backgroundColor: theme.palette.secondary.main,
    width: "fit-content",
    color: theme.palette.common.white,
    fontSize: theme.mixins.fonts.fontSize.xxs,
    lineHeight: 2.6,
    position: "absolute",
    bottom: "-5px",
  },
  avatarsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0px",
  },
  actionsContainer: {
    width: hasActions ? "100%" : "fit-content",
    margInline: "0px",
    gap: 10,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  infoContainer: {
    flexDirection: "row",
    gap: "0px",
  },
  infoContainerWithActions: {
    flexDirection: "column",
    gap: "10px",
    flexWrap: "wrap",
  },
  registerButton: {
    borderRadius: "20px",
    height: "40px",
    margin: 0,
  },
  profileButton: {
    borderRadius: "20px",
    height: "40px",
    margin: 0,
  },
  expandMoreButton: {
    width: "10px",
    height: "10px",
    "& svg": {
      width: "10px",
      height: "10px",
    },
    "& path": {
      fill: theme.palette.text.primary,
    },
  },
  languages: {
    color: theme.palette.stale.main,
    fontSize: theme.mixins.fonts.fontSize.sm,
    width: "fit-content",
    display: "inline-block",
  },
  languagesAndExperienceContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  experience: {
    width: "fit-content",
    display: "inline-block",
  },
  dot: {
    backgroundColor: theme.palette.primary[100],
    marginInline: "5px",
  },
  displayNone: {
    display: "none",
  },
  fullHeight: {
    height: "100%",
  },
}));
