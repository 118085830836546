import { useOnHealthProgramStatusUpdate } from "@health/sse";
import { useAuth } from "react-oidc-context";
import { useGetVisitQuery } from "../../gql";
import queryString from "query-string";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";
import { useAddToast } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const useVisitCallWaitingContainer = () => {
  const { user } = useAuth();
  const { visitId } = queryString.parse(location.search) as { visitId: string };
  const { t } = useTranslation("consumer");
  const { failed } = useAddToast();
  const navigate = useNavigate();
  const { data, loading, refetch } = useGetVisitQuery({
    skip: !user?.access_token,
    nextFetchPolicy: "cache-and-network",
    variables: { visitId: visitId },
  });

  useOnHealthProgramStatusUpdate(({ data }) => {
    if (data.graphqlType === "Visit" && data?.id) {
      refetch();
    }
  });

  useEffect(() => {
    if (!loading && !data?.visit?.id) {
      failed(t("Something went wrong, please try again"));
      navigate(routesPaths.home);
    }
  }, [loading]);
  return { visit: data?.visit, isLoading: loading };
};
