import { base64Atob } from "./base64";
import { meetoraConfig } from "./meetoraConfig";
import { Client, LocalStorage } from "@meetora/client-json";

export function parseUserId(token: string | null | undefined): string | null {
  try {
    if (!token) {
      return null;
    }

    const userInfo = JSON.parse(base64Atob(token.split(".")[1] || ""));
    return userInfo?.user_id ? `${userInfo.user_id}` : null;
  } catch (error: any) {
    console.error("Failed to parse accessToken", error);
    return null;
  }
}

export function createMeetoraClient({ key }: { key?: string }) {
  return new Client({
    baseUrl: meetoraConfig.get("meetoraBaseUrl"),
    storage: key ? new LocalStorage({ key }) : undefined,
    debug: process.env.NODE_ENV !== "production",
  });
}
