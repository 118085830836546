import { AvailabilityBody } from "@/domains/doctor/components/DoctorProfileTabs/tabsBodies/AvailabilityBody";
import { ExperienceBody } from "@/domains/doctor/components/DoctorProfileTabs/tabsBodies/ExperienceBody";
import { OverviewBody } from "@/domains/doctor/components/DoctorProfileTabs/tabsBodies/OverviewBody";
import { QualificationBody } from "@/domains/doctor/components/DoctorProfileTabs/tabsBodies/QualificationsBody";
import { i18n } from "@toolkit/i18n";
import { MyTeamBody } from "./TabsBodies/MyTeamBody";

export const tabs = [
  {
    label: i18n.t("Overview", { ns: "consumer" }),
    key: "overview",
    component: OverviewBody,
  },
  {
    label: i18n.t("Team Members", { ns: "consumer" }),
    key: "teamMembers",
    component: MyTeamBody,
  },
  {
    label: i18n.t("Qualifications", { ns: "consumer" }),
    key: "qualifications",
    component: QualificationBody,
  },
  {
    label: i18n.t("Experience", { ns: "consumer" }),
    key: "experience",
    component: ExperienceBody,
  },
  {
    label: i18n.t("Availability", { ns: "consumer" }),
    key: "availability",
    component: AvailabilityBody,
  },
] as const;

export type TabKey = (typeof tabs)[number]["key"];
