import { useCallback } from "react";
import useSendConferenceCallInvitation from "@meetora/react/queries/conversation/useSendConferenceCallInvitation";
import { CustomDialog, DialogContent, FormActions, useAddToast } from "@toolkit/ui";
import { CallInstance } from "./Meetora";
import { useTranslation } from "@toolkit/i18n";
import { FormProvider, useForm } from "react-hook-form";
import { GuestInviteContactInput, GuestInviteForm } from "./GuestInviteForm";

type GuestInviteProps = {
  call: CallInstance;
  onClose: () => void;
};

type GuestInviteInput = { contacts: GuestInviteContactInput[] };

export const GuestInvite = ({ call, onClose }: GuestInviteProps) => {
  const { t } = useTranslation();
  const { succeeded, failed } = useAddToast();
  const { sendConferenceCallInvitation, loading } = useSendConferenceCallInvitation();

  const methods = useForm<GuestInviteInput>({
    mode: "onChange",
  });

  const onSubmit = useCallback(
    async (values: GuestInviteInput) => {
      if (values.contacts.length < 1) {
        return;
      }

      const result = await sendConferenceCallInvitation({
        peer: call.conferencePeer!,
        emails: values.contacts.map(c => c.email).filter(c => !!c),
        phoneNumbers: values.contacts.map(c => c.phoneNumber).filter(c => !!c),
      });

      switch (result?._name) {
        case "void": {
          onClose();
          succeeded(t("Invitation Sent!"));
          break;
        }
        case "errorBadRequestWrongEmail": {
          failed(t("Invalid Email"));
          break;
        }
        case "errorBadRequestPhoneNumberInvalid": {
          failed(t("Invalid Phone Number"));
          break;
        }
        default:
          failed(t("Failed to send invitation, please retry."));
      }
    },
    [call.conferencePeer, failed, onClose, sendConferenceCallInvitation, succeeded, t]
  );

  return (
    <CustomDialog
      open
      onClose={onClose}
      type='base'
      DialogTitleProps={{
        onClose,
        title: t("Add Guests"),
      }}
    >
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <GuestInviteForm />
            <FormActions
              hasCancel
              isLoading={loading}
              hasFormButton
              newButtonDisabled={false}
              formButtonTitle={t("Send")}
              onNavigation={onClose}
            />
          </form>
        </FormProvider>
      </DialogContent>
    </CustomDialog>
  );
};
