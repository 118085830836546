import { H_OrderDirection, PaymentOperationLogSortField } from "@health/queries/types";
import { useSiteSettingsGetQuery } from "@/gql";
import { useEffect, useRef, useState } from "react";
import { useWalletQuery } from "../../gql";

export const useGetMyWallet = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver(entries => {
        setContainerHeight(entries[0].contentRect.height);
      });

      observer.observe(containerRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const {
    data,
    loading,
    refetch: handleRefresh,
    fetchMore,
  } = useWalletQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 10,
      sortBy: {
        direction: H_OrderDirection.Desc,
        field: PaymentOperationLogSortField.Created,
      },
    },
  });

  const { data: siteSettingsData } = useSiteSettingsGetQuery({
    fetchPolicy: "cache-first",
  });

  const balance = data?.me?.patient?.wallet?.balance || 0;
  const defaultCurrency = siteSettingsData?.siteSettings?.defaultCurrency || "";
  const paymentOperationLogs = data?.me?.patient?.paymentOperationLogs?.edges?.map(edge => edge?.node) || [];
  const pageInfo = data?.me?.patient?.paymentOperationLogs?.pageInfo;

  const onFetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo.endCursor || "",
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.me?.patient?.paymentOperationLogs?.edges?.length) return prevResult;
          return {
            ...fetchMoreResult,
            me: {
              ...fetchMoreResult.me,
              patient: {
                ...fetchMoreResult.me.patient,
                paymentOperationLogs: {
                  ...fetchMoreResult.me.patient.paymentOperationLogs,
                  edges: [
                    ...(prevResult?.me?.patient?.paymentOperationLogs?.edges || []),
                    ...(fetchMoreResult.me.patient?.paymentOperationLogs?.edges || []),
                  ],
                },
              },
            },
          };
        },
      });
    }
  };

  const hasMore = pageInfo?.hasNextPage;
  return {
    containerRef,
    containerHeight,
    balance,
    defaultCurrency,
    paymentOperationLogs,
    loading,
    handleRefresh,
    onFetchMoreData,
    hasMore,
  };
};
