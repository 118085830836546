import { FC } from "react";
import { Box } from "@toolkit/ui";
import { WeekCardProps } from "./types";
import { useStyles } from "./styles";
import { CalenderButton } from "./CalenderButton";
import { i18n } from "@toolkit/i18n";

export const WeekCard: FC<WeekCardProps> = ({ weekStart, selectedDate, minDate, maxDate, onDateChange }) => {
  const { classes } = useStyles();
  const days = Array.from({ length: 7 }, (_, i) => weekStart.clone().add(i, "days"));
  const lang = i18n.language;
  return (
    <Box className={classes.weekCard}>
      {days.map(day => (
        <CalenderButton
          key={day.format("YYYY-MM-DD")}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onDateChange(day);
          }}
          disabled={
            !((minDate ? day.endOf("day").isSameOrAfter(minDate) : true) && (maxDate ? day.startOf("day").isSameOrBefore(maxDate) : true))
          }
          className={selectedDate?.isSame(day, "day") ? "selected" : ""}
        >
          <span>{day.locale(lang).format("ddd")}</span>
          <span>{day.locale(lang).format("D")}</span>
        </CalenderButton>
      ))}
    </Box>
  );
};
