import { Box, Button, CardsContainer, Typography, useMediaQuery } from "@toolkit/ui";
import React from "react";
import { useStyle } from "./HealthPackagesSectionStyle";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { useTranslation } from "@toolkit/i18n";
import { useAuth } from "react-oidc-context";
import { HealthPackageCard, ProductCardSkeleton, useMarketplaceHealthPackagesForYouQuery } from "@/domains/marketplace-health-packages";
import { ArrowIcon, CircularIconButton } from "@/components";

export const HealthPackagesSection: React.FC = () => {
  const { classes, cx, theme } = useStyle();
  const { classes: commonClasses } = useCommonSectionsStyle();
  const { t } = useTranslation("consumer");
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useAuth();
  const { data, loading } = useMarketplaceHealthPackagesForYouQuery({
    variables: {
      first: 9,
    },
    skip: !user?.access_token,
  });
  const healthPackages = data?.marketplaceHealthPackagesForYou?.edges?.map(edge => edge?.node) || [];
  const handleSeeAll = () => {
    // implement see all
  };
  return (
    <Box className={cx(commonClasses.root, classes.root, "section__root", "health-packages")}>
      <Box className={cx(commonClasses.container, classes.container)}>
        <Box className={cx(commonClasses.root, classes.root, "section__root", "healthPackages")}>
          <Box className={cx(commonClasses.container, classes.container)}>
            <Box className={commonClasses.titleContainer}>
              <Typography className={cx(commonClasses.title, "title")}>{t("Featured Health Packages")}</Typography>
              <Button onClick={handleSeeAll} className={cx(commonClasses.titleSeeAll, "title__btn")} variant='text'>
                {t("See All")}
              </Button>
            </Box>
            <CardsContainer
              classes={{
                cardsContainerClassName: classes.cardsContainerClassName,
                navBtnClassName: classes.navBtnClassName,
                navBtnContainerClassName: classes.navBtnContainerClassName,
                cardsContainer: classes.cardsContainer,
                cardsUpperContainer: classes.cardsUpperContainer,
                card: classes.card,
                skeletonContainer: classes.skeletonContainer,
              }}
              isLoadingData={loading}
              countOfSkeletons={7}
              CardSkeletonComponent={
                <Box className={cx(classes.healthPackagesCardWrapper)}>
                  <ProductCardSkeleton />
                </Box>
              }
              cardsScrollAmount={2}
              hideNavBtns={isSmall}
              cards={healthPackages?.map(product => (
                <Box key={product?.id} className={cx(classes.healthPackagesCardWrapper)}>
                  <HealthPackageCard healthPackage={product} />
                </Box>
              ))}
            />
            <Box className={classes.actionsContainer}>
              <Box className={classes.seeAllButton__container}>
                <Typography className={"see-all__title"}>{t("See All")}</Typography>
                <CircularIconButton onClick={handleSeeAll} height={"46px"} className={commonClasses.seeAllButton}>
                  <ArrowIcon fill={theme.palette.common.white} />
                </CircularIconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
