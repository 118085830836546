import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const MailIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-email' width='29.61' height='23.688' viewBox='0 0 29.61 23.688' {...props}>
      <path
        id='Path_12796'
        data-name='Path 12796'
        d='M28.8,35.325H8.812A4.776,4.776,0,0,1,4,30.513V28.292a1.11,1.11,0,0,1,2.221,0v2.221A2.563,2.563,0,0,0,8.812,33.1H28.8a2.563,2.563,0,0,0,2.591-2.591v-7.4a1.11,1.11,0,0,1,2.221,0v7.4A4.776,4.776,0,0,1,28.8,35.325Z'
        transform='translate(-4 -11.636)'
        fill={fill}
      />
      <path
        id='Path_12797'
        data-name='Path 12797'
        d='M5.11,21.621A1.11,1.11,0,0,1,4,20.51v-7.7A4.776,4.776,0,0,1,8.812,8H28.8a4.776,4.776,0,0,1,4.812,4.812v1.481a1.11,1.11,0,0,1-2.221,0V12.812A2.563,2.563,0,0,0,28.8,10.221H8.812a2.563,2.563,0,0,0-2.591,2.591v7.7A1.11,1.11,0,0,1,5.11,21.621Z'
        transform='translate(-4 -8)'
        fill={fill}
      />
      <path
        id='Path_12798'
        data-name='Path 12798'
        d='M20.378,24.418a2.684,2.684,0,0,1-1.2-.282l-.038-.02-8.365-4.59a1.11,1.11,0,1,1,1.068-1.947l8.341,4.576a.458.458,0,0,0,.388,0l12.933-7.021a1.11,1.11,0,1,1,1.06,1.952L21.611,24.119l-.033.017A2.684,2.684,0,0,1,20.378,24.418Z'
        transform='translate(-5.61 -9.818)'
        fill={fill}
      />
    </SvgIcon>
  );
};
