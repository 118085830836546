import { makeStyles, Theme } from "@toolkit/ui";

export const useStyles = makeStyles<{ width?: string | number; height?: string | number }>()((theme: Theme, { width, height }) => ({
  root: {
    width: width || "100%",
    height: height || "100%",
    position: "relative",
  },
  background: {
    position: "absolute",
    [theme.direction === "ltr" ? "left" : "right"]: "calc(50% - 50%)",
    width: "90%",
    height: "90%",
  },
  avatar: {
    position: "absolute",
    left: "0",
    width: "100%",
    height: "100%",
    top: "0",
    transform: theme?.direction === "ltr" ? "scaleX(1)" : "scaleX(-1)",
  },
  userIcon: {
    position: "absolute",
    width: "44%",
    height: "44%",
    top: "calc(50% - 22%)",
    [theme.direction === "ltr" ? "left" : "right"]: "calc(50% - 25%)",
  },
}));
