import { MeetoraProvider } from "@meetora/react/client";
import { AuthorizeByToken } from "@meetora/schema/auth/AuthorizeByToken";
import { oAuthTypeSehacity } from "@meetora/schema/auth/OAuthType";
import { FC, memo, PropsWithChildren, useEffect, useMemo, useRef } from "react";
import { useLoggedUser } from "./useLoggedUser";
import { MeetoraClient } from "./MeetoraClient";

type ProviderProps = PropsWithChildren<{
  token?: string;
}>;

const Provider: FC<ProviderProps> = ({ token, children }) => {
  const unsubscribeRef = useRef<() => void>();
  const { onSignin } = useLoggedUser(token);
  const client = MeetoraClient.getInstance();

  useMemo(() => {
    unsubscribeRef.current?.();
    unsubscribeRef.current = undefined;

    if (!token) {
      return;
    }

    unsubscribeRef.current = client.onPreAuthorize(async () => {
      const result = await client.sendMessage(AuthorizeByToken({ token, authType: oAuthTypeSehacity() }));
      if (result._name === "auth.authorization") {
        onSignin(token);
      }

      return result;
    });
  }, [token]);

  useEffect(() => {
    return () => {
      unsubscribeRef.current?.();
      unsubscribeRef.current = undefined;
    };
  }, []);

  return <MeetoraProvider client={client}>{children}</MeetoraProvider>;
};

export default memo(Provider);
