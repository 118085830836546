import { makeStyles } from "@toolkit/ui";

export const useVirtualVisitAvailableDoctorsListStyle = makeStyles()(() => ({
  emptyPageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(100% - 20px)",
    height: "100%",
    flex: 1,
    justifySelf: "center",
    alignSelf: "center",
    padding: "10px",
    marginInline: "10px",
    paddingBottom: "100px",
  },
  content: {
    flexGrow: 1,
    maxHeight: "100%",
  },
  scheduleButton: {
    height: "45px",
    paddingInline: "20px",
    borderRadius: "20px",
  },
}));
