import { CareTeamMemberType } from "@/domains/care-teams/type";
import { FC } from "react";
import { CareTeamMembersList } from "../../CareTeamMembersList/CareTeamMembersList";

type MyTeamBodyProps = {
  teamMembers: CareTeamMemberType[];
};
export const MyTeamBody: FC<MyTeamBodyProps> = props => {
  const { teamMembers } = props;
  return (
    <>
      <CareTeamMembersList teamMembers={teamMembers} />
    </>
  );
};
