import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  root: {
    width: "calc(100% - 20px)",
    height: "40px",
    marginBottom: "10px",
    marginInline: "10px",
    borderRadius: "20px",
    boxShadow: theme.mixins.shadows.sm,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
  },
  tab: {
    width: "50%",
    height: "40px",
    margin: 0,
    borderRadius: "20px",
  },
  activeTab: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary[900],
    },
  },
}));
