import { ExpandMoreIcon } from "@/components";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography } from "@toolkit/ui";
import { useStyle } from "../MyCareTeamDoctorCard/MyCareTeamDoctorCardStyle";
import { FC, ReactNode } from "react";

type ServiceActionProps = { icon: ReactNode; label: string; onExpand?: () => void; lastItem?: boolean; disabled?: boolean };
export const ServiceAction: FC<ServiceActionProps> = ({ icon, label, onExpand, lastItem, disabled }) => {
  const { classes, cx } = useStyle({ disableAction: !!disabled });
  const { t } = useTranslation("consumer");
  const handleClick = () => {
    if (disabled) return;
    onExpand?.();
  };

  return (
    <Box onClick={handleClick} className={cx(classes.serviceContainer, lastItem && classes.lastServiceContainer)}>
      <Box className={classes.serviceStart}>
        <Box className={classes.iconContainer}>{icon}</Box>
        <Typography className={classes.serviceTitle}>{t(label)}</Typography>
      </Box>
      <Box className={classes.expandIcon}>
        <ExpandMoreIcon />
      </Box>
    </Box>
  );
};
