import { UserGender } from "@health/queries/types";
import { DoctorAvailableTimeSlotsQuery, ActiveDoctorsQuery, MeComingSoonAppointmentQuery } from "../gql/queries";
import moment from "moment";
import { IAppointmentConfirmationFormValues } from "../forms/AppointmentConfirmation/AppointmentConfirmationFormSchema";
export interface IFormattedTimeSlot {
  startTime: moment.Moment;
  endTime: moment.Moment;
}

export interface IGroupedAvailableTimeSlots {
  morningSlots: IFormattedTimeSlot[];
  afternoonSlots: IFormattedTimeSlot[];
  eveningSlots: IFormattedTimeSlot[];
}
export type AvailableSlotQueryType = NonNullable<DoctorAvailableTimeSlotsQuery["availableTimeSlots"]>[0];

export type DoctorInfoType = NonNullable<ActiveDoctorsQuery["doctors"]>["edges"][0]["node"];

export interface IDoctorInfo {
  doctorId?: string;
  name?: string;
  gender?: UserGender | null;
  doctorImageUrl?: string | null;
  specialty?: string;
  languages?: string;
  yearsOfExperience?: string;
  vendorImageUrl?: string | null;
  vendorName?: string;
}

export type IGuestInfo = Omit<IAppointmentConfirmationFormValues, "showInsuranceSection">;

export type ComingSoonAppointmentDataType = NonNullable<MeComingSoonAppointmentQuery["appointments"]>["edges"][0]["node"];

export enum AppointmentHistoryTabsEnum {
  completed = "completed",
  upcoming = "upcoming",
  requested = "requested",
}
