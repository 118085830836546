/* eslint-disable no-restricted-imports */
import { useCustomForm } from "@toolkit/core";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  CareTeamsListFiltersFormSchema,
  careTeamListFiltersFormDefaultValues,
  ICareTeamsListFiltersFormValues,
} from "./CareTeamsListFiltersFormSchema";

import { Box, Button } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useStyles } from "./CareTeamsListStyle";
import { TeamNameFilterForm } from "./Filters/TeamNameFilter";
import { DoctorsFilterForm } from "./Filters/DoctorsFilter";
import { PayerFilterForm } from "./Filters/PayerFilterForm";
import { VendorFilterForm } from "./Filters/VendorFilter";

type CareTeamsListFiltersFormProps = {
  onSubmit?: (values: ICareTeamsListFiltersFormValues) => void;
};

export type CareTeamsListFiltersFormRef = {
  submit: () => void;
};

export const CareTeamsListFiltersForm: React.FC<CareTeamsListFiltersFormProps> = props => {
  const { t } = useTranslation("consumer");
  const form = useCustomForm<ICareTeamsListFiltersFormValues>({
    schema: CareTeamsListFiltersFormSchema,
    defaultValues: careTeamListFiltersFormDefaultValues,
    mode: "onChange",
  });
  const { setValue } = form;
  const onSubmit = <K extends keyof ICareTeamsListFiltersFormValues>(fieldName: K, value: ICareTeamsListFiltersFormValues[K]) => {
    setValue(
      fieldName as keyof ICareTeamsListFiltersFormValues,
      value as ICareTeamsListFiltersFormValues[keyof ICareTeamsListFiltersFormValues]
    );
    props.onSubmit?.({
      ...form.getValues(),
      [fieldName]: value,
    });
  };
  const getValue = <K extends keyof ICareTeamsListFiltersFormValues>(fieldName: K) => {
    return form.getValues()[fieldName];
  };
  const resetForm = () => {
    form.reset(careTeamListFiltersFormDefaultValues);
    props.onSubmit?.(careTeamListFiltersFormDefaultValues);
  };

  const { classes } = useStyles();
  return (
    <FormProvider {...form}>
      <form>
        <Box className={classes.container}>
          <Button className={classes.button} onClick={resetForm} variant='outlined'>
            {t("All")}
          </Button>
          <TeamNameFilterForm onSubmit={onSubmit} getValue={getValue} />
          <DoctorsFilterForm onSubmit={onSubmit} getValue={getValue} />
          <PayerFilterForm onSubmit={onSubmit} getValue={getValue} />
          <VendorFilterForm onSubmit={onSubmit} getValue={getValue} />
        </Box>
      </form>
    </FormProvider>
  );
};
