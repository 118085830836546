import usePaymentSummary from "@/domains/payment/hooks/use-payment-summary.hook";
import { useDoctorQuery, usePatientShareQuery } from "@/gql";
import { AmountSourceType, Coverage, VisitCareType, VisitStatus } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { VirtualVisitPaymentSummaryContainerData } from "./types";
import { useCreateImmediateCallVisit } from "./useCreateImmediateCallVisit";
import { PayNowDialog } from "@/domains/payment/pay-now-dialog/pay-now-dialog";
import { routesPaths } from "@/routing/routesPaths";
import queryString from "query-string";
import { merge } from "lodash";
import { routes } from "@/domains/VisitCall/routes";

export const useCallDoctorNowHook = () => {
  const { t } = useTranslation("consumer");
  const { failed, succeeded } = useAddToast();
  const { isAuthenticated } = useAuth();
  const [isCreatingVisitLoading, setIsLoading] = useState(false);

  const location: Location<VirtualVisitPaymentSummaryContainerData> = useLocation();
  const { doctorId: pathDoctorId } = queryString.parse(location.search) as { doctorId: string };

  const { data } = useDoctorQuery({
    variables: {
      doctorId: pathDoctorId || location.state?.doctor?.id || "",
    },
    skip: !!location.state?.doctor?.id || !pathDoctorId,
  });
  console.log(data, location.state?.doctor?.id, "datadata");
  const doctor = merge({}, location.state?.doctor, data?.doctor);
  const doctorId = doctor?.id;

  const { visitRef, loading, handleCreateVisitNow } = useCreateImmediateCallVisit({ doctorId: doctorId });
  const navigate = useNavigate();
  const { data: patientShareData, loading: isPatientShareLoading } = usePatientShareQuery({
    variables: {
      doctorId: doctorId!,
      visitCareType: VisitCareType.Virtual,
    },
    skip: !doctorId,
    fetchPolicy: "no-cache",
  });

  const isFullyCovered = patientShareData?.patientShare?.coverage === Coverage.FullyCovered;

  const { total } = usePaymentSummary({
    priceBeforeVat: !visitRef.current ? doctor?.onlineVisitPrice || 0 : 0,
  });

  let paymentTotal = visitRef.current?.priceAmountAfterVat || 0;

  if (!visitRef.current) {
    paymentTotal = total;
  }

  const handlePayDoctorNowToCreateCall = async () => {
    try {
      setIsLoading(true);
      const visit = await handleCreateVisitNow();
      if (!visit) {
        return;
      }

      if (isFullyCovered && visit?.status === VisitStatus.PaymentSucceeded) {
        navigate(routesPaths.home);
        succeeded(t("Visit has been initiated successfully"));
      } else {
        PayNowDialog.open({
          amountSource: AmountSourceType.Visit,
          amountSourceId: visit.id || "",
          onSuccess: () => {
            succeeded(t("Payment successful"));
          },
          onError: () => {
            failed(t("Payment failed. Please try again."));
          },
          onPending: () => {
            succeeded(t("Payment in progress please wait,you will be notified once payment is completed"));
          },
          onRefundedToWallet: () => {
            failed(t("Payment was not completed successfully. The amount has been refunded to your wallet."));
          },
        });
        localStorage.setItem("payment-call-back-source-page", routes.waiting.getRouteWithParams({ visitId: visit.id }));
      }
    } catch (e) {
      failed(t("We are unable to initiate the visit at this time. Please try again later."));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    t,
    isFullyCovered,
    visit: visitRef.current,
    doctor,
    loading: loading || isPatientShareLoading,
    isAuthenticated,
    isCreatingVisitLoading,
    paymentTotal,
    handlePayDoctorNowToCreateCall,
  };
};
