import { FC } from "react";
import { Box, Skeleton } from "@toolkit/ui";
import { useCareTeamCardStyle } from "../CareTeamCard/CareTeamCardStyle";
import { DoctorCardLayout } from "@/components";

type CareTeamDoctorCardSkeletonProps = {
  hasActions?: boolean;
};

export const CareTeamDoctorCardSkeleton: FC<CareTeamDoctorCardSkeletonProps> = props => {
  const { hasActions } = props;
  const { classes } = useCareTeamCardStyle({ hasActions: hasActions });

  return (
    <DoctorCardLayout
      doctorAvatarNode={
        <Box className={classes.doctorAvatar}>
          <Skeleton variant='circular' width={100} height={100} />
        </Box>
      }
      infoNode={
        <>
          <Skeleton variant='text' width='60%' height={30} className={classes.teamName} />
          <Skeleton variant='text' width='50%' height={25} className={classes.vendorName} />
          <Skeleton variant='text' width='50%' height={25} className={classes.vendorName} />
        </>
      }
      classes={{
        avatarsContainer: classes.avatarsContainer,
        actionsContainer: classes.actionsContainer,
        infoContainer: classes.infoContainer,
      }}
      downAvatarNode={<Skeleton variant='rectangular' width='100%' height={30} />}
      actionsNode={
        hasActions && (
          <>
            <Skeleton variant='rectangular' width={90} height={40} className={classes.registerButton} />
            <Skeleton variant='rectangular' width={120} height={40} className={classes.profileButton} />
          </>
        )
      }
    />
  );
};
