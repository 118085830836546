import { FC } from "react";
import { CareTeamMemberType } from "../../type";
import { useCareTeamMembersListStyle } from "./CareTeamMembersListStyle";
import { Box } from "@toolkit/ui";
import { CareTeamMemberCard } from "../CareTeamMemberCard/CareTeamMemberCard";

type CareTeamMembersListProps = {
  teamMembers: CareTeamMemberType[];
};
export const CareTeamMembersList: FC<CareTeamMembersListProps> = props => {
  const { teamMembers } = props;
  const { classes } = useCareTeamMembersListStyle();
  return (
    <Box className={classes.root}>
      {teamMembers.map((member, index) => (
        <CareTeamMemberCard
          classes={{
            card: classes.card,
          }}
          key={index}
          member={member}
        />
      ))}
    </Box>
  );
};
