import { useMutation } from "@apollo/client";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { ChangeEvent, FormEvent, useState } from "react";

import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  QuestionIcon,
  RoundedTextField,
  TextField,
  Typography,
  useAddToast,
  UserIcon,
  WarningIcon,
} from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { GENERAL_ERROR_MSG, IdentityTypes, isValidEmail, REQUEST_OTP_FOR_DELETE_ACCOUNT, RESET_PASS_EMAIL_ERROR_MSG } from "./constants";
import { DeletePageLayout } from "./DeletePageLayout";

interface DeleteAccountProps {}
type FormErrors = {
  identity?: string;
  reason?: string;
};

const DeleteAccount: React.FC<DeleteAccountProps> = () => {
  // Context
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const { t } = useTranslation("Consumer");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formErrors, setFormErrors] = useState<FormErrors>({
    identity: "",
    reason: "",
  });

  const [formData, setFormData] = useState({
    identity: "",
    vendor: "",
    reason: "",
    isDeleteConfirmed: false,
  });

  const [fetchRequestOtpForDeleteAccount] = useMutation(REQUEST_OTP_FOR_DELETE_ACCOUNT);
  const isDeleteButtonDisabled = isSubmitting || !formData.isDeleteConfirmed;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = value;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleToggleChecked = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      isDeleteConfirmed: !prevFormData.isDeleteConfirmed,
    }));
  };
  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const _errors = {
        identity: "",
        reason: "",
      };
      setIsSubmitting(true);
      const identity = formData.identity.trim();
      const isMobileIdentity = identity[0] === "+";
      const isEmailIdentity = !isMobileIdentity;
      const identityType = isMobileIdentity ? IdentityTypes.mobile : IdentityTypes.email;

      if (!identity) {
        _errors["identity"] = RESET_PASS_EMAIL_ERROR_MSG;
      } else if (isMobileIdentity && !isValidPhoneNumber("" + identity)) {
        _errors["identity"] = RESET_PASS_EMAIL_ERROR_MSG;
      } else if (isEmailIdentity && !isValidEmail(identity)) {
        _errors["identity"] = RESET_PASS_EMAIL_ERROR_MSG;
      }
      if (!formData.reason) {
        _errors["reason"] = "Please enter a reason";
      } else if (formData.reason.length > 2000) {
        _errors["reason"] = "Reason should not exceed 2000 characters";
      }

      setFormErrors(_errors);

      if (_errors?.identity || _errors?.reason) {
        setIsSubmitting(false);
        return;
      }
      const userMethod = isMobileIdentity ? "phoneNumber" : "email";

      const response = await fetchRequestOtpForDeleteAccount({
        variables: {
          [userMethod]: identity,
        },
      });

      const data = response?.data?.requestOtpForDeleteAccount;

      setIsSubmitting(false);
      const errors = data?.accountErrors;
      if (errors.length > 0) {
        failed(errors[0]);
        return;
      }
      navigate("/verify-delete-account", {
        state: {
          identity,
          identityType,
          reason: formData.reason,
          sessionToken: data.sessionToken,
        },
      });
    } catch (e) {
      failed(GENERAL_ERROR_MSG);
    }
  };

  return (
    <DeletePageLayout>
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          rowGap: 2,
          height: "100%",
        }}
      >
        <WarningIcon sx={{ mx: "auto", fontWeight: "bold", fontSize: 70 }} />
        <Typography fontSize={24} fontWeight={"bold"} color='warning.main' textAlign={"center"}>
          {t("Delete Your Account")}
        </Typography>
        <Typography fontSize='16' textAlign={"center"} fontWeight={"500"}>
          {t("Please enter your registered email/mobile number")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
          mt: 2,
        }}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <RoundedTextField
          name='identity'
          label='Mobile / Email'
          inputProps={{
            id: "identity",
            name: "identity",
            placeholder: "Mobile / Email",
            value: formData.identity,
            onChange: handleChange,
          }}
          InputProps={{
            startAdornment: <UserIcon sx={{ fontSize: 14 }} />,
          }}
          error={!!formErrors?.identity}
          helperText={formErrors?.identity}
        />

        <TextField
          sx={{
            "& .MuiInputBase-root,& .MuiInputBase-root:focus ,& input,& textarea": {
              backgroundColor: "#F1F2FB !important",
            },
          }}
          InputProps={{
            startAdornment: <QuestionIcon sx={{ fontSize: 14 }} />,
          }}
          name='reason'
          id='reason'
          multiline
          placeholder='Reason'
          value={formData.reason}
          rows={4}
          onChange={handleTextAreaChange}
          error={!!formErrors?.reason}
          helperText={formErrors?.reason}
        />
        <Box>
          <FormControlLabel
            label={"I confirm that I need to delete my account"}
            control={<Checkbox checked={formData.isDeleteConfirmed} onChange={handleToggleChecked} />}
          />
        </Box>
        <Button
          type='submit'
          disabled={isDeleteButtonDisabled}
          fullWidth
          className='auth-button'
          sx={{ mt: 4, mb: 1, alignSelf: "flex-end", borderRadius: 25, height: 50 }}
        >
          {isSubmitting ? <CircularProgress style={{ padding: 0 }} size={20} /> : t("Continue")}
        </Button>
      </Box>
    </DeletePageLayout>
  );
};

export default DeleteAccount;
