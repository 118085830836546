import { makeStyles } from "@toolkit/ui";

export const useCallPageActionsStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    marginTop: "60px",
    height: "max-content",
  },
  iconButton: {
    width: "60px",
    height: "60px",
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    color: theme.palette.text.primary,
    cursor: "pointer",
    marginTop: "5px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "max-content",
  },
  declineButton: {
    backgroundColor: theme.palette.error.main,
    "& svg": {
      fill: theme.palette.common.white,
      width: "30px",
      height: "30px",
    },
  },
  defaultRoot: {
    marginTop: "60px",
    gap: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  defaultButton: {
    width: "60px",
    height: "60px",
  },
  defaultActionContainer: {
    display: "flex",
    gap: "10px",
  },
  joinButton: {
    paddingInline: theme.spacing(3),
    borderRadius: "24px",
    "&& svg": {
      width: "15px",
      height: "15px",
    },
    "&& path": {
      fill: theme.palette.common.white,
    },
  },
}));
