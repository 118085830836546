import { useRelayStyleConnection } from "@toolkit/apollo";
import { useAppointmentsHistoryQuery } from "../../gql";
import { useEffect } from "react";
import { AppointmentSortingInput, AppointmentStatusHistoryFilterInput } from "@health/queries/types";
import { useOnAppointmentStatusUpdate } from "@health/sse";

interface IUseGetAppointmentHistoryListData {
  filters: AppointmentStatusHistoryFilterInput;
  sortBy: AppointmentSortingInput;
}

export const useGetAppointmentHistoryListData = (props: IUseGetAppointmentHistoryListData) => {
  const { filters, sortBy } = props;
  const {
    data: appointments,
    loading: isLoading,
    hasMore,
    handleFetchMore: fetchMoreData,
    refetch,
  } = useRelayStyleConnection({
    useQuery: useAppointmentsHistoryQuery,
    variables: {
      filter: filters,
      sortBy: sortBy,
    },
  });

  const handleRefresh = () => {
    refetch();
  };
  useEffect(() => {
    refetch({
      filter: filters,
    });
  }, [JSON.stringify(filters)]);

  useOnAppointmentStatusUpdate(({ data }) => {
    if (data.graphqlType === "Appointment" && data?.id) {
      refetch();
    }
  });

  return {
    appointments,
    isLoading,
    hasMore,
    handleRefresh,
    fetchMoreData,
  };
};
