import { Box } from "@toolkit/ui";
import { usePageTabsStyle } from "./PageTabsStyle";
import { Tab, Tabs } from "react-tabs-scrollable";
import { ExpandMoreIcon } from "@/components/Icons";
import { ReactNode } from "react";

type PageTabsProps = {
  tabs: string[] | ReactNode[];
  activeTabIndex: number;
  onTabClick: (e: React.BaseSyntheticEvent, id: number) => void;
  classes?: Partial<ReturnType<typeof usePageTabsStyle>["classes"]>;
};

export const PageTabs: React.FC<PageTabsProps> = props => {
  const { tabs, activeTabIndex, onTabClick, classes: _classes } = props;
  const { classes, cx, theme } = usePageTabsStyle({ tabsCount: tabs.length || 1 });
  const isRtl = theme.direction === "rtl";

  return (
    <Box className={cx(classes.root, _classes?.root)}>
      <Tabs
        tabsUpperContainerClassName={classes.tabsUpperContainer}
        tabsContainerClassName={classes.tabsContainer}
        tabsScrollAmount={2}
        hideNavBtns={false}
        hideNavBtnsOnMobile={false}
        navBtnContainerClassName={classes.navBtnContainer}
        isRTL={isRtl}
        activeTab={activeTabIndex}
        onTabClick={onTabClick}
        rightBtnIcon={<ExpandMoreIcon direction='right' />}
        leftBtnIcon={<ExpandMoreIcon direction='left' />}
      >
        {tabs.map((tab, index) => (
          <Tab className={cx(classes.tab, activeTabIndex === index && classes.activeTab)} key={index + tab.toString()}>
            {tab}
          </Tab>
        ))}
      </Tabs>
    </Box>
  );
};
