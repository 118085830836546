import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const ChatIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' id='icons8-chat_5_' width='29.414' height='29.636' viewBox='0 0 29.414 29.636' {...props}>
      <path
        id='Path_12801'
        data-name='Path 12801'
        d='M5.257,22.062a1.11,1.11,0,0,1-1.1-1l-.018-.184A19.923,19.923,0,0,1,4,18.805,14.822,14.822,0,0,1,18.805,4a14.654,14.654,0,0,1,5.126.92A14.811,14.811,0,0,1,28.28,7.451,15.03,15.03,0,0,1,33.367,16a1.11,1.11,0,0,1-2.178.436,12.8,12.8,0,0,0-4.334-7.278A12.6,12.6,0,0,0,23.157,7a12.441,12.441,0,0,0-4.352-.78A12.6,12.6,0,0,0,6.221,18.805a17.96,17.96,0,0,0,.124,1.85l.019.186a1.11,1.11,0,0,1-.994,1.215C5.331,22.06,5.294,22.062,5.257,22.062Z'
        transform='translate(-3.974 -4)'
        fill={fill}
      />
      <path
        id='Path_12802'
        data-name='Path 12802'
        d='M5.857,39.584a1.9,1.9,0,0,1-1.833-2.368l.008-.031,1.7-6.07a1.11,1.11,0,0,1,2.138.6l-1.544,5.5,5.5-1.544a1.11,1.11,0,0,1,.839.1,12.626,12.626,0,0,0,13.955-1.166,12.706,12.706,0,0,0,4.415-6.864,1.11,1.11,0,0,1,2.156.532A14.878,14.878,0,0,1,12,37.935l-5.64,1.582-.031.008A1.963,1.963,0,0,1,5.857,39.584Z'
        transform='translate(-3.965 -9.948)'
        fill={fill}
      />
    </SvgIcon>
  );
};
