/* eslint-disable */
/* prettier-ignore */

import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CallDoctorNowCreateMutationVariables = Types.Exact<{
  input: Types.CallDoctorNowInput;
}>;


export type CallDoctorNowCreateMutation = { __typename?: 'Mutation', callDoctorNowCreate?: { __typename?: 'VisitAction', visit?: { __typename?: 'Visit', id: string, status?: Types.VisitStatus | null, priceAmount?: number | null, priceAmountAfterVat?: number | null, vatPercentage?: number | null, meetingPlatformLinkForJoinCall?: string | null } | null, visitErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const CallDoctorNowCreateDocument = gql`
    mutation CallDoctorNowCreate($input: CallDoctorNowInput!) {
  callDoctorNowCreate(input: $input) {
    visit {
      id
      status
      priceAmount
      priceAmountAfterVat
      vatPercentage
      meetingPlatformLinkForJoinCall
    }
    visitErrors {
      code
      field
      message
    }
  }
}
    `;
export type CallDoctorNowCreateMutationFn = Apollo.MutationFunction<CallDoctorNowCreateMutation, CallDoctorNowCreateMutationVariables>;

/**
 * __useCallDoctorNowCreateMutation__
 *
 * To run a mutation, you first call `useCallDoctorNowCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallDoctorNowCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callDoctorNowCreateMutation, { data, loading, error }] = useCallDoctorNowCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallDoctorNowCreateMutation(baseOptions?: Apollo.MutationHookOptions<CallDoctorNowCreateMutation, CallDoctorNowCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallDoctorNowCreateMutation, CallDoctorNowCreateMutationVariables>(CallDoctorNowCreateDocument, options);
      }
export type CallDoctorNowCreateMutationHookResult = ReturnType<typeof useCallDoctorNowCreateMutation>;
export type CallDoctorNowCreateMutationResult = Apollo.MutationResult<CallDoctorNowCreateMutation>;
export type CallDoctorNowCreateMutationOptions = Apollo.BaseMutationOptions<CallDoctorNowCreateMutation, CallDoctorNowCreateMutationVariables>;