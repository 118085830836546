import { AvatarUserIcon, PortraitBackgroundIcon } from "@/components";
import { EmptyUserAvatarWithBorderIconProps, UserAvatarWithDefaultFillIcon } from "@/components/Icons/UserAvatarWithDefaultFillIcon";
import { useStyles } from "./CustomUserAvatarStyle";
import { Box } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";
import { uniqueId } from "lodash";

interface CustomUserAvatarsProps extends EmptyUserAvatarWithBorderIconProps {
  src?: string | null;
  width: string | number;
  height: string | number;
  id?: string;
  isLoading?: boolean;
  withoutBackground?: boolean;
  withoutDefaultIcon?: boolean;
}

export const CustomUserAvatar: FC<PropsWithChildren<CustomUserAvatarsProps>> = props => {
  const {
    src,
    width,
    height,
    isLoading,
    id = uniqueId("image-"),
    withoutBackground = false,
    withoutDefaultIcon = false,
    children,
    ...rest
  } = props;
  const { classes } = useStyles({ width, height });

  return (
    <Box className={classes.root}>
      {!withoutBackground && <PortraitBackgroundIcon className={classes.background} />}
      <UserAvatarWithDefaultFillIcon
        id={id}
        width={width}
        height={height}
        src={!isLoading ? src : undefined}
        className={classes.avatar}
        {...rest}
      />
      {!withoutDefaultIcon && !isLoading && !src && <AvatarUserIcon className={classes.userIcon} />}

      {children}
    </Box>
  );
};
