/**
 * Concatenates an array of strings into a single formatted string, handling various array lengths.
 *
 * @param array - An optional array of strings.
 * @returns A formatted string:
 *          - If the array is empty or undefined, returns an empty string.
 *          - If the array has a single element, returns that element as a string.
 *          - If the array has two elements, joins them with "and" (e.g., "item1 and item2").
 *          - If the array has more than two elements, joins all except the last with commas,
 *            and appends the last element with "and" (e.g., "item1, item2, and item3").
 */
export const displayStringOfArray = (array?: string[]) => {
  if (!array || array.length === 0) {
    return "";
  } else if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    return array.join(" and ");
  } else {
    const lastElement = array.pop();
    const formattedArray = array.join(", ");
    return `${formattedArray} and ${lastElement}`;
  }
};
