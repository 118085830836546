import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  footer: {
    justifyContent: "space-between",
  },
  button: {
    width: "fit-content",
    paddingInline: "40px",
  },
  iconContainer: {
    minWidth: "58px",
    minHeight: "58px",
    borderRadius: "50%",
    boxShadow: theme.mixins.shadows.md,
    marginInlineEnd: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "29px",
      height: "29px",
    },
  },
  infoCard: {
    marginTop: "10px",
  },
  doctorCard: {
    margin: 0,
  },
}));
