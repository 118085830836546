import { MainPageLayout, PageWithBackHeaderLayout } from "@/components";
import { routes } from "@/domains/my-wallet";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const MyWalletRoute: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={routes.root.route} element={<routes.root.component />} />
      </Route>
      <Route element={<PageWithBackHeaderLayout containerBreakpoint='sm' />}>
        <Route path={routes.transactionDetails.route} element={<routes.transactionDetails.component />} />
      </Route>
    </Routes>
  );
};
