import { makeStyles } from "@toolkit/ui";
import defaultBackground from "@/assets/Visit/defaultBackground.png";

export const useMeetoraCallPageLayoutStyle = makeStyles()(theme => ({
  content: {
    backgroundImage: `url(${defaultBackground})`,
    boxShadow: theme.mixins.shadows.sm,
    alignContent: "center",
    backgroundSize: "cover",
  },
}));
