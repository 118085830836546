import { Box, IconButton, MuiArrowBackIcon, Typography } from "@toolkit/ui";
import { useCallStyles } from "./Call.styles";
import { CallInstance } from "./Meetora";
import { useTranslation } from "@toolkit/i18n";
import PeerAvatar from "@meetora/react/ui/PeerAvatar";
import PeerTitle from "@meetora/react/ui/PeerTitle";

type WaitingScreenProps = {
  call: CallInstance;
  onClose?: () => void;
};

export const WaitingScreen = ({ call, onClose }: WaitingScreenProps) => {
  const { t } = useTranslation();
  const { classes, theme } = useCallStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {onClose && (
          <Box className={classes.header}>
            <IconButton color='inherit' onClick={onClose}>
              <MuiArrowBackIcon />
            </IconButton>
          </Box>
        )}
        <Box className={classes.body} sx={{ textAlign: "center", maxWidth: 320 }}>
          <Box py={2}>
            <PeerAvatar peer={call.peer} size={120} />
          </Box>
          <Box>
            <Typography fontSize={theme.mixins.fonts.fontSize.md}>
              <PeerTitle peer={call.peer} />
            </Typography>
            <Typography variant='subtitle1' color={theme.palette.gray[400]}>
              {t("Please wait for the call to be started.")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
