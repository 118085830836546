import { makeStyles } from "@toolkit/ui";

export const useCallDoctorAvatarStyle = makeStyles()(theme => ({
  root: {
    border: "15px solid",
    borderColor: theme.palette.warning[100],
    backgroundColor: theme.palette.warning[200],
    width: "185px",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    position: "relative",
  },
  avatar: {
    position: "absolute",
    width: "calc(100% - 30px)",
    height: "calc(100% - 30px)",
    boxShadow: "none",
    justifyContent: "center",
    "& .MuiAvatar-root": {
      width: "100px",
      height: "100px",
    },
  },
  pulse: {
    border: "15px solid",
    borderRadius: "50%",
    backgroundColor: theme.palette.warning[300],
    borderColor: theme.palette.warning[200],
    position: "absolute",
    width: "calc(100% + 30px)",
    height: "calc(100% + 30px)",
    transform: "scale(1)",
    animation: "pulse-doctor-call 1.8s infinite",
    "@keyframes pulse-doctor-call": {
      "0%": {
        transform: "scale(0)",
        backgroundColor: theme.palette.warning.main,
        borderColor: theme.palette.warning[800],
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  },
  declineCallIcon: {
    width: "165px",
    height: "165px",
    backgroundColor: theme.palette.error.main,
    "& svg": {
      fill: theme.palette.common.white,
      width: "60px",
      height: "60px",
    },
  },
}));
