import { makeStyles } from "@toolkit/ui";

export const useCareTeamsDoctorTabsStyle = makeStyles()(theme => ({
  root: {
    marginTop: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    height: "100%",
    flex: 1,
  },
  headerRoot: {
    height: "40px",
    width: "100%",
    borderBottomColor: theme.palette.divider,
    backgroundColor: "inherit",
  },
  tabBodyContainer: {
    width: "100%",
    height: "100%",
    flex: 1,
    padding: "20px",
    textAlign: "start",
  },
}));
