import { Box, CircularProgress, useMediaQuery } from "@toolkit/ui";
import { useStyles } from "./ComingSoonCardStyle";
import { useComingSoonCard } from "./useComingSoonCard";
import { BookAnAppointmentCard } from "./BookAnAppointmentCard";
import { BookAnAppointmentCardSm } from "./ComingSoonCardSm";
import { BookAnAppointmentCardLg } from "./ComingSoonCardLg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { AppointmentHistoryTabsEnum } from "../../types/types";

type ComingSoonCardProps = {
  className?: string;
};

export const ComingSoonCard: React.FC<ComingSoonCardProps> = props => {
  const { className } = props;
  const { classes, cx, theme } = useStyles();
  const { appointment, loading: isLoading } = useComingSoonCard();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const handleNavigateToUpcomingAppointment = () => {
    navigate(routes?.myAppointments?.getRouteWithParams({ tab: AppointmentHistoryTabsEnum.upcoming }));
  };
  return (
    <>
      {isLoading && (
        <Box className={cx(className, classes.root)}>
          {isLoading && (
            <CircularProgress
              size={36}
              sx={{
                color: theme.palette.common.white,
              }}
            />
          )}
        </Box>
      )}
      {!isLoading && !appointment?.id && <BookAnAppointmentCard className={className} />}
      {!isLoading &&
        appointment?.id &&
        (isSmall ? (
          <BookAnAppointmentCardSm onClick={handleNavigateToUpcomingAppointment} appointment={appointment} className={className} />
        ) : (
          <BookAnAppointmentCardLg onClick={handleNavigateToUpcomingAppointment} appointment={appointment} className={className} />
        ))}
    </>
  );
};
