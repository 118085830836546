import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.common.white,
    borderRadius: 15,
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, #712661)`,
    height: 151,
    padding: 16,
    cursor: "pointer",
    boxShadow: theme.mixins.shadows.md,
    [theme.breakpoints.up("sm")]: {
      padding: 15,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      height: 80,
      minWidth: 240,

      ".card__container": {
        gap: 10,
      },
      ".card__startIcon": {
        "& svg": {
          width: "30px",
          height: "30px",
        },
        paddingInlineStart: 0,
      },
      ".card__subtitle": {
        fontSize: theme.mixins.fonts.fontSize.xxs,
      },
      ".card__title": {
        fontSize: theme.mixins.fonts.fontSize.md,
        fontWeight: theme.mixins.fonts.fontWeight.medium,
      },
      ".card__vendorTitle": {
        fontSize: theme.mixins.fonts.fontSize.xs,
      },
    },
  },
  emptyCard__root: {
    cursor: "pointer",
  },
  iconWrapper: {
    "& svg": {
      width: 50,
      height: 50,
    },
    paddingInlineStart: 2,
  },
  textWrapper: {
    textAlign: "start",
  },
  typographyTitle: {
    lineHeight: 1,
    fontSize: theme?.mixins.fonts.fontSize.xxl,
  },
  typographySubtitle: {
    lineHeight: 2,
  },
}));
