import { Box, IconButton, Typography, useMediaQuery } from "@toolkit/ui";
import React from "react";
import { useStyle } from "./HealthServicesSectionStyle";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import {
  HealthServiceBookAnAppointmentIcon,
  HealthServiceCallDoctorNowIcon,
  HealthServiceConnectToACareTeamIcon,
  HealthServiceJoinASessionIcon,
  HealthServiceKnowledgeHubIcon,
  HealthServiceOrderMedicationIcon,
} from "@/components";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";

export const HealthServicesSection: React.FC = () => {
  const { classes, cx, theme } = useStyle();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { classes: commonClasses } = useCommonSectionsStyle();
  const navigate = useNavigate();
  const handleServiceClick = (service?: keyof typeof routesPaths) => {
    return () => service && navigate(routesPaths[service]);
  };

  const { t } = useTranslation("consumer");
  return (
    <Box className={cx(commonClasses.root, classes.root, "section__root", "health-services")} sx={{}}>
      <Box className={cx(commonClasses.container, classes.container)}>
        <Typography className={classes.title}>{t("Your health SuperApp")}</Typography>
        <Typography className={classes.subTitle}>
          {t("Lorem Ipsum is simply dummy text of the printing and typesetting industry")}
        </Typography>
        <Box className={classes.servicesContainer}>
          <ServiceButton
            handleServiceClick={handleServiceClick("virtualVisit")}
            icon={<HealthServiceCallDoctorNowIcon variant={isSmallScreen ? "fill" : "gradient"} fill={theme.palette.primary.main} />}
            title={t("Call a doctor now")}
          />
          <ServiceButton
            handleServiceClick={handleServiceClick("careTeam")}
            icon={<HealthServiceConnectToACareTeamIcon variant={isSmallScreen ? "fill" : "gradient"} fill={theme.palette.secondary.main} />}
            title={t("Connect to a care team")}
          />
          <ServiceButton
            handleServiceClick={handleServiceClick("appointments")}
            icon={<HealthServiceBookAnAppointmentIcon variant={isSmallScreen ? "fill" : "gradient"} fill={theme.palette.primary.main} />}
            title={t("Book an appointment")}
          />
          <ServiceButton
            disabled
            icon={<HealthServiceOrderMedicationIcon variant={isSmallScreen ? "fill" : "gradient"} fill={theme.palette.secondary.main} />}
            title={t("Order medication")}
          />
          {isSmallScreen && (
            <ServiceButton
              disabled
              icon={<HealthServiceKnowledgeHubIcon variant={isSmallScreen ? "fill" : "gradient"} fill={theme.palette.primary.main} />}
              title={t("Knowledge hub")}
            />
          )}
          {!isSmallScreen && (
            <ServiceButton
              disabled
              icon={<HealthServiceJoinASessionIcon variant={isSmallScreen ? "fill" : "gradient"} fill={theme.palette.secondary.main} />}
              title={t("Join a session")}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

type ServiceButtonProps = {
  icon: React.ReactNode;
  title: string;
  disabled?: boolean;
  handleServiceClick?: () => void;
};

const ServiceButton: React.FC<ServiceButtonProps> = props => {
  const { classes, cx } = useStyle();
  const { icon, title, disabled, handleServiceClick } = props;
  return (
    <Box className={cx(classes?.serviceButtonContainer, "service-button-container")}>
      <IconButton onClick={handleServiceClick} disabled={disabled} className={cx(classes.serviceButton, "service-button")}>
        {icon}
      </IconButton>
      <Typography className={cx(classes.serviceLabel, "service-label")}>{title}</Typography>
    </Box>
  );
};
