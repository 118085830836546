import _ from "lodash";
import moment from "moment-timezone";
import { DoctorInfoType, IDoctorInfo, IFormattedTimeSlot, IGroupedAvailableTimeSlots } from "../types/types";
import { formatUserNameFull } from "@toolkit/core";
import { AppointmentType, AppointmentTypeEnum, TimeSlot } from "@health/queries/types";
import { ProfileDataType } from "@/domains/doctor/type";
import { InPersonIcon, VideoCallIcon } from "@/components";
import { AtHomeIcon } from "@toolkit/ui";

type visitPriceProps = {
  type?: AppointmentType | AppointmentTypeEnum | null;
  onlineVisitPrice: number;
  onsiteVisitPrice: number;
  atHomeVisitPrice: number;
};

export const formatQueryTimeSlots = (timeSlots?: (TimeSlot | null)[] | null): IFormattedTimeSlot[] => {
  if (!timeSlots) return [];
  return timeSlots.map(slot => ({
    startTime: moment(slot?.startTime),
    endTime: moment(slot?.endTime),
  }));
};

export const formatQueryStringSlots = (timeSlots?: (string | null)[] | null): IFormattedTimeSlot[] => {
  if (!timeSlots) return [];
  return timeSlots.map(slot => {
    const parsedSlot: TimeSlot = safeJsonParseWithDefault(slot || "", {});

    return {
      startTime: moment(parsedSlot.startTime),
      endTime: moment(parsedSlot.endTime),
    };
  });
};

export const getTimeOfDay = (hour: number): string => {
  if (hour < 12) return "morning";
  if (hour < 18) return "afternoon";
  return "evening";
};

export const groupTimeSlotsByTimeOfDay = (timeSlots: IFormattedTimeSlot[]): IGroupedAvailableTimeSlots => {
  const groupedTimeSlots = _.groupBy(timeSlots, slot => {
    const hour = moment(slot.startTime).hour();
    return getTimeOfDay(hour);
  });
  return {
    morningSlots: groupedTimeSlots.morning || [],
    afternoonSlots: groupedTimeSlots.afternoon || [],
    eveningSlots: groupedTimeSlots.evening || [],
  };
};

export const getDoctorInfo = (doctor: DoctorInfoType | ProfileDataType): IDoctorInfo => {
  return {
    doctorId: doctor?.id,
    name: formatUserNameFull(doctor?.user),
    doctorImageUrl: doctor?.user?.photo || "",
    gender: doctor?.user?.gender,
    specialty: doctor?.specializations?.map(specialization => specialization?.display).join(", ") || "",
    yearsOfExperience: doctor?.yearsOfExperience ? `${doctor?.yearsOfExperience} Years Experience` : undefined,
    vendorName: doctor?.vendor?.name || "",
    vendorImageUrl: doctor?.vendor?.logo || "",
    languages: doctor?.languages?.map(lan => lan?.display)?.join(", ") || "",
  };
};

export function safeJsonParseWithDefault<T>(jsonString: string, defaultValue: T): T {
  try {
    const parsedJson = JSON.parse(jsonString);
    return parsedJson !== null && typeof parsedJson === "object" ? parsedJson : defaultValue;
  } catch (error) {
    return defaultValue;
  }
}

export const getVisitPrice = ({ type, onlineVisitPrice, onsiteVisitPrice, atHomeVisitPrice }: visitPriceProps) => {
  switch (type) {
    case AppointmentTypeEnum.Online:
      return onlineVisitPrice || 0;
    case AppointmentTypeEnum.Onsite:
      return onsiteVisitPrice || 0;
    case AppointmentTypeEnum.AtHome:
      return atHomeVisitPrice || 0;
    default:
      return 0;
  }
};

export const getRemainingTimeText = (momentDate: Date) => {
  const currentDate = moment();

  const durationDifference = moment.duration(moment(momentDate).diff(currentDate));
  const isMinus = durationDifference.hours() < 0 || durationDifference.minutes() < 0 || durationDifference.seconds() < 0;

  const remainingHours = String(Math.abs(durationDifference.hours())).padStart(2, "0");
  const remainingMinutes = String(Math.abs(durationDifference.minutes())).padStart(2, "0");
  const remainingSeconds = String(Math.abs(durationDifference.seconds())).padStart(2, "0");

  const text = `${remainingHours}:${remainingMinutes}:${remainingSeconds}`;

  return isMinus ? "00:00:00" : text;
};

export const AppointmentTypeIconMap = {
  [AppointmentType.Online]: VideoCallIcon,
  [AppointmentType.AtHome]: AtHomeIcon,
  [AppointmentType.Onsite]: InPersonIcon,
} as const;
