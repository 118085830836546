import {
  careTeamListFiltersFormDefaultValues,
  CareTeamsListFiltersFormSchema,
  ICareTeamsListFiltersFormValues,
} from "../CareTeamsListFiltersFormSchema";
import { GenericFilterForm } from "@/components/filter/GenericFilterForm";
import { useTranslation } from "@toolkit/i18n";
import { AppointmentFilterFormProps } from "../types";
import { PayersAutocomplete } from "@health/autocompletes";

export const PayerFilterForm: React.FC<AppointmentFilterFormProps> = ({ getValue, onSubmit }) => {
  const { t } = useTranslation("consumer");
  return (
    <GenericFilterForm<ICareTeamsListFiltersFormValues>
      schema={CareTeamsListFiltersFormSchema}
      onSubmit={onSubmit}
      getValue={getValue}
      formFieldName='payer'
      dialogTitle={t("Payer")}
      formLabel={t("Payer")}
      render={name => <PayersAutocomplete name={name} />}
      defaultValues={careTeamListFiltersFormDefaultValues}
    />
  );
};
