import { makeStyles } from "@toolkit/ui";

export const useDoctorProfileTabsStyle = makeStyles()(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    height: "100%",
    flex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsHeaderContainer: {
    height: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider,
  },
  tabBodyContainer: {
    width: "100%",
    height: "100%",
    flex: 1,
    padding: "20px",
    textAlign: "start",
  },
  tab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "inherit",
    borderRadius: "0px",
    paddingBlock: "2.5px",
    paddingInline: "7px",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    color: theme.palette.stale[800],
    cursor: "pointer",
  },
  activeTab: {
    color: theme.palette.primary.main,
    borderBottom: "2px solid",
    borderColor: theme.palette.primary.main,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  titleText: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginTop: "20px",
  },
  multilineText: {
    whiteSpace: "pre-line",
  },
  qualificationContainer: {
    marginTop: "20px",
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  qualificationText: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  dayContainer: {
    display: "flex",
    alignItems: "start",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  shift: {
    display: "flex",
    alignItems: "start",
    marginTop: "10px",
  },
  shiftContainer: {},
  dayTitle: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    lineHeight: 2.55,
  },
  shiftTime: {
    minWidth: "70px",
  },
}));
