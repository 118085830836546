import { useOpenState } from "@toolkit/core";
import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import PaymentFormWebView, { PaymentFormWebViewProps } from "../payment-web-view/payment-web-view.component";

export const PayNowDialog: FC<PaymentFormWebViewProps> & {
  open: (payload: PaymentFormWebViewProps) => void;
  close: () => void;
} = props => {
  const [propsFromOpenFn, setPropsFromOpenFn] = useState<PaymentFormWebViewProps | null>(null);
  const { open: isOpen, handleOpen, handleClose } = useOpenState();
  PayNowDialog.open = payload => {
    setPropsFromOpenFn(payload);
    console.log("Open Called");
    handleOpen();
  };
  PayNowDialog.close = () => {
    setPropsFromOpenFn(null);
    handleClose();
  };
  useEffect(() => {
    localStorage.setItem("payment-call-back-source-page", location.href);
  }, []);
  return (
    <CustomDialog type='bottomSheet' open={isOpen} handleClose={handleClose}>
      <PaymentFormWebView {...props} {...propsFromOpenFn} />
    </CustomDialog>
  );
};

PayNowDialog.close = () => {};
PayNowDialog.open = () => {};
