import { MyWalletContainer, TransactionDetailsContainer } from "./containers";

export const MyWalletRoute = "/my-wallet";

export const routes = {
  root: {
    route: "/",
    component: () => <MyWalletContainer />,
    get absRoute() {
      return `${MyWalletRoute}${this.route}`;
    },
  },
  transactionDetails: {
    route: "transaction-details",
    component: () => <TransactionDetailsContainer />,
    get absRoute() {
      return `${MyWalletRoute}/${this.route}`;
    },
    getRouteWithParams({ transactionId }: { transactionId: string }) {
      return `${this.absRoute}?transactionId=${transactionId}`;
    },
  },
};
