import { useNetwork } from "@toolkit/core";
import { useCallback, useEffect, useRef, useState } from "react";

type QueryRetryParams = {
  query: (() => void) | undefined;
  timeout: number;
  enabled: boolean;
  attempts: number;
};

export function useQueryRetry({ query, timeout, enabled, attempts }: QueryRetryParams) {
  const attemptsRef = useRef(0);
  const isConnected = useNetwork();
  const [isRetrying, setIsRetrying] = useState(true);
  const handleRetry = useCallback(async () => {
    try {
      attemptsRef.current++;
      setIsRetrying(true);
      await query?.();
    } finally {
      setIsRetrying(false);
    }
  }, [query]);

  useEffect(() => {
    if (!enabled || !query || !isConnected || attemptsRef.current >= attempts) {
      setIsRetrying(false);
      return;
    }

    setIsRetrying(true);
    const timeoutId = setTimeout(handleRetry, timeout);
    return () => {
      setIsRetrying(false);
      clearTimeout(timeoutId);
    };
  }, [handleRetry, isConnected, enabled, query, timeout, attempts]);

  return { isRetrying, attemptsRef };
}
