import { MeetoraCallPageLayout } from "@/domains/VisitCall/components/MeetoraCallPageLayout/MeetoraCallPageLayout";
import { routes } from "@/domains/VisitCall/routes";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const VisitCallRoutes: FC = () => {
  return (
    <Routes>
      <Route path={routes.root.route} element={<routes.root.component />} />
      <Route element={<MeetoraCallPageLayout containerBreakpoint='sm' />}>
        <Route path={routes.waiting.route} element={<routes.waiting.component />} />
      </Route>
    </Routes>
  );
};
