import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  pageContent: {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
  },
  generalInfoContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: "10px",
    textAlign: "start",
    width: "100%",
    paddingBottom: 0,
  },
  doctorName: {
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
    width: "100%",
  },
  bookAppointmentContainer: {
    padding: "10px",
    textAlign: "start",
    width: "100%",
  },
  bookAppointmentTitle: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
    width: "100%",
  },
  appointmentActionsBox: {
    padding: "13px",
  },
  doctorTabContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));
