import { Badge, Box, Button, IconButton, IoHealthLogo, useMediaQuery } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { sideMenuDrawerOpenStateVar } from "../SideMenuDrawer/sideMenuDrawerOpenStateVar";
import useStyles from "./PageHeader.styles";
import { CartIcon, MenuIcon, NotificationIcon, ChatHeaderIcon, AvatarUserIcon } from "../Icons";
import { Link, useNavigate } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";
import { useGetNotificationsCount } from "./useGetNotificationsCount";
import { HeaderUserAvatar } from "../HeaderUserAvatar/HeaderUserAvatar";
import { HeaderSearch } from "../HeaderSearch/HeaderSearch";
import { useAuth } from "@/providers/auth";
import { useTranslation } from "@toolkit/i18n";

type PageHeaderProps = {
  hasBlob?: boolean;
};

export const PageHeader: FC<PageHeaderProps> = props => {
  const { hasBlob } = props;

  const { classes, theme, cx } = useStyles({ hasBlob });

  const { t } = useTranslation("consumer");
  const media = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuthenticated, askToLogin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!media && isAuthenticated) {
      sideMenuDrawerOpenStateVar(true);
    }
  }, [media, isAuthenticated]);
  const { unreadNotificationsCount } = useGetNotificationsCount();

  const handleNotificationClick = () => {
    navigate(routesPaths.notifications);
  };
  return (
    <Box className={cx(classes.root)}>
      <Box className={cx(classes.container)}>
        <Box className={classes.startItems}>
          <IconButton onClick={() => sideMenuDrawerOpenStateVar(true)} className={cx(classes.iconButton, classes.MenuIconButton)}>
            <MenuIcon />
          </IconButton>
          <Link to={routesPaths.home} className={classes.logoLink}>
            <IoHealthLogo className={classes.logo} />
          </Link>
          <HeaderSearch
            classes={{
              iconButton: classes.iconButton,
            }}
          />
        </Box>
        <Box className={classes.endItems}>
          {isAuthenticated && (
            <>
              <IconButton className={classes.iconButton}>
                <CartIcon />
              </IconButton>
              <IconButton onClick={handleNotificationClick} className={classes.iconButton}>
                <Badge badgeContent={unreadNotificationsCount} color='secondary'>
                  <NotificationIcon />
                </Badge>
              </IconButton>
              <IconButton className={classes.iconButton}>
                <ChatHeaderIcon />
              </IconButton>
            </>
          )}
          {!media && <HeaderUserAvatar />}
          {!isAuthenticated && (
            <Button className={classes.loginButton} startIcon={<AvatarUserIcon />} onClick={askToLogin}>
              {t("Sign in")}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
