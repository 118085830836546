import { DoctorRegisterIcon } from "@/components/Icons/DoctorRegisterIcon";
import { Box, Card, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useCareTeamCompletionCardStyles } from "./CareTeamCompletionCardStyle";
import { Trans, useTranslation } from "@toolkit/i18n";

type CareTeamCompletionCardProps = {
  teamName?: string | null;
};

export const CareTeamCompletionCard: FC<CareTeamCompletionCardProps> = props => {
  const { classes } = useCareTeamCompletionCardStyles();
  const { t } = useTranslation("consumer");
  return (
    <Card className={classes.root}>
      <Box className={classes.iconContainer}>
        <DoctorRegisterIcon />
      </Box>
      <Typography className={classes.title}>
        <Trans
          t={t}
          i18nKey='You are now registered with <bold>{{teamName}}</bold> Team under your primary care program'
          values={{ teamName: props.teamName }}
          components={{ bold: <Typography className={classes.teamName} /> }}
        />
      </Typography>
    </Card>
  );
};
