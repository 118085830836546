import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useVisitNewCallStartMutation } from "../../gql/mutations";

export const RejoinCallButton: FC<{ visitId: string; isDisabled?: boolean; className?: string }> = ({ visitId, isDisabled, className }) => {
  const { t } = useTranslation("provider");
  const { failed } = useAddToast();
  const [rejoinCallMutation, { loading }] = useVisitNewCallStartMutation();

  const handleJoinCall = () => {
    rejoinCallMutation({
      variables: { visitId: visitId! },
      onCompleted: request => {
        const visitResponse = request?.visitNewCallStart;
        if (visitResponse?.visitErrors?.length !== 0) {
          failed(formatMessageErrors(visitResponse?.visitErrors));
        }
      },
      onError: ({ graphQLErrors }) => {
        failed(formatGraphQLError(graphQLErrors));
      },
    });
  };

  return (
    <Button variant='outlined' className={className} disabled={loading || isDisabled} onClick={handleJoinCall}>
      {t("Rejoin")}
    </Button>
  );
};
