import React from "react";
import { List, Divider } from "@toolkit/ui";
import { getMenuItems, MenuItem } from "./menuItems"; // Assuming you have an interface like this
import { MenuSection } from "./MenuSection";
import { useMenuMenuStyles } from "./Menu.styles";
import { useGetWalletBalanceQuery } from "@/gql";
import { formatCurrency } from "@/utils";
import { i18n } from "@toolkit/i18n";
import { useAuth } from "@/providers/auth";

interface MenuListProps {
  getTargetMenuItem?: (menu: ReturnType<typeof getMenuItems>) => MenuItem[][];
}

export const MenuList: React.FC<MenuListProps> = props => {
  const { getTargetMenuItem } = props;
  const { isAuthenticated } = useAuth();
  const { data } = useGetWalletBalanceQuery({
    fetchPolicy: "cache-and-network",
  });
  const wallet = data?.me?.patient?.wallet;
  const balance = formatCurrency(Number(wallet?.balance), String(wallet?.currency));
  const menu = getTargetMenuItem
    ? getTargetMenuItem(getMenuItems(balance, i18n, isAuthenticated))
    : getMenuItems(balance, i18n, isAuthenticated);
  const { classes } = useMenuMenuStyles();

  return (
    <List>
      {menu.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          <MenuSection items={section} sectionIndex={sectionIndex} classes={classes} />
          {sectionIndex !== menu?.length - 1 && <Divider className={classes.divider} />}
        </React.Fragment>
      ))}
    </List>
  );
};
