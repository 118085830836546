import { makeStyles } from "@toolkit/ui";

export const useConnectionStateViewStyles = makeStyles()(() => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: "999",
    backgroundColor: "#1e1e1e",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    opacity: 0.8,
    padding: 5,
    gap: 2,
  },
}));
