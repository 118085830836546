import { Box } from "@toolkit/ui";
import { FC, useState } from "react";
import { useDoctorProfileTabsStyle } from "./DoctorProfileTabsStyles";
import { ProfileDataType } from "../../type";
import { tabs } from "./tabs";

type DoctorProfileTabsProps = {
  doctorInfo?: ProfileDataType;
};

export const DoctorProfileTabs: FC<DoctorProfileTabsProps> = props => {
  const { doctorInfo } = props;
  const { classes, cx } = useDoctorProfileTabsStyle();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const CurrentTab = tabs[selectedTabIndex]?.component;

  const handleTabClick = (index: number) => {
    setSelectedTabIndex(index);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.tabsHeaderContainer}>
        {tabs.map((tab, index) => (
          <Box
            key={index}
            className={cx(classes.tab, selectedTabIndex === index && classes.activeTab)}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </Box>
        ))}
      </Box>
      <Box className={classes.tabBodyContainer}>
        <CurrentTab doctorInfo={doctorInfo!} />
      </Box>
    </Box>
  );
};
