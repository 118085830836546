import { Box, Typography, useMediaQuery } from "@toolkit/ui";
import { useStyle } from "./UserProfileStyle";
import { useCommonSectionsStyle } from "../../Styles/CommonSectionsStyle";
import { PatientAvatarWithUploadAndDependents } from "@/domains/patient/components";
import { useMeProfileInfoQuery } from "@/domains/patient/gql";

export const UserProfileSection: React.FC = () => {
  const { classes, theme, cx } = useStyle();
  const { classes: commonClasses } = useCommonSectionsStyle();

  const { data } = useMeProfileInfoQuery({
    fetchPolicy: "cache-first",
  });
  const name = data?.me?.fullName;
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box className={cx(commonClasses.root, classes.root, "section__root", "user-profile")}>
      <Box className={cx(commonClasses.container, classes.container)}>
        <Box width='150px' height='150px'>
          {isSmall && <PatientAvatarWithUploadAndDependents avatarId={"home-page-avatar"} />}
        </Box>
        <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.medium}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
