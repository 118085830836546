import { Box, Button, CircularProgress } from "@toolkit/ui";
import useStyles from "./Profile.styles";
import { CustomUserAvatar } from "@/components";
import { useMeProfileInfoQuery } from "@/domains/patient/gql";
import { Link } from "react-router-dom";
import { useTranslation } from "@toolkit/i18n";

export const Profile: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation("consumer");

  const { data, loading } = useMeProfileInfoQuery({
    fetchPolicy: "cache-first",
  });

  const me = data?.me;
  return (
    <Box className={classes.container}>
      {loading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <>
          <CustomUserAvatar key={"image-sidebar-image"} id='image-sidebar-image' src={me?.photo} width={80} height={80} />
          <Box>
            <Button className={classes.button} endIcon={<Box className={classes.buttonIcon}></Box>}>
              {me?.fullName}
            </Button>
            {me?.dependents?.length && (
              <Link className={classes.dependentLink} to='#'>
                {t("{{dependentCount}} dependents", { dependentCount: me?.dependents?.length })}
              </Link>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
