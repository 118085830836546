import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(theme => ({
  transactionCardWrapper: {
    "& svg": {
      width: 29,
      height: 26,
    },
    paddingInlineStart: 2,
    display: "flex",
  },
  iconButton: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.md,
    borderRadius: 40,
    padding: 15,
    borderWidth: 0,
  },
  text: {
    textAlign: "start",
  },
  operationType: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  content: {
    color: theme.palette.primary.main,
    borderRadius: 15,
    background: theme.palette.common.white,
    padding: 16,
    margin: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
}));
