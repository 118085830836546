import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

interface CallIconProps extends SvgIconProps {
  rotation?: number; // Add a rotation prop
}

export const CallIcon: React.FC<CallIconProps> = ({ rotation = 0, ...props }) => {
  return (
    <SvgIcon width='17.118' height='17.119' viewBox='0 0 17.118 17.119' style={{ transform: `rotate(${rotation}deg)` }} {...props}>
      <path
        id='icons8-call'
        d='M10.037,16.82a.956.956,0,0,0-.005-.974l-.907-1.523a.953.953,0,0,1,.065-1.079,24.776,24.776,0,0,1,1.889-2.167,24.776,24.776,0,0,1,2.167-1.889.953.953,0,0,1,1.079-.065l1.523.907a.96.96,0,0,0,.977,0L20.642,7.8a.963.963,0,0,0,.449-1.063A3.17,3.17,0,0,0,20.2,5.257C18.48,3.535,15.575,2.62,9.1,9.1s-5.563,9.382-3.84,11.1a3.17,3.17,0,0,0,1.482.889A.959.959,0,0,0,7.8,20.643Z'
        transform='translate(-4 -3.999)'
        fill='#25287f'
      />
    </SvgIcon>
  );
};
