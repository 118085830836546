import { gql } from "@apollo/client";

export const GENERAL_FORM_ERROR_MSG = "Please ensure you've correctly entered your data." as const;

export const RESET_PASS_EMAIL_ERROR_MSG = "The email/mobile number entered is incorrect" as const;
export const RESET_PASS_VENDOR_ERROR_MSG = "The vendor is not selected" as const;
export const GENERAL_ERROR_MSG = "Something went wrong" as const;

export const IdentityTypes = {
  mobile: "mobile",
  email: "email",
};

export const REQUEST_OTP_FOR_DELETE_ACCOUNT = gql`
  mutation requestOtpForDeleteAccount($phoneNumber: String, $email: String) {
    requestOtpForDeleteAccount(phoneNumber: $phoneNumber, email: $email) {
      sessionToken
      accountErrors {
        message
      }
    }
  }
`;

export const isValidEmail = (email: string) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const DELETE_CUSTOMER_BY_EMAIL = gql`
  mutation customerDelete($identity: String!, $sessionToken: String!, $reason: String!) {
    customerDelete(input: { email: $identity, deleteReason: $reason, emailVerificationToken: $sessionToken }) {
      accountErrors {
        code
        message
        field
      }
    }
  }
`;

export const DELETE_CUSTOMER_BY_MOBILE = gql`
  mutation customerDelete($identity: String!, $sessionToken: String!, $reason: String!) {
    customerDelete(input: { mobile: $identity, deleteReason: $reason, mobileVerificationToken: $sessionToken }) {
      accountErrors {
        code
        message
        field
      }
    }
  }
`;
export const RESEND_OTP_FOR_FORGET_PASSWORD_IN_SECOND = 30;
export const CONFIRM_VERIFICATION = gql`
  mutation ConfirmVerification($sessionToken: String!, $verificationCode: String!, $email: String, $phoneNumber: String) {
    confirmVerification(sessionToken: $sessionToken, verificationCode: $verificationCode, email: $email, phoneNumber: $phoneNumber) {
      accountErrors {
        message
      }
      success
    }
  }
`;
