import { Box, DocumentIcon, format, Grid, Typography, UserIcon } from "@toolkit/ui";
import { useStyles } from "./ComingSoonCardStyle";
import { i18n, useTranslation } from "@toolkit/i18n";
import { FC, useEffect, useRef, useState } from "react";
import { ComingSoonAppointmentDataType } from "../../types/types";

import { getRemainingTimeText } from "../../utils";
import { truncate } from "lodash";
import { appointmentTypeOptionsMap } from "@health/enum-options";

type BookAnAppointmentCardProps = {
  className?: string;
  appointment?: ComingSoonAppointmentDataType;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const BookAnAppointmentCardLg: FC<BookAnAppointmentCardProps> = props => {
  const { className, appointment, onClick } = props;

  const eventDate = appointment?.confirmedStartTime;
  const [remainingTimeText, setRemainingTimeText] = useState(getRemainingTimeText(eventDate) ?? "00:00:00");
  const interval = useRef<NodeJS.Timeout>();

  const { classes, cx, theme } = useStyles();
  const { t } = useTranslation("consumer");
  const doctorName = appointment?.doctor?.user?.fullName;

  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      setRemainingTimeText(getRemainingTimeText(eventDate));
    }, 1000);
  }, [eventDate]);

  return (
    <Box role='button' onClick={onClick} className={cx(className, classes.root, "root__lg")}>
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs={2} container alignItems='center' justifyContent='center' flexDirection='column'>
          <Typography
            variant='subtitle1'
            fontSize={theme.mixins.fonts.fontSize.x48}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
            sx={{ lineHeight: 1 }}
          >
            {format(appointment?.confirmedStartTime, "DD", i18n.language)}
          </Typography>
          <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.xxl} fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {format(appointment?.confirmedStartTime, "MMM", i18n.language)}
          </Typography>
          <Typography variant='subtitle1' fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {format(appointment?.confirmedStartTime, "hh:mm A", i18n.language)}
          </Typography>
        </Grid>
        <Grid item xs={5} sx={{ textAlign: "start" }} container gap={1.5}>
          <Grid item xs={12} gap={1} container sx={{ width: "max-content" }}>
            <Grid item>
              <UserIcon fill={theme.palette.common.white} sx={{ fontSize: theme.mixins.fonts.fontSize.sm }} />
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle1'
                fontSize={theme.mixins.fonts.fontSize.md}
                fontWeight={theme.mixins.fonts.fontWeight.medium}
                sx={{ lineHeight: 1.5 }}
              >
                {t("Doctor Name")}
              </Typography>
              <Typography
                variant='subtitle1'
                fontSize={theme.mixins.fonts.fontSize.sm}
                fontWeight={theme.mixins.fonts.fontWeight.regular}
                sx={{ lineHeight: 1 }}
              >
                {truncate(doctorName || "", { length: 25 })}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} container gap={1} mt={1.5}>
            <Grid item>
              <DocumentIcon sx={{ fontSize: theme.mixins.fonts.fontSize.sm }} fill={theme.palette.common.white} />
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle1'
                fontSize={theme.mixins.fonts.fontSize.md}
                fontWeight={theme.mixins.fonts.fontWeight.medium}
                sx={{ lineHeight: 1 }}
                mb={0.5}
              >
                {t("Appointment Type")}
              </Typography>
              <Typography
                variant='subtitle1'
                fontSize={theme.mixins.fonts.fontSize.sm}
                fontWeight={theme.mixins.fonts.fontWeight.regular}
                sx={{ lineHeight: 1.5 }}
              >
                {appointment && appointment.type && appointmentTypeOptionsMap[appointment.type].label}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} container justifyContent='center'>
          <Typography
            variant='subtitle1'
            fontSize={theme.mixins.fonts.fontSize.xxl}
            fontWeight={theme.mixins.fonts.fontWeight.semiBold}
            sx={{ lineHeight: 1 }}
          >
            {remainingTimeText}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
