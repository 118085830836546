import { Box, Card, CardContent } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";
import "./style.css";
export const DeletePageLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      className='app'
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          width: "min( 500px, calc( 100vw - 20px ) )",
          height: "min( 650px, calc( 100vh - 40px ) )",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardContent sx={{ maxWidth: 400 }}>{children}</CardContent>
      </Card>
    </Box>
  );
};
