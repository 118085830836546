import { makeStyles } from "@toolkit/ui";

export const useAppointmentsHistoryListStyle = makeStyles()(theme => ({
  emptyPageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(100% - 20px)",
    height: "100%",
    flex: 1,
    justifySelf: "center",
    alignSelf: "center",
    padding: "10px",
    marginInline: "10px",
    paddingBottom: "100px",
    paddingInline: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    maxHeight: "100%",
  },
  scheduleButton: {
    height: "45px",
    paddingInline: "20px",
    borderRadius: "20px",
  },
  emptyCard: {
    padding: "10px",
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)})`,
  },
}));
