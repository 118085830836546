import { FC, PropsWithChildren, useMemo } from "react";
import { createMeetoraClient } from "../util";
import { MeetoraProvider } from "@meetora/react/client";
import { GuestCallView } from "./GuestCallView";
import { CallProvider } from "../Call/Meetora";

type GuestCallProps = PropsWithChildren<{
  token: string;
}>;

export const GuestCall: FC<GuestCallProps> = ({ token }) => {
  const client = useMemo(() => createMeetoraClient({}), []);
  return (
    <MeetoraProvider client={client}>
      <CallProvider>
        <GuestCallView token={token} />
      </CallProvider>
    </MeetoraProvider>
  );
};
