import React from "react";
import { CustomInfiniteScroll, CircularProgress, Box } from "@toolkit/ui";
import { MyWalletSkeleton } from "../../components/MyWalletSkeleton";
import { useStyles } from "./TransactionListStyle";
import { ITransactionListProps } from "../../types/types";

export const TransactionList: React.FC<ITransactionListProps> = ({
  paymentOperationLogs,
  containerHeight,
  handleFetchMoreData,
  hasMoreData,
  onRefresh,
  loading,
  emptyListComponent,
  keyExtractor,
  renderItem,
}) => {
  const { classes } = useStyles();

  if (loading) {
    return (
      <Box className={classes.loader}>
        {Array.from({ length: 5 }).map((_, index) => (
          <MyWalletSkeleton key={index} />
        ))}
      </Box>
    );
  }

  return (
    <CustomInfiniteScroll
      height={containerHeight ? containerHeight - 10 : undefined}
      dataLength={paymentOperationLogs.length}
      onFetchMore={handleFetchMoreData}
      hasMore={hasMoreData}
      loader={<CircularProgress className={classes.loader} />}
      onRefresh={onRefresh}
    >
      {!paymentOperationLogs.length && emptyListComponent}

      {paymentOperationLogs.map(item => (
        <React.Fragment key={keyExtractor(item)}>{renderItem(item)}</React.Fragment>
      ))}
    </CustomInfiniteScroll>
  );
};
