/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { AppointmentNotificationData } from "@/domains/notifications/types";
import { getDoctorInfo } from ".";
import { IDoctorInfo } from "@/domains/appointment/types/types";
import { AppointmentType } from "@health/queries/types";
import moment from "moment";
import { ExtractNodeType } from "@toolkit/apollo";
import { AppointmentsHistoryQuery } from "../gql";

export type ConfirmDoctorTimeContainerData = {
  doctorInfo: IDoctorInfo;
  isIntegrated?: boolean;
  appointmentId: string;
  appointmentType?: AppointmentType | null;
  appointmentStatus?: string | null;
  paymentStatus?: string | null;
  paymentInfo?: AppointmentNotificationData["paymentInfo"];
  suggestedTimeSlots?: string[] | null;
};

export const getConfirmDoctorTimeContainerData = (
  data: AppointmentNotificationData | ExtractNodeType<AppointmentsHistoryQuery>
): ConfirmDoctorTimeContainerData => {
  return {
    doctorInfo: getDoctorInfo(data?.doctor!),
    isIntegrated: data?.doctor?.vendor?.isIntegrated,
    appointmentId: data?.id,
    appointmentType: data?.type,
    appointmentStatus: data?.status,
    paymentStatus: data?.paymentStatus,
    paymentInfo: data?.paymentInfo,
    suggestedTimeSlots: data?.suggestedTimeSlots?.filter((slot): slot is string => slot !== null) || null,
  };
};

export const formatAppointmentTimeRange = (start: string, end: string) => {
  const _start = moment(start);
  const _end = moment(end);
  return `${_start.format("DD MMMM")} . ${_start?.format("hh:mm")} - ${_end?.format("hh:mm")}`;
};
