import { useCustomFormContext } from "@toolkit/core";
import { get } from "lodash";
import React, { FC } from "react";
import { CustomStaticTimePicker, CustomStaticTimePickerProps } from "../CustomStaticTimePicker";

type FormStaticTimeFieldProps = Omit<CustomStaticTimePickerProps, "defaultValue" | "error" | "helperText" | "control">;

export const FormStaticTimeField: FC<FormStaticTimeFieldProps> = props => {
  const { name, label, variant = "filled", size = "small", fullWidth = true, disabled, ...rest } = props;

  const {
    control,
    register,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <CustomStaticTimePicker
      {...rest}
      control={control}
      {...register(name)}
      label={label}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      error={!!error}
      helperText={error}
      disabled={isFormDisabled || disabled}
    />
  );
};
