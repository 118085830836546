import { makeStyles } from "@toolkit/ui";

export const useGuestCallViewStyles = makeStyles()(theme => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 320,
    height: "100%",
    width: "100%",
  },

  title: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: theme.mixins.fonts.fontSize.xxxl,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
    padding: 10,
  },

  content: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    gap: 20,
  },
}));
