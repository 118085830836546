import { AppointmentBaseRoute } from "@/domains/appointment/routes";
import { CareTeamBaseRoute } from "@/domains/care-teams/routes";
import { DoctorBaseRoute } from "@/domains/doctor";
import { MyWalletRoute } from "@/domains/my-wallet";
import { NotificationsBaseRoute } from "@/domains/notifications/routes";
import { VirtualVisitRoute } from "@/domains/VirtualVisit/routes";
import { VisitCallBaseRoute, VisitCallGuestBaseRoute } from "@/domains/VisitCall/routes";
import { HomeBaseRoute } from "@/pages/Home/routes";

export const routesPaths = {
  login: "/login",
  landing: "/",
  changeLanguage: "/change-language",
  getHelp: "/get-help",
  accountSettings: "/account-settings",
  deleteAccount: "/delete-account",
  verifyDeleteAccount: "/verify-delete-account",
  deleteAccountCompleted: "/delete-account-completed",
  playGround: "/playground",
  soon: "/soon",
  visitCall: VisitCallBaseRoute,
  visitCallGuest: VisitCallGuestBaseRoute,
  appointments: AppointmentBaseRoute,
  notifications: NotificationsBaseRoute,
  home: HomeBaseRoute,
  doctor: DoctorBaseRoute,
  careTeam: CareTeamBaseRoute,
  wallet: MyWalletRoute,
  virtualVisit: VirtualVisitRoute,
};
