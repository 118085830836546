import { DoctorProfileForTabs } from "@/domains/doctor/type";
import { useTranslation } from "@toolkit/i18n";
import { Box } from "@toolkit/ui";
import { FC } from "react";
import { useDoctorProfileTabsStyle } from "../DoctorProfileTabsStyles";
import moment from "moment/moment";

type ExperienceBodyProps = {
  doctorInfo: DoctorProfileForTabs;
};

export const ExperienceBody: FC<ExperienceBodyProps> = props => {
  const { t } = useTranslation();
  const { classes } = useDoctorProfileTabsStyle();
  const { doctorInfo } = props;
  const getWorkingDateRange = (startDate: string, endDate: string) => {
    const startYear = moment(startDate).year();
    const endYear = moment(endDate).year();
    if (startYear === endYear) {
      return `${startYear}`;
    }
    return `${startYear} - ${endYear}`;
  };
  return (
    <>
      {doctorInfo?.experiences?.map(experience => (
        <Box key={experience?.id} className={classes.qualificationContainer}>
          {experience?.startDate && experience?.endDate && <Box>{getWorkingDateRange(experience.startDate, experience.endDate)}</Box>}
          <Box>{experience?.jobTitle}</Box>
        </Box>
      ))}
      {doctorInfo?.experiences?.length === 0 && <Box>{t("No Experience provided")}</Box>}
    </>
  );
};
