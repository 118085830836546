import { makeStyles } from "@toolkit/ui";

export const useAppointmentHistoryCardStyle = makeStyles()(theme => ({
  root: {
    margin: theme.spacing(1, 2),
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(1),
  },
  card: {
    backgroundColor: "none",
    borderRadius: "0",
    boxShadow: "none",
    padding: "0",
    margin: "0",
  },
  avatarsContainer: {
    paddingInline: "5px",
  },
  vendorAvatar: {
    height: "100%",
    borderRadius: "0",
    "& img": {
      objectFit: "cover",
    },
  },
  doctorName: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.secondary.main,
  },
  appointmentStatus: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  appointmentDateRange: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  vendorName: {
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.primary.main,
  },
  doctorSpecializations: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    color: theme.palette.stale.main,
  },
  infoContainer: {
    paddingInlineEnd: "0",
    width: "100%",
  },
  paymentStatus: {
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.success.main,
  },
}));
