import { makeStyles } from "@toolkit/ui";

export const useVisitCallWaitingContainerStyle = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
    position: "relative",
  },
  allDoctorsLink: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
