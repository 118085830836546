import { ChangeEvent, FC, useCallback, useState } from "react";
import { CallError } from "../Call/CallError";
import { useCallInvitationToken } from "@meetora/react/ui/ConferenceCall/useCallInvitationToken";
import { Box, Button, TextField, Typography, useAddToast } from "@toolkit/ui";
import { useCall } from "../Call/Meetora";
import { useTranslation } from "@toolkit/i18n";
import { useGuestCallViewStyles } from "./GuestCallView.styles";
import { CallContent } from "../Call/CallContent";

type GuestCallProps = {
  token: string;
};

export const GuestCallView: FC<GuestCallProps> = ({ token }) => {
  const { t } = useTranslation();
  const { failed } = useAddToast();
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const { error, loading, retry: handleRetry, data, getCallInvitationTokenInfo } = useCallInvitationToken({ token });
  const handleChangeName = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setName(e.target.value || ""), []);
  const { classes } = useGuestCallViewStyles();
  const call = useCall({ name, token, invitationInfo: data });

  const handleJoin = useCallback(async () => {
    setSubmitted(true);
    const result = await getCallInvitationTokenInfo({ token });
    if (
      result?._name === "conversation.conferenceCallInvitationTokenInfo" &&
      result.callInfo._name !== "calls.conference.conferenceCallInfo"
    ) {
      failed(t("Call is not started yet. Please try again later."));
    }
  }, [token, getCallInvitationTokenInfo, failed, t]);

  const handleReset = useCallback(() => {
    setSubmitted(false);
    handleRetry();
  }, [handleRetry]);

  if (error || loading || !data) {
    return <CallError isLoading={loading} error={error} onClick={handleRetry} />;
  }

  const isCallStarted = data.callInfo._name === "calls.conference.conferenceCallInfo";
  if (!isCallStarted || !name || !submitted) {
    return (
      <Box className={classes.root}>
        <Box className={classes.content} sx={{ width: 320 }}>
          <Box>
            <Typography className={classes.title}>{t("Join Meeting")}</Typography>
            <Typography>{data.chatTitle}</Typography>
          </Box>

          <TextField fullWidth value={name} label={t("Enter your name")} onChange={handleChangeName} />
          <Button fullWidth onClick={handleJoin}>
            {t("Join")}
          </Button>
        </Box>
      </Box>
    );
  }

  return <CallContent call={call} onClose={handleReset} />;
};
