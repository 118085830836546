import { makeStyles } from "@toolkit/ui";

export const useMeetingSidebarStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxWidth: "100%",

    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      backgroundColor: "#292929",
      border: 0,
    },
  },

  item: {
    flex: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    width: 320,
    boxSizing: "border-box",
  },

  itemHeader: {
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
