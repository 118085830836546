import { PaymentOperationLog } from "@health/queries/types";
import { Box, formatDate, Grid, Typography } from "@toolkit/ui";
import { FC, useCallback } from "react";
import { getDescription, getTransaction, getTransactionDetails } from "../../utils/transaction-details.utils";
import { useTranslation } from "@/i18n/i18n.config";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./TransactionCardStyle";

export const TransactionCard: FC<{ paymentOperationLog: PaymentOperationLog }> = ({ paymentOperationLog }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const navigate = useNavigate();
  const { classes, cx, theme } = useStyles();
  const { icon, formattedNumber, operationType, color } = getTransactionDetails(
    paymentOperationLog.operationType!,
    paymentOperationLog.paymentAmount!,
    theme
  );
  const description = getDescription(paymentOperationLog);
  const onGoToTransactionDetails = useCallback((transactionId: string) => {
    return () => {
      navigate(routes.transactionDetails.getRouteWithParams({ transactionId: transactionId }));
    };
  }, []);

  return (
    <Box role='button' onClick={onGoToTransactionDetails(paymentOperationLog?.id)} className={classes.content}>
      <Grid height='100%' className={"card__container"} container gap={2} alignItems='center'>
        <Grid className={cx(classes.transactionCardWrapper, classes.iconButton)}>{icon}</Grid>
        <Grid>
          {operationType ? <Typography className={cx(classes.text, classes.operationType)}>{t(operationType)}</Typography> : null}
          <Typography className={classes.text}>{getTransaction(paymentOperationLog, t)}</Typography>
          {description ? <Typography className={classes.text}>{description}</Typography> : null}
          {paymentOperationLog?.createdDate ? (
            <Typography className={classes.text}>{formatDate(paymentOperationLog?.createdDate, lang)}</Typography>
          ) : null}
        </Grid>
      </Grid>
      <Typography m={1} fontSize={theme.mixins.fonts.fontSize.xxl} color={color}>
        {formattedNumber}
      </Typography>
    </Box>
  );
};
