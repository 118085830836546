import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Box, format, ValueWithLabel } from "@toolkit/ui";
import { useRemittanceDrawerStyles } from "./RemittanceDrawerStyles";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { transactionStatusTypeOptionsMap } from "@health/enum-options";
import { getOptimaAuthorizationStatusColor } from "../../../../utils";
import { useSiteSettingsGetQuery } from "../../../../shared/gql/queries";
export const RemittanceDrawerDetails = ({ claim }) => {
    var _a, _b;
    const { remittance } = claim;
    const { classes, theme } = useRemittanceDrawerStyles();
    const { t } = useTranslation("domains");
    const { data } = useSiteSettingsGetQuery();
    const defaultCurrency = ((_a = data === null || data === void 0 ? void 0 : data.siteSettings) === null || _a === void 0 ? void 0 : _a.defaultCurrency) ? " " + ((_b = data === null || data === void 0 ? void 0 : data.siteSettings) === null || _b === void 0 ? void 0 : _b.defaultCurrency) : "";
    const commonValueProps = {
        labelProps: {
            className: classes.label,
        },
        valueProps: {
            className: classes.value,
        },
    };
    return (_jsxs(Box, { className: classes.sectionContainer, children: [_jsx(ValueWithLabel, Object.assign({}, commonValueProps, { valueProps: Object.assign(Object.assign({}, commonValueProps.valueProps), { color: getOptimaAuthorizationStatusColor((remittance === null || remittance === void 0 ? void 0 : remittance.status) || "", theme) }), label: t("Status"), value: (remittance === null || remittance === void 0 ? void 0 : remittance.status) ? transactionStatusTypeOptionsMap[remittance.status].label : "-" })), _jsx(ValueWithLabel, Object.assign({}, commonValueProps, { label: t("Date Settlement"), value: (remittance === null || remittance === void 0 ? void 0 : remittance.dateSettlement) ? format(remittance.dateSettlement, DATE_FORMAT_FOR_TABLE_LONG) : "-" })), _jsx(ValueWithLabel, Object.assign({}, commonValueProps, { label: t("Payment Reference"), value: (remittance === null || remittance === void 0 ? void 0 : remittance.paymentReference) || "-" })), _jsx(ValueWithLabel, Object.assign({}, commonValueProps, { label: t("Remittance ID"), value: (remittance === null || remittance === void 0 ? void 0 : remittance.id) || "-" })), _jsx(ValueWithLabel, Object.assign({}, commonValueProps, { label: t("Gross Amount"), value: claim.gross ? claim.gross + defaultCurrency : "-" })), _jsx(ValueWithLabel, Object.assign({}, commonValueProps, { label: t("Net Amount"), value: claim.net ? claim.net + defaultCurrency : "-" }))] }));
};
