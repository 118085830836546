import { MainPageLayout } from "@/components";
import { routes } from "@/domains/doctor";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

export const DoctorRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<MainPageLayout containerBreakpoint='sm' />}>
        <Route path={routes.profile.route} Component={routes.profile.component} />
      </Route>
    </Routes>
  );
};
