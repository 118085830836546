import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const UserIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  const fill = props.fill || theme.palette.primary.main;

  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='23.636' height='29.543' viewBox='0 0 23.636 29.543' {...props}>
      <path
        id='icons8-user_3_'
        d='M19.817,4a7.38,7.38,0,0,0-2.271.356,1.108,1.108,0,1,0,.682,2.108,5.171,5.171,0,1,1-3.582,4.922,1.108,1.108,0,1,0-2.216,0A7.386,7.386,0,1,0,19.817,4ZM12.062,21.726A4.079,4.079,0,0,0,8,25.788a6.883,6.883,0,0,0,3.919,5.955,16.623,16.623,0,0,0,7.9,1.8,16.623,16.623,0,0,0,7.9-1.8,6.944,6.944,0,0,0,3.866-5.616,1.108,1.108,0,0,0,.055-.339,4.079,4.079,0,0,0-4.062-4.062H25.867a1.108,1.108,0,1,0,0,2.216h1.707a1.829,1.829,0,0,1,1.845,1.844s0,0,0,0c0,1.769-.994,3.034-2.774,4.016a14.723,14.723,0,0,1-6.827,1.523A14.723,14.723,0,0,1,12.99,29.8c-1.78-.982-2.774-2.246-2.774-4.016a1.83,1.83,0,0,1,1.846-1.846h8.714a1.108,1.108,0,1,0,0-2.216Z'
        transform='translate(-8 -4)'
        fill={fill}
      />
    </SvgIcon>
  );
};
