import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useGetDoctorProfileData } from "./useGetDoctorProfileData";
import { useTranslation } from "@toolkit/i18n";
import { useCommonStyles } from "@/domains/appointment/containers/CommonContainer.styles";
import { DoctorProfileCard } from "../../components/DoctorProfileCard/DoctorProfileCard";
import { useStyle } from "./DoctorProfileContainerStyle";
import { AppointmentActionsBox } from "@/domains/appointment/components/AppointmentActionsBox/AppointmentActionsBox";
import { useNavigate } from "react-router-dom";
import { routes as appointmentsRoutes } from "@/domains/appointment/routes";
import { AppointmentType, AppointmentTypeEnum } from "@health/queries/types";
import { getDoctorInfo } from "@/domains/appointment/utils";
import { DoctorProfileTabs } from "../../components/DoctorProfileTabs/DoctorProfileTabs";
import { TimeSelectionContainerData } from "@/domains/appointment/containers/TimeSelectionContainer/type";

export const DoctorProfileContainer: FC = () => {
  const { t } = useTranslation("consumer");
  const { doctorData, loading } = useGetDoctorProfileData(t);
  const { classes: commonClasses } = useCommonStyles();
  const { classes, cx } = useStyle();
  const navigate = useNavigate();

  const doctorId = doctorData?.id;

  const handleOnActionCLick = (visitType: AppointmentTypeEnum) => {
    navigate(appointmentsRoutes.schedule.getRouteWithParams({ visitType, doctorId: doctorId || "" }), {
      state: { doctorInfo: getDoctorInfo(doctorData!), visitType: visitType as unknown as AppointmentType } as TimeSelectionContainerData,
    });
  };
  const isAppointmentTypeDisabled = (visitType: AppointmentTypeEnum) => {
    return !doctorData?.appointmentTypes?.includes(visitType!);
  };
  return (
    <Box className={commonClasses.pageContainer}>
      <Box className={cx(commonClasses.pageContent, classes.pageContent)}>
        {loading && (
          <Box mt={"40px"}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Box className={classes.generalInfoContainer}>
              <Typography className={classes.doctorName}>{doctorData?.user?.fullName}</Typography>
              <DoctorProfileCard doctorInfo={doctorData!} />
            </Box>
            <Box className={classes.bookAppointmentContainer}>
              <Typography className={classes.bookAppointmentTitle}>{t("Book an Appointment")}</Typography>
              <AppointmentActionsBox
                classes={{
                  root: classes.appointmentActionsBox,
                }}
                handleOnActionCLick={handleOnActionCLick}
                isAppointmentTypeDisabled={isAppointmentTypeDisabled}
              />
            </Box>
            <Box className={classes.doctorTabContainer}>
              <DoctorProfileTabs doctorInfo={doctorData!} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
