import { Box, Typography } from "@toolkit/ui";
import { FC, useMemo } from "react";
import { AppointmentsHistoryTabs } from "../../components/AppointmentsHistoryTabs/AppointmentsHistoryTabs";
import { AppointmentsHistoryList } from "../../components/AppointmentsHistoryList/AppointmentsHistoryList";
import { AppointmentHistoryTabsEnum } from "../../types/types";
import queryString from "query-string";
import { appointmentsHistoryTabs } from "../../components/AppointmentsHistoryTabs/tabs";
import { useCommonInfiniteContainerStyles } from "@/styles";
import { useTranslation } from "@toolkit/i18n";

export const AppointmentsHistoryContainer: FC = () => {
  const defaultTab = appointmentsHistoryTabs[2];
  const { tab } = queryString.parse(location.search) as { tab?: AppointmentHistoryTabsEnum };
  const currentTab = useMemo(
    () => appointmentsHistoryTabs.find(({ routeParameterValue }) => routeParameterValue === tab) || defaultTab,
    [tab]
  );
  const filters = { ...currentTab?.filter, status: [...(currentTab?.filter?.status || [])] };
  const { classes: commonContainerClasses } = useCommonInfiniteContainerStyles();

  const { t } = useTranslation("consumer");

  return (
    <Box className={commonContainerClasses.container}>
      <Typography className={commonContainerClasses.title}>{t("Appointments")}</Typography>
      <AppointmentsHistoryTabs currentTab={currentTab.routeParameterValue} />
      <AppointmentsHistoryList variant={currentTab.routeParameterValue} {...currentTab} filters={filters} />
    </Box>
  );
};
