import { useLocation, useNavigate, Location } from "react-router-dom";
import { CareTeamRegistrationContainerDataType } from "../../type";
import { useAddToast } from "@toolkit/ui";
import { TFunction, useOpenState } from "@toolkit/core";
import { useGuidedCareTeamEnrollMutation } from "../../gql/mutations";
import { routes } from "../../routes";

export const useCareTeamRegistrationConfirm = (t: TFunction) => {
  const location: Location<CareTeamRegistrationContainerDataType> = useLocation();
  const { teamLeader, team } = location.state || {};
  const navigate = useNavigate();
  const { open, handleClose, handleOpen } = useOpenState();
  const { failed } = useAddToast();

  const [enrollTeam, { loading }] = useGuidedCareTeamEnrollMutation({
    onCompleted: data => {
      if (data?.enrollPatientToTeam?.providerGuidedCareHealthProgramTeamErrors?.length) {
        handleClose();
        failed(t("Failed to join the care team"));
      } else {
        navigate(routes.registerSuccess.getRouteWithParams({ teamId: team?.id }), { state: { team, teamLeader } });
        handleClose();
      }
    },
    onError: () => {
      failed(t("Failed to join the care team"));
    },
  });

  const handleConfirmClick = () => {
    enrollTeam({
      variables: {
        input: {
          teamId: team?.id,
        },
      },
    });
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  const leaderTeamsMembers = team?.teamMembers?.filter((member): member is NonNullable<typeof member> => member?.id !== teamLeader?.id);

  return {
    t,
    open,
    handleOpen,
    handleClose,
    handleConfirmClick,
    handleCancelClick,
    loading,
    team,
    teamLeader,
    leaderTeamsMembers,
  };
};
