import { makeStyles } from "@toolkit/ui";

export const useCareTeamDisEnrollButtonStyle = makeStyles()(theme => ({
  moreTitle: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    display: "inline",
    color: theme.palette.primary.main,
  },
  leaveButtonContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  leaveButton: {
    backgroundColor: theme.palette.error.main,
    borderRadius: "24px",
    paddingInline: "30px",
    "&:hover": {
      backgroundColor: theme.palette.error[800],
    },
  },
  ConfirmBodyTitle: {
    marginTop: "10px",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
}));
