import { FC } from "react";
import { CareTeamDataType, CareTeamRegistrationContainerDataType } from "../../type";
import { useCareTeamCardStyle } from "./CareTeamCardStyle";
import { Avatar, Box, Button, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { getTeamLeader } from "../../utils";
import { DoctorCardLayout, ExpandMoreIcon } from "@/components";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { DoctorAvatar } from "@/components/DoctorAvatar/DoctorAvatar";

type CareTeamCardProps = {
  team: CareTeamDataType;
  hasActions: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
};

export const CareTeamCard: FC<CareTeamCardProps> = props => {
  const { hasActions, team, onClick } = props;

  const { classes, cx } = useCareTeamCardStyle({ hasActions: hasActions });
  const navigate = useNavigate();
  const teamLeader = getTeamLeader(team?.teamMembers);
  const leaderVendor = team?.provider;
  const { t } = useTranslation("consumer");

  const handleRegisterClick = () => {
    navigate(routes.register.getRouteWithParams({ teamId: team.id }), {
      state: { team, teamLeader } as CareTeamRegistrationContainerDataType,
    });
  };
  const handleProfileClick = () => {
    navigate(routes.doctorProfile.getRouteWithParams({ doctorId: teamLeader?.user?.doctor?.id || "" }), {
      state: { team, teamLeader } as CareTeamRegistrationContainerDataType,
    });
  };

  return (
    <DoctorCardLayout
      onClick={onClick}
      doctorAvatarNode={
        <Box className={classes.doctorAvatar}>
          <DoctorAvatar className={classes.doctorAvatar} imageUrl={teamLeader?.user?.photo || ""} />
          <Typography className={classes.teamLeaderBadge}>{t("Care Team Leader")}</Typography>
        </Box>
      }
      infoNode={
        <>
          <Typography className={classes.teamName}>{team?.name}</Typography>
          <Typography className={classes.vendorName}>{teamLeader?.user?.fullName}</Typography>
          <Typography className={classes.vendorName}>{pickLocalizedValue(leaderVendor.name, leaderVendor?.nameAr)}</Typography>
        </>
      }
      classes={{
        avatarsContainer: classes.avatarsContainer,
        actionsContainer: classes.actionsContainer,
        infoContainer: cx(classes.infoContainer, hasActions && classes.infoContainerWithActions),
      }}
      downAvatarNode={
        leaderVendor?.logo ? <Avatar className={classes.vendorAvatar} title={leaderVendor?.name} src={leaderVendor?.logo} /> : null
      }
      actionsNode={
        hasActions ? (
          <>
            <Button className={classes.registerButton} onClick={handleRegisterClick}>
              {t("Register")}
            </Button>
            <Button onClick={handleProfileClick} className={classes.profileButton} variant='outlined'>
              {t("Doctor’s Profile")}
            </Button>
          </>
        ) : (
          <Box className={classes.expandMoreButton}>
            <ExpandMoreIcon />
          </Box>
        )
      }
    />
  );
};
