import { useEffect, useState } from "react";
import { useMeProfileInfoQuery } from "@/domains/patient/gql";
import { Avatar, AvatarProps, Box, CustomIcon, IconButton, Popover, Typography, useMediaQuery } from "@toolkit/ui";
import { UserProfileMenu } from "../UserProfileMenu/UserProfileMenu";
import { CustomUserAvatar } from "@/components";
import { useStyles } from "./HeaderUserAvatarStyle";
import { useAuth as useProviderUserAuth } from "@/providers/auth";
import { useTranslation } from "@toolkit/i18n";
import { sideMenuDrawerOpenStateVar } from "../SideMenuDrawer/sideMenuDrawerOpenStateVar";
import { MenuSettingIcon } from "../Icons";

interface HeaderUserAvatarProps extends AvatarProps {}

export const HeaderUserAvatar = (props: HeaderUserAvatarProps) => {
  const { classes, theme } = useStyles();
  const { doLogout, isAuthenticated } = useProviderUserAuth();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { data } = useMeProfileInfoQuery({
    fetchPolicy: "cache-first",
  });
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    !isSmall && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    doLogout();
  };
  const open = Boolean(anchorEl);
  const id = open ? "profile-menu-popover" : undefined;

  const me = data?.me;
  const avatarSrc = me?.avatar || me?.photo;
  const altText = me?.fullName || undefined;
  const initial = me?.fullName?.charAt(0) || "";

  useEffect(() => {
    if (isSmall) {
      handleClose();
    } else {
      sideMenuDrawerOpenStateVar(false);
    }
  }, [isSmall]);

  return (
    <Box className={classes.root}>
      {isAuthenticated ? (
        <Avatar alt={altText} src={avatarSrc || undefined} onClick={handleAvatarClick} {...props} className={classes.avatar}>
          {!avatarSrc && initial}
        </Avatar>
      ) : (
        <IconButton onClick={handleAvatarClick} className={classes.avatar}>
          <MenuSettingIcon />
        </IconButton>
      )}
      <Popover
        classes={{
          paper: classes.popoverPaper,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -30,
          horizontal: 125,
        }}
      >
        {isAuthenticated && (
          <Box className={classes.userInfoContainer}>
            <Box className={classes.popoverContent}>
              <CustomUserAvatar id='patient-profile-img' src={avatarSrc!} width='100%' height='100%' />
            </Box>
            <Typography>{me?.fullName}</Typography>
          </Box>
        )}
        <Box className={classes.menuList}>
          <UserProfileMenu />
        </Box>
        {isAuthenticated && (
          <IconButton onClick={handleLogout} className={classes.logoutButton}>
            <CustomIcon icon={"logout"} />
            <Typography>{t("Log out")}</Typography>
          </IconButton>
        )}
      </Popover>
    </Box>
  );
};
