import { CallContent } from "./CallContent";
import { CallError } from "./CallError";
import { useMeetingInfo } from "./useMeetingInfo";

type CallProps = {
  invitationCode: string;
  invitationLink?: string;
  renderDoctorConsole?: () => JSX.Element;
  onClose?: () => void;
  onGoBack?: () => void;
};

export const Call = ({ invitationLink, invitationCode, renderDoctorConsole, onClose, onGoBack }: CallProps) => {
  const { call, error, loading, handleRetry } = useMeetingInfo({ invitationCode });

  if (!call || error || loading) {
    return <CallError isLoading={loading} error={error} onClick={handleRetry} />;
  }

  return (
    <CallContent
      call={call}
      invitationLink={invitationLink}
      onClose={onClose}
      onGoBack={onGoBack}
      renderDoctorConsole={renderDoctorConsole}
    />
  );
};
